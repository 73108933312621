import {writeLog} from "@baton8/quizium-lib-repositories/dist/game";
import {socket} from "src/clients/socket.js";
import {global} from "src/globals";
import {FieldId, PlayerInfo, Session, SessionDirectorRequirement, SessionEntryRequirement, SessionId} from "src/types";
import {createAsyncApi} from "src/utils/api";


/**
 * @group Types
 * @category Session
 */
export interface SessionEntryRequirementForCreate {
  allowShowList: boolean;
  allowAllGroup: boolean;
  groupIds: Array<string>;
  password: string;
}

/**
 * @group Types
 * @category Session
 */
export interface SessionDirectorRequirementForCreate {
  allowAllUser: boolean;
  userIds: Array<string>;
}

/**
 * @group Types
 * @category Session
 */
export interface CreateSessionOptions {
  entryRequirement: SessionEntryRequirementForCreate;
  directorRequirement: SessionDirectorRequirementForCreate;
  expireHours: number;
};

/**
 * 新しいセッションを作成します。
 * @param name 名前
 * @param options オプション
 * @returns 作成されたセッション
 * @group API Wrappers
 * @category Session
 */
export const createSession = createAsyncApi((name: string, options: CreateSessionOptions): Promise<Session> => {
  const user = global.user;
  return new Promise((resolve, reject) => {
    if (user != null) {
      socket.emit("createSession", name, user.id, options.entryRequirement, options.directorRequirement, options.expireHours, (session) => {
        writeLog("Repositories", "createSession", [name, user.id, options.entryRequirement, options.directorRequirement, options.expireHours], session);
        resolve(session);
      });
    } else {
      reject(new Error("Not signed in"));
    }
  });
}, "createSession");

/**
 * セッションを削除します。
 * @param id セッションの ID
 * @group API Wrappers
 * @category Session
 */
export const deleteSession = createAsyncApi((id: SessionId): Promise<void> => {
  return new Promise((resolve, reject) => {
    socket.emit("deleteSession", id);
    resolve();
  });
}, "deleteSession");

/**
 * セッションの入室条件を変更します。
 * @param id セッションの ID
 * @param requirement セッションの入室条件
 * @group API Wrappers
 * @category Session
 */
export const updateSessionEntryRequirement = createAsyncApi((id: SessionId, requirement: SessionEntryRequirementForCreate): Promise<void> => {
  return new Promise((resolve, reject) => {
    socket.emit("updateSessionRequirement", id, requirement);
    resolve();
  });
}, "updateSessionEntryRequirement");

/**
 * セッション内のゲームの進行条件を変更します。
 * @param id セッションの ID
 * @param requirement セッション内のゲームの進行条件
 * @group API Wrappers
 * @category Session
 */
export const updateSessionDirectorRequirement = createAsyncApi((id: SessionId, requirement: SessionDirectorRequirementForCreate): Promise<void> => {
  return new Promise((resolve, reject) => {
    socket.emit("updateSessionDirectorRequirement", id, requirement);
    resolve();
  });
}, "updateSessionDirectorRequirement");

/**
 * セッションの入室条件を取得します。
 * @param id セッション ID
 * @returns セッションの入室条件
 * @group API Wrappers
 * @category Session
 */
export const getSessionEntryRequirement = createAsyncApi((id: SessionId): Promise<SessionEntryRequirement> => {
  return new Promise((resolve, reject) => {
    socket.emit("getSessionRequirement", id, (requirement) => {
      resolve(requirement);
    });
  });
}, "getSessionEntryRequirement");

/**
 * セッション内のゲームの進行条件を取得します。
 * @param id セッション ID
 * @returns セッション内のゲームの進行条件
 * @group API Wrappers
 * @category Session
 */
export const getSessionDirectorRequirement = createAsyncApi((id: SessionId): Promise<SessionDirectorRequirement> => {
  return new Promise((resolve, reject) => {
    socket.emit("getSessionDirectorRequirement", id, (requirement) => {
      resolve(requirement);
    });
  });
}, "getSessionDirectorRequirement");

/**
 * 指定されたマップにいるプレイヤー情報を取得します。
 * @param fieldId マップ ID
 * @returns プレイヤー情報の配列
 * @group API Wrappers
 * @category Session
 */
export const listPlayerInfos = createAsyncApi((fieldId: FieldId): Promise<Array<PlayerInfo>> => {
  const user = global.user;
  return new Promise((resolve, reject) => {
    if (user != null) {
      socket.emit("getPlayers", user.id, fieldId, (playerInfos) => {
        resolve(playerInfos);
      });
    } else {
      reject(new Error("Not signed in"));
    }
  });
}, "listPlayerInfos");