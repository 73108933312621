import {css} from "@emotion/react";
import {faCrown} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {alpha, color, fontWeight, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";
import {useTranslation} from "src/modules/translation";


interface RankViewProps extends CustomDataAttributes {
  rank: number;
  className?: string;
};

const styles = {
  root: css`
    width: ${size(12)};
    height: ${size(12)};
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    position: relative;
  `,
  background: css`
    inset: ${size(0)};
    inset-block-start: ${size(0)};
    font-size: ${size(12)};
    color: ${alpha(color("primary", 5), 0.2)};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 0;
  `,
  number: css`
    inset: ${size(0)};
    inset-block-start: ${size(2)};
    font-size: ${size(8)};
    font-weight: ${fontWeight("bold")};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const RankView: React.FC<RankViewProps> = ({
  rank,
  className,
  ...data
}) => {
  const {transNumber} = useTranslation("userStatusView");

  return (
    <div css={styles.root} className={className} {...data}>
      <div css={styles.background}><FontAwesomeIcon icon={faCrown}/></div>
      <div css={styles.number}>{rank > 0 ? transNumber(rank) : "—"}</div>
    </div>
  );
};