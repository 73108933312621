import {createContext} from "react";


type ContextValue = {
  isOpen: boolean,
  onClose?: () => unknown
};

export const context = createContext<ContextValue>({
  isOpen: false
});
export const Provider = context.Provider;