import {PlayableDeck as Deck} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faCheck, faMinus, faPlusCircle, faQ, faRocket} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {MouseEvent, useCallback} from "react";
import {Radio} from "src/components/common/radio";
import {SingleLineText} from "src/components/common/singleLineText";
import {alpha, color, size} from "src/components/constants/constants";
import {UserView} from "src/components/modules/userView";
import {useTranslation} from "src/modules/translation";


interface Props {
  optionDeck: Deck;
  deck: Deck | null;
  onChange?: (deck: Deck | null) => void;
};

const styles = {
  root: css`
    padding-block: 0.5em;
    padding-inline: 0.5em;
    column-gap: 0.5em;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: ${color("primary", 0)};
    }
  `,
  checkbox: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
  main: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  name: css`
    flex-grow: 1;
    flex-shrink: 1;
  `,
  quizCountContainer: css`
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  quizCountIcon: css`
    margin-inline-end: 0.4em;
    font-size: ${size(3)};
  `,
  quizCount: css`
    font-size: ${size(4)};
  `,
  footnoteList: css`
    margin-block-start: ${size(2)};
    row-gap: ${size(1)};
    font-size: ${size(3)};
    opacity: 0.6;
    display: flex;
    flex-direction: column;
  `,
  footnoteLine: css`
    column-gap: ${size(2)};
    display: flex;
    align-items: center;
  `,
  footnoteItem: css`
    display: flex;
    align-items: center;
  `,
  iconWrapper: css`
    width: 0.9em;
    margin-inline-end: 0.4em;
    display: inline-flex;
    justify-content: center;
    flex-shrink: 0;
  `,
  icon: css`
  `,
  tag: css`
    padding-block: 0.2em;
    padding-inline: 0.4em;
    font-size: ${size(3)};
    border-radius: ${size(1)};
    color: ${color("primary", 5)};
    background-color: ${alpha(color("primary", 5), 0.2)};
    transition: opacity 0.2s ease;
    flex-grow: 0;
    flex-shrink: 0;
    opacity: 0;
    .qr-listbox:hover >& {
      opacity: 1;
    }
  `,
  tagIcon: css`
    margin-inline-end: 0.4em;
  `
};

export const DeckListboxPane: React.FC<Props> = ({
  optionDeck,
  deck,
  onChange
}) => {
  const {trans, transNumber} = useTranslation("deckListbox");

  const isSelected = deck?.id === optionDeck.id;

  const handleChange = useCallback((event: MouseEvent<HTMLElement>) => {
    const willBeAdded = !isSelected;
    if (willBeAdded) {
      onChange?.(optionDeck);
    } else {
      onChange?.(null);
    }
  }, [optionDeck, isSelected, onChange]);

  return (
    <label css={styles.root} className="qr-listbox" data-selected={isSelected} onMouseDown={handleChange}>
      <div css={styles.checkbox}>
        <Radio checked={isSelected} onChange={() => null}/>
      </div>
      <div css={styles.main}>
        <SingleLineText css={styles.name}>{optionDeck.name}</SingleLineText>
        <div css={styles.footnoteList}>
          <div css={styles.footnoteLine}>
            <div css={styles.quizCountContainer}>
              <div css={styles.quizCountIcon}>
                <FontAwesomeIcon icon={faQ}/>
              </div>
              <div css={styles.quizCount}>
                {transNumber(optionDeck.quizIds.length)}
              </div>
            </div>
          </div>
          <div css={styles.footnoteLine}>
            <div css={styles.footnoteItem}>
              <div css={styles.iconWrapper}><FontAwesomeIcon css={styles.icon} icon={faPlusCircle}/></div>
              <UserView id={optionDeck.authorId} showAvatar={false}/>
            </div>
            <div css={styles.footnoteItem}>
              <div css={styles.iconWrapper}><FontAwesomeIcon css={styles.icon} icon={faRocket}/></div>
              <UserView id={optionDeck.publisherId} showAvatar={false}/>
            </div>
          </div>
        </div>
      </div>
      <div css={styles.tag}>
        <FontAwesomeIcon css={styles.tagIcon} icon={isSelected ? faMinus : faCheck}/>
        {isSelected ? trans("minus") : trans("select")}
      </div>
    </label>
  );
};