import {global} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faFacebook, faInstagram, faLine, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faCircleNotch, faLink} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import qixColor from "color";
import {useCallback, useMemo} from "react";
import {Button} from "src/components/common/button";
import {alpha, boxShadow, color, size, smartphone} from "src/components/constants/constants";
import {Dialog, DialogBody, DialogCloseButton, DialogHeader, DialogTitle} from "src/components/modules/dialog";
import {createOverlay} from "src/modules/create";
import {useBehaviorSubject} from "src/modules/subject";
import {useTranslation} from "src/modules/translation";
import {useToast} from "src/overlays/common/toast";


export interface ShareSessionLinkDialogProps {
  /**
   * このオーバーレイの Z index。
   * @defaultValue `200`
   */
  zIndex?: number;

  isOpen: boolean;
};

const styles = {
  dialog: css`
  `,
  body: css`
    column-gap: ${size(8)};
    row-gap: ${size(8)};
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    ${smartphone()} {
      flex-direction: column;
    }
  `,
  qrContainer: css`
    aspect-ratio: 1;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
  `,
  qrLoading: css`
    font-size: ${size(12)};
    color: ${color("primary", 5)};
  `,
  qrImage: css`
    width: 100%;
    height: 100%;
    inset: ${size(0)};
    display: block;
    position: absolute;
  `,
  right: css`
    row-gap: ${size(6)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  buttonSection: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  heading: css`
    margin-block-end: ${size(2)};
    font-size: ${size(4)};
    opacity: 0.6;
  `,
  buttonList: css`
    column-gap: ${size(3)};
    display: flex;
    justify-content: center;
  `,
  button: css`
    width: ${size(10)};
    height: ${size(10)};
    font-size: ${size(6)};
    border-radius: ${size(2)};
    color: ${color("white")};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
    &[data-platform="twitter"] {
      background-color: #1DA1F2;
      box-shadow: ${boxShadow(alpha("#1DA1F2", 0.2), 0.5)};
      &:hover {
        background-color: ${qixColor("#1DA1F2").mix(qixColor("#FFFFFF"), 0.3).toString()};
      }
    }
    &[data-platform="line"] {
      background-color: #06C755;
      box-shadow: ${boxShadow(alpha("#06C755", 0.2), 0.5)};
      &:hover {
        background-color: ${qixColor("#06C755").mix(qixColor("#FFFFFF"), 0.3).toString()};
      }
    }
    &[data-platform="instagram"] {
      background-color: #E1306C;
      box-shadow: ${boxShadow(alpha("#E1306C", 0.2), 0.5)};
      &:hover {
        background-color: ${qixColor("#E1306C").mix(qixColor("#FFFFFF"), 0.3).toString()};
      }
    }
    &[data-platform="facebook"] {
      background-color: #4267B2;
      box-shadow: ${boxShadow(alpha("#4267B2", 0.2), 0.5)};
      &:hover {
        background-color: ${qixColor("#4267B2").mix(qixColor("#FFFFFF"), 0.3).toString()};
      }
    }
  `
};

/**
 * - **Inner Props**: {@link ShareSessionLinkDialogProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const ShareSessionLinkDialog = createOverlay<ShareSessionLinkDialogProps>(({
  zIndex = 200,
  isOpen
}) => {
  const {trans} = useTranslation("shareSessionLinkDialog");

  const showToast = useToast();

  const session = useBehaviorSubject(global.sessionSubject);

  const sessionUrl = useMemo(() => {
    if (session != null) {
      const url = new URL(document.location.href);
      const params = url.searchParams;
      params.delete("session");
      params.set("session", session.id);
      url.search = params.toString();
      return url.toString();
    } else {
      return undefined;
    }
  }, [session]);
  const qrUrl = sessionUrl != null ? `https://api.qrserver.com/v1/create-qr-code/?format=svg&size=300x300&color=${color("blackText").slice(1)}&data=${encodeURIComponent(sessionUrl)}` : undefined;

  const copySessionUrl = useCallback(async () => {
    if (sessionUrl != null) {
      if (navigator.clipboard != null) {
        await navigator.clipboard.writeText(sessionUrl);
        showToast(trans("sessionUrlCopied"));
      } else {
        showToast(trans("copySessionUrlUnsupported"));
      }
    }
  }, [sessionUrl, showToast, trans]);

  const handleClose = useCallback(() => {
    ShareSessionLinkDialog.propsSubject.update({isOpen: false});
  }, []);

  return (
    <Dialog css={styles.dialog} zIndex={zIndex} isOpen={isOpen} onClose={handleClose}>
      <DialogCloseButton/>
      <DialogHeader>
        <DialogTitle>{trans("title")}</DialogTitle>
      </DialogHeader>
      {sessionUrl != null && (
        <DialogBody css={styles.body}>
          <div css={styles.qrContainer}>
            <div css={styles.qrLoading}>
              <FontAwesomeIcon icon={faCircleNotch} spin={true}/>
            </div>
            <img css={styles.qrImage} src={qrUrl} alt={trans("qr")}/>
          </div>
          <div css={styles.right}>
            <Button iconNode={<FontAwesomeIcon icon={faLink}/>} type="button" onClick={copySessionUrl}>{trans("copySessionUrl")}</Button>
            <section css={styles.buttonSection}>
              <h3 css={styles.heading}>{trans("shareOnSns")}</h3>
              <div css={styles.buttonList}>
                <button css={styles.button} type="button" data-platform="twitter" aria-label={trans("twitter")}><FontAwesomeIcon icon={faTwitter}/></button>
                <button css={styles.button} type="button" data-platform="line" aria-label={trans("line")}><FontAwesomeIcon icon={faLine}/></button>
                <button css={styles.button} type="button" data-platform="instagram" aria-label={trans("instagram")}><FontAwesomeIcon icon={faInstagram}/></button>
                <button css={styles.button} type="button" data-platform="facebook" aria-label={trans("facebook")}><FontAwesomeIcon icon={faFacebook}/></button>
              </div>
            </section>
          </div>
        </DialogBody>
      )}
    </Dialog>
  );
}, {
  isOpen: false
});
