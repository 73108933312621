import {GraphicSetting, PlayerInfo, global} from "@baton8/qroud-lib-repositories";
import {
  CharacterAnimation,
  Direction,
  FollowingNodeComponent,
  Ghost as GhostInterface,
  HideGhostsTrigger,
  borderWidth,
  color,
  fontWeight,
  size,
  withStories
} from "@baton8/qroud-lib-resources";
import {css} from "@emotion/react";
import {Actor, CollisionType, Engine, Shape, Vector, vec} from "excalibur";
import {Field} from "src/game/entities/field";
import {roundDown} from "src/utils/rounding";


const styles = {
  root: css`
    max-inline-size: ${size(24)};
    font-size: ${size(4)};
    font-weight: ${fontWeight("bold")};
    color: ${color("whiteText")};
    text-shadow:
      ${borderWidth(-1)} ${borderWidth(0)} 0rem ${color("blackText")},
      ${borderWidth(1)} ${borderWidth(0)} 0rem ${color("blackText")},
      ${borderWidth(0)} ${borderWidth(-1)} 0rem ${color("blackText")},
      ${borderWidth(0)} ${borderWidth(1)} 0rem ${color("blackText")},
      ${borderWidth(-1)} ${borderWidth(-1)} 0rem ${color("blackText")},
      ${borderWidth(1)} ${borderWidth(-1)} 0rem ${color("blackText")},
      ${borderWidth(-1)} ${borderWidth(1)} 0rem ${color("blackText")},
      ${borderWidth(1)} ${borderWidth(1)} 0rem ${color("blackText")};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transform: translate(-50%, -100%);
  `
};

export class Ghost extends withStories(Actor) implements GhostInterface {
  public field!: Field;
  private hideTriggers?: Array<HideGhostsTrigger>;

  private readonly playerInfo: PlayerInfo;
  private direction: Direction;
  private nextVel: Vector;
  /**
   * ゴーストがマップに入って最初の位置に移動した後かどうか。
   * 最初の位置への移動は瞬間的に行う必要がある (そうしないと初期位置であるマップ左上から飛んでくるような見た目になってしまう) ため、最初の移動が済んだかどうかをこのフラグで管理しています。
   */
  private hasMoved: boolean;

  public characterAnimation: CharacterAnimation;

  public constructor(playerInfo: PlayerInfo) {
    super({
      pos: vec(playerInfo.x, playerInfo.y),
      collider: Shape.Box(24, 24),
      collisionType: CollisionType.PreventCollision,
      name: "Ghost",
      z: 1
    });
    this.playerInfo = playerInfo;
    this.direction = "down";
    this.nextVel = vec(0, 0);
    this.hasMoved = false;
    this.addComponent(new FollowingNodeComponent());
    this.characterAnimation = new CharacterAnimation();
  }

  public override onInitialize(engine: Engine): void {
    this.characterAnimation.setupAnimations(this.graphics, this.playerInfo.graphicSetting.avatarName, this.height);
    this.setupFollowingNode();
  }

  public override onPreUpdate(engine: Engine, delta: number): void {
    this.updateVisibilityByHideTriggers();
    this.updateZ();
    this.characterAnimation.updateAnimation(this.graphics, this.nextVel);
    this.direction = this.characterAnimation.direction;
  }

  private updateZ(): void {
    const fieldHeight = this.field.boundingBox.height;
    this.z = roundDown(this.pos.y / fieldHeight * 20, 1);
  }

  private setupFollowingNode(): void {
    const followingNode = this.get(FollowingNodeComponent)!;
    followingNode.anchor = "top";
    followingNode.offset = vec(0, -24);
    followingNode.node = (
      <div css={styles.root}>
        {this.playerInfo.user.nickname}
      </div>
    );
  }

  /**
   * `HideTrigger` に重なっているかに従って、自身を表示するかどうかを更新します。
   */
  private updateVisibilityByHideTriggers(): void {
    const hideTriggers = this.getHideTriggers();
    const hidden = hideTriggers.some((trigger) => trigger.checkHide(this));
    this.graphics.opacity = hidden ? 0 : 1;
  }

  public setByInfo(playerInfo: PlayerInfo): void {
    if (this.isInitialized) {
      const interval = global.session?.settings.playerSocketInterval ?? 0;
      const pos = vec(playerInfo.x, playerInfo.y);
      if (!this.hasMoved) {
        this.pos = pos;
        this.hasMoved = true;
      } else {
        this.stories.addStory(function *(this: Ghost) {
          yield* this.stories.storyMoveTo(pos, interval);
          this.nextVel = vec(playerInfo.x - this.pos.x, playerInfo.y - this.pos.y);
        }.bind(this));
      }
      this.nextVel = vec(playerInfo.x - this.pos.x, playerInfo.y - this.pos.y);
    }
  }

  public setByGraphicSetting(graphicSetting: GraphicSetting): void {
    this.characterAnimation.setupAnimations(this.graphics, graphicSetting.avatarName, this.height);
  }

  private getHideTriggers(): Array<HideGhostsTrigger> {
    let hideTriggers = this.hideTriggers;
    if (hideTriggers == null) {
      hideTriggers = this.field.getEntities(HideGhostsTrigger);
      this.hideTriggers = hideTriggers;
    }
    return hideTriggers;
  }
}