import {css} from "@emotion/react";


interface Props {
};

const styles = {
  root: css`
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
  `
};

export const DummyUserAvatar: React.FC<Props> = ({
}) => {
  return (
    <img css={styles.root} src="https://picsum.photos/160"/>
  );
};
