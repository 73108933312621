import {EntityConstructor, auth} from "@baton8/qroud-lib-repositories";
import lib, {OverlayComponent} from "@baton8/qroud-lib-resources";
import {createRoot} from "react-dom/client";
import {Root} from "src/overlays/root";


export type GameResource = {
  overlays?: Array<OverlayComponent>,
  entities?: Array<EntityConstructor>,
  messages?: Record<string, Record<string, string>>
};

export const render = (resources: Array<GameResource>): void => {
  const overlayComponents = [
    ...lib.overlays,
    ...resources.flatMap((resource) => resource.overlays ?? [])
  ];
  const entityConstructors = [
    ...lib.entities,
    ...resources.flatMap((resource) => resource.entities ?? [])
  ];
  const messages = {
    ja: {
      ...lib.messages.ja,
      ...resources.map((resource) => resource.messages?.ja ?? {}).reduce(Object.assign, {})
    },
    en: {
      ...lib.messages.en,
      ...resources.map((resource) => resource.messages?.en ?? {}).reduce(Object.assign, {})
    }
  };

  waitAuth().then(() => {
    const container = document.getElementById("root");
    if (container) {
      const root = createRoot(container);
      root.render(<Root overlayComponents={overlayComponents} entityConstructors={entityConstructors} messages={messages}/>);
    }
  });
};

const waitAuth = (): Promise<void> => {
  return new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      resolve();
      unsubscribe();
    });
  });
};