import {css} from "@emotion/react";
import {faAnglesLeft, faComment} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCallback, useState} from "react";
import {alpha, color, filterBlur, size} from "src/components/constants/constants";
import {ChatPane} from "./chatPane";


interface Props {
};

const styles = {
  root: css`
    inline-size: 100%;
    column-gap: ${size(8)};
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  `,
  paneWrapper: css`
    inline-size: ${size(56)};
    transform: translateX(${size(-8)});
    flex: 1;
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: relative;
    &[data-open="true"] {
      transform: translateX(${size(0)});
      opacity: 1;
    }
    &:before {
      inline-size: ${size(0)};
      block-size: ${size(0)};
      inset-inline-start: ${size(0)};
      inset-block-start: calc(75% - ${size(4)});
      transform: translateX(-100%);
      border-block-start: ${size(4)} solid transparent;
      border-block-end: ${size(4)} solid transparent;
      border-inline-end: ${size(4)} solid ${alpha(color("black"), 0.8)};
      position: absolute;
      content: "";
    }
  `,
  chatPane: css`
    inline-size: 100%;
    block-size: ${size(48)};
    pointer-events: none;
    &[data-open="true"] {
      pointer-events: all;
    }
  `,
  button: css`
    width: ${size(12)};
    height: ${size(24)};
    padding-inline: ${size(0)} !important;
    font-size: ${size(6)};
    border-start-end-radius: 100em;
    border-end-end-radius: 100em;
    color: ${color("primary", 3)};
    background-color: ${alpha(color("black"), 0.8)};
    backdrop-filter: ${filterBlur(1)};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all;
    &:hover {
      color: ${color("primary", 2)};
    }
  `
};

export const DesktopChatSlide: React.FC<Props> = ({
}) => {
  const [isOpen, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((isOpen) => !isOpen);
  }, []);

  return (
    <div css={styles.root}>
      <button css={styles.button} onClick={toggleOpen}>
        <FontAwesomeIcon icon={isOpen ? faAnglesLeft : faComment}/>
      </button>
      <div css={styles.paneWrapper} data-open={isOpen}>
        <ChatPane css={styles.chatPane} data-open={isOpen}/>
      </div>
    </div>
  );
};
