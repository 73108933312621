import {css, keyframes} from "@emotion/react";
import {ReactNode, useMemo, useRef, useState} from "react";
import {FormEvent} from "react";
import {alpha, boxShadow, color, filterBlur, size, smartphone} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";
import {Provider} from "./context";


export interface DrawerProps extends CustomDataAttributes {
  /** */
  isOpen: boolean;
  /** */
  onClose?: () => unknown;
  /**
   * フォーム送信時に実行されるコールバック関数。
   * これに関数が登録されていると、ドロワーの中身のルートとなる要素が `<div>` から `<form>` になり、その要素にこのコールバック関数が登録されます。
   */
  onSubmit?: (event: FormEvent<HTMLFormElement>) => unknown;
  /**
   * ルート要素の Z index。
   */
  zIndex?: number;
  /** */
  className?: string;
  /** */
  children?: ReactNode;
};

const styles = {
  root: css`
    inset: ${size(0)};
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
  `,
  background: css`
    width: 100%;
    height: 100%;
    inset: 0rem;
    background-color: ${alpha(color("white"), 0.3)};
    backdrop-filter: ${filterBlur(1)};
    opacity: 0;
    z-index: 0;
    position: absolute;
    animation: ${keyframes`
      to {opacity: 0;}
      from {opacity: 1;}
    `} 0.3s ease;
    &[data-open="true"] {
      opacity: 1;
      pointer-events: all;
      animation: ${keyframes`
        from {opacity: 0;}
        to {opacity: 1;}
      `} 0.3s ease;
    }
  `,
  main: css`
    width: 90%;
    height: 100%;
    max-width: ${size(256)};
    padding-block: ${size(10)};
    padding-inline: ${size(12)};
    inset-block: 0rem;
    inset-inline-end: 0rem;
    font-size: ${size(4)};
    background-color: ${alpha(color("white"), 0.95)};
    backdrop-filter: ${filterBlur(1)};
    box-shadow: ${boxShadow(alpha(color("primary", 5), 0.5), 1)};
    transform: translate(100%, 0%) translate(${size(2)}, 0rem);
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: absolute;
    box-sizing: border-box;
    pointer-events: all;
    animation: ${keyframes`
      to {transform: translate(100%, 0%) translate(${size(2)}, 0rem);}
      from {transform: translate(0%, 0%) translate(0rem, 0rem);}
    `} 0.3s ease;
    &[data-open="true"] {
      transform: translate(0%, 0%) translate(0rem, 0rem);
      animation: ${keyframes`
        from {transform: translate(100%, 0%) translate(${size(2)}, 0rem);}
        to {transform: translate(0%, 0%) translate(0rem, 0rem);}
      `} 0.3s ease;
    }
    ${smartphone()} {
      width: 100%;
      height: 90%;
      max-width: unset;
      padding-inline: ${size(8)};
      inset-block-end: 0rem;
      inset-block-start: unset;
      inset-inline: 0rem;
      transform: translate(0%, 100%) translate(0rem, ${size(2)});
      animation: ${keyframes`
        to {transform: translate(0%, 100%) translate(0rem, ${size(2)});}
        from {transform: translate(0%, 0%) translate(0rem, 0rem);}
      `} 0.3s ease;
      &[data-open="true"] {
        animation: ${keyframes`
          from {transform: translate(0%, 100%) translate(0rem, ${size(2)});}
          to {transform: translate(0%, 0%) translate(0rem, 0rem);}
        `} 0.3s ease;
      }
    }
  `,
  ornamentContainer: css`
    inset: ${size(0)};
    position: absolute;
    overflow: hidden;
    z-index: 0;
  `,
  ornament: css`
    inline-size: ${size(48)};
    block-size: ${size(48)};
    inset-block-start: ${size(-20)};
    inset-inline-start: ${size(-16)};
    background-color: ${alpha(color("primary", 5), 0.3)};
    border-radius: 100rem;
    position: absolute;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  onSubmit,
  zIndex,
  className,
  children,
  ...data
}) => {
  const TagName = onSubmit ? "form" : "div";

  const [prevOpen, setPrevOpen] = useState(isOpen);
  const [isExisting, setExisting] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  if (isOpen !== prevOpen) {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    if (isOpen) {
      setExisting(true);
    } else {
      intervalRef.current = setTimeout(() => setExisting(false), 300);
    }
    setPrevOpen(isOpen);
  }

  const contextValue = useMemo(() => ({
    isOpen,
    onClose
  }), [
    isOpen,
    onClose
  ]);

  return isExisting ? (
    <aside css={styles.root} className={className} style={zIndex ? {zIndex} : undefined} {...data}>
      <div css={styles.background} data-open={isOpen} onClick={onClose}/>
      <TagName css={styles.main} data-open={isOpen} onSubmit={onSubmit as any}>
        <div css={styles.ornamentContainer}>
          <div css={styles.ornament}/>
        </div>
        <Provider value={contextValue}>
          {children}
        </Provider>
      </TagName>
    </aside>
  ) : null;
};
