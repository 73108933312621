import {css} from "@emotion/react";
import {CSSInterpolation} from "@emotion/serialize";
import qixColor from "color";
import {WithNumber} from "src/utils/types";
import {COLORS} from "./colors";


export const SIDEBAR_WIDTH = "0.6rem";
export const HEADER_HEIGHT = "0.15rem";

export const FONT_FAMILY = "'Nunito', 'Dubai', 'M PLUS Rounded 1c', sans-serif";

type ColorScheme = keyof typeof COLORS;
type ColorLevel<S extends ColorScheme> = (typeof COLORS)[S] extends string ? never : WithNumber<keyof (typeof COLORS)[S]>;

export type UnleveledColorScheme = {[S in ColorScheme]: (typeof COLORS)[S] extends string ? S : never}[ColorScheme];
export type LeveledColorScheme = {[S in ColorScheme]: (typeof COLORS)[S] extends string ? never : S}[ColorScheme];

/**
 * @param weight ウェイト
 * @category CSS Utility
 */
export function fontWeight(weight: "normal" | "bold"): number {
  return weight === "normal" ? 400 : 700;
}

/**
 * @param scheme カラースキーム
 * @category CSS Utility
 */
export function color<S extends UnleveledColorScheme>(scheme: S): string;
/**
 * @param scheme カラースキーム
 * @param level 色の濃さ (0～9, 数字が大きいほど黒に近い)
 * @category CSS Utility
 */
// eslint-disable-next-line @typescript-eslint/unified-signatures
export function color<S extends LeveledColorScheme>(scheme: S, level: ColorLevel<S>): string;
export function color<S extends ColorScheme>(scheme: S, level?: ColorLevel<S>): string {
  if (level != null) {
    return (COLORS as any)[scheme][level];
  } else {
    return (COLORS as any)[scheme];
  }
};

/**
 * @category CSS Utility
 */
export function alpha(color: string, alpha: number): string {
  return qixColor(color).alpha(alpha).hexa();
};

/**
 * 行の高さを設定します。
 * さらに、before 疑似要素と after 疑似要素に負のマージンを設定することで、最初の行の上と最後の行の下に追加される余白を取り除きます。
 * これによって、ブロックの大きさがテキストぴったりに調整されます。
 *
 * いくつかの CSS 宣言をまとめた文字列を返すので、次のように他の CSS 宣言と並列する形で用いてください。
 * ```typescript
 * const style = css`
 *   font-size: ${size(4)};  // その他の CSS 宣言
 *   ${lineHeight(1.4)}
 * `;
 * ```
 * @param lineHeight 行の高さの倍率
 * @category CSS Utility
 */
export function lineHeight(lineHeight: number): CSSInterpolation {
  return css`
    line-height: ${lineHeight};
    &::before {
      block-size: ${size(0)};
      inline-size: ${size(0)};
      margin-block-start: ${(1 - lineHeight) * 0.5}em;
      display: block;
      content: "";
    }
    &::after {
      block-size: ${size(0)};
      inline-size: ${size(0)};
      margin-block-end: ${(1 - lineHeight) * 0.5}em;
      display: block;
      content: "";
    }
  `;
}

/**
 * ウィンドウサイズに合わせて拡大縮小する相対的な長さを返します。
 * CSS (Emotion) 上で長さを指定するときはこの関数を使い、px などの絶対単位は使用しないでください。
 * また、全体のデザインを統一するため、引数の `dimen` として渡す値には基本的に整数を用いてください。
 *
 * 画面サイズによって `size(1)` の実際の大きさは変動しますが、一般的なデスクトップパソコンのモニターでは `size(1)` がだいたい 5 px になります。
 * @param dimen 長さ
 * @category CSS Utility
 */
export function size(dimen: number): string {
  return `${0.01 * dimen}rem`;
}

/**
 * 罫線の太さを返します。
 * @param level 太さのレベル
 * @category CSS Utility
 */
export function borderWidth(level: number): string {
  return `${0.0025 * level}rem`;
}

/**
 * @category CSS Utility
 */
export function filterBlur(dimen: number): string {
  return "none"; // TODO: パフォーマンスのため一旦削除
}

/**
 * @category CSS Utility
 */
export function boxShadow(color: string, dimen: number): string {
  return `0rem ${0.01 * dimen}rem ${0.02 * dimen}rem ${color}`;
}

/**
 * @category CSS Utility
 */
export function shapeInset(dimens: {top?: number, right?: number, bottom?: number, left?: number}): string {
  return `inset(${0.01 * (dimens.top ?? 0)}rem ${0.01 * (dimens.right ?? 0)}rem ${0.01 * (dimens.bottom ?? 0)}rem ${0.01 * (dimens.left ?? 0)}rem)`;
}

/**
 * スマートフォンで開いたときに中身を適用するアットルールを返します。
 * 以下のように使います。
 * ```typescript
 * const style = css`
 *   ${smartphone()} {
 *     // スマートフォン用の CSS
 *   }
 * `;
 * ```
 * @category CSS Utility
 */
export function smartphone(): string {
  return "@media (orientation: portrait)";
}