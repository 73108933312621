import {Competition, PlayableDeck as Deck, createCompetition, getCompetition, getGroupByHandle, global} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import dayjs from "dayjs";
import {FormEvent, useCallback, useRef, useState} from "react";
import {size, smartphone} from "src/components/constants/constants";
import {Drawer, DrawerCloseButton} from "src/components/modules/drawer";
import {createOverlay} from "src/modules/create";
import {useSubject} from "src/modules/subject";
import {HoldCompetitionDrawerLibraryPart} from "./holdCompetitionDrawerLibraryPart";
import {HoldCompetitionDrawerMainPart} from "./holdCompetitionDrawerMainPart";


export interface HoldCompetitionDrawer {
  /**
   * このオーバーレイを表示するかどうか。
   */
  isOpen: boolean;
  /**
   * このオーバーレイの Z index。
   * @initialValue `undefined`
   * @defaultValue `190`
   */
  zIndex?: number;
  /**
   * イベントかデッキが選ばれたときに呼ばれるコールバック関数。
   * @param competition イベント (デッキが選ばれた場合はそのデッキで遊ぶための即席イベント)
   */
  onConfirm: (competition: Competition) => unknown;
};

const styles = {
  drawer: css`
  `,
  body: css`
    column-gap: ${size(8)};
    row-gap: ${size(6)};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    flex-grow: 1;
    flex-shrink: 1;
    ${smartphone()} {
      grid-template-columns: 1fr;
      grid-template-rows: max-content max-content;
    }
  `,
  heading: css`
    margin-block-end: ${size(4)};
  `
};

/**
 * - **Inner Props**: {@link HoldCompetitionDrawer}
 * @group React Components
 * @category Builtin Overlay
 */
export const HoldCompetitionDrawer = createOverlay<HoldCompetitionDrawer>(({
  zIndex = 300,
  isOpen,
  onConfirm
}) => {
  const [mode, setMode] = useState("main");

  const user = useSubject(global.userSubject);

  const [deck, setDeck] = useState<Deck | null>(null);
  const [name, setName] = useState<string>("");
  const isNameChangedRef = useRef(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = useCallback(() => {
    HoldCompetitionDrawer.propsSubject.update({isOpen: false});
  }, []);

  const handleSubmit = useCallback(async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault();
    try {
      setIsLoading(true);
      if (user != null && deck != null) {
        const group = await getGroupByHandle(user.handle);
        const id = await createCompetition({
          ownerGroupId: group.id,
          title: name || deck.name,
          deckIds: [deck.id],
          quizIds: [],
          appId: "", // TODO: appId 受け取る
          aggregationSettings: [],
          userTags: [],
          gameTags: [],
          entryRequirement: {allowAllGroup: true, groupIds: [], password: "", capacity: 0},
          viewRequirement: {allowAllGroup: true, groupIds: [], password: "", capacity: 0},
          openPeriod: {startAt: dayjs().subtract(1, "hour").unix().toString(), endAt: "0"},
          gameData: {roomId: ""}
        });
        const competition = await getCompetition(id);
        onConfirm?.(competition);
        HoldCompetitionDrawer.propsSubject.update({isOpen: false});
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [deck, name, user, onConfirm]);

  return (
    <Drawer css={styles.drawer} zIndex={zIndex} isOpen={isOpen} onClose={handleClose} onSubmit={handleSubmit}>
      <DrawerCloseButton/>
      {mode === "main" ? (
        <HoldCompetitionDrawerMainPart {...{deck, setDeck, name, setName, isNameChangedRef, isLoading, setMode}}/>
      ) : (
        <HoldCompetitionDrawerLibraryPart {...{setMode}}/>
      )}
    </Drawer>
  );
}, {
  isOpen: false,
  onConfirm: () => null
});
