import {css} from "@emotion/react";
import {Radio} from "src/components/common/radio";
import {size} from "src/components/constants/constants";
import {useLocale, useSetLocale} from "src/modules/translation";


interface Props {
};

const styles = {
  radio: css`
    display: flex;
    column-gap: ${size(4)};
  `
};

export const ChangeLocaleForm: React.FC<Props> = ({
}) => {
  const locale = useLocale();
  const setLocale = useSetLocale();

  return (
    <div css={styles.radio}>
      <Radio name="language" value="ja" label="日本語" checked={locale === "ja"} onChange={() => setLocale("ja")}/>
      <Radio name="language" value="en" label="English" checked={locale === "en"} onChange={() => setLocale("en")}/>
    </div>
  );
};
