import {css} from "@emotion/react";
import {faCaretRight} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {size} from "src/components";
import {Link} from "src/components/common/link";
import {useTranslation} from "src/modules/translation";


export interface Props {
};

const styles = {
  list: css`
    list-style-type: none;
  `,
  listItem: css`
    margin-inline-start: 1em;
    & +& {
      margin-block-start: ${size(2)};
    }
  `,
  listBullet: css`
    width: 1em;
    margin-inline-start: -1em;
    opacity: 0.6;
    display: inline-block;
  `
};

/**
 * - **Inner Props**: {@link Props}
 * @group React Components
 * @category Builtin Overlay
 */
export const LegalInfoList: React.FC<Props> = ({
}) => {
  const {trans} = useTranslation("menu");

  return (
    <ul css={styles.list}>
      <li css={styles.listItem}>
        <span css={styles.listBullet}><FontAwesomeIcon icon={faCaretRight}/></span>
        <Link href="https://portal.quizium.io/terms" target="_blank">{trans("terms")}</Link>
      </li>
      <li css={styles.listItem}>
        <span css={styles.listBullet}><FontAwesomeIcon icon={faCaretRight}/></span>
        <Link href="https://portal.quizium.io/privacy" target="_blank">{trans("privacy")}</Link>
      </li>
    </ul>
  );
};