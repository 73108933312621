import {switchEnv} from "@baton8/quizium-lib-repositories";
import axios, {Axios} from "axios";


export const QROUD_API_URL = switchEnv({
  production: "https://qroud-api.quizium.io",
  staging: "https://qroud-api-staging58.quizium.io",
  development: "https://qroud-api-dev.quizium.io"
});

/**
 * QROUD の API を呼ぶための Axios インスタンスです。
 * 各 API ごとにそれを呼ぶ関数が用意されているので、これを直接使用することは推奨しません。
 * @group Variables
 */
export const client = createClient();

function createClient(): Axios {
  const instance = axios.create({
    baseURL: QROUD_API_URL,
    timeout: 10000
  });

  return instance;
}