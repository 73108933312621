import {writeLog} from "@baton8/quizium-lib-repositories";
import dayjs from "dayjs";
import {Howl} from "howler";
import {BehaviorSubject} from "rxjs";


export const SES = {
  correct: "assets/sound/se/correctSound.wav",
  incorrect: "assets/sound/se/wrongBuzzer.wav"
} as const;

export const MASTER_VOLUME_KEY = "masterVolume";
export const MASTER_MUTE_KEY = "masterMute";
export const BGM_VOLUME_KEY = "bgmVolume";
export const BGM_MUTE_KEY = "bgmMute";
export const AMBIENT_VOLUME_KEY = "ambientVolume";
export const AMBIENT_MUTE_KEY = "ambientMute";
export const SE_VOLUME_KEY = "seVolume";
export const SE_MUTE_KEY = "seMute";

/**
 * ライブラリによらないサウンドシステムです。
 * 現在は Howler.js を利用しています。
 *
 * 以下のサウンドを鳴らすことができます。
 * - BGM — ループする, 1 つしか鳴らせない
 * - Ambient — ループする, 複数鳴らせる
 * - SE — ループしない, 複数鳴らせる
 *
 * `play` から始まるメソッドを呼ぶとサウンドを鳴らし、`stop` から始まるメソッドを呼ぶとサウンドを停止します。
 * 事前にロードが必要なものを鳴らす場合は、`load` から始まるメソッドを呼びます。
 */
export class SoundSystem {
  // 各種音量設定
  public readonly masterVolumeSubject: BehaviorSubject<number>;
  public readonly masterMuteSubject: BehaviorSubject<boolean>;
  public readonly bgmVolumeSubject: BehaviorSubject<number>;
  public readonly bgmMuteSubject: BehaviorSubject<boolean>;
  public readonly ambientVolumeSubject: BehaviorSubject<number>;
  public readonly ambientMuteSubject: BehaviorSubject<boolean>;
  public readonly seVolumeSubject: BehaviorSubject<number>;
  public readonly seMuteSubject: BehaviorSubject<boolean>;

  private readonly defaultMasterVolume: number = 0.2;

  // BGM
  private readonly bgmMap: Map<string, Howl>;
  // 環境音
  private readonly ambientMap: Map<string, Howl>;
  // SE
  private readonly seMap: Map<string, Howl>;
  private seCheckMap: Set<string>;

  /**
   * 現在の鳴らしている BGM です。
   */
  private currentBgm: Howl | undefined;

  public constructor() {
    this.masterVolumeSubject = createVolumeBehaviorSubject(MASTER_VOLUME_KEY, this.defaultMasterVolume);
    this.masterMuteSubject = createMuteBehaviorSubject(MASTER_MUTE_KEY, false);
    this.bgmVolumeSubject = createVolumeBehaviorSubject(BGM_VOLUME_KEY, 1);
    this.bgmMuteSubject = createMuteBehaviorSubject(BGM_MUTE_KEY, false);
    this.ambientVolumeSubject = createVolumeBehaviorSubject(AMBIENT_VOLUME_KEY, 1);
    this.ambientMuteSubject = createMuteBehaviorSubject(AMBIENT_MUTE_KEY, false);
    this.seVolumeSubject = createVolumeBehaviorSubject(SE_VOLUME_KEY, 1);
    this.seMuteSubject = createMuteBehaviorSubject(SE_MUTE_KEY, false);
    this.bgmMap = new Map<string, Howl>();
    this.ambientMap = new Map<string, Howl>();
    this.seMap = new Map<string, Howl>();
    this.seCheckMap = new Set<string>();
    this.currentBgm = undefined;

    // 音量変更時の更新処理
    this.masterVolumeSubject.subscribe((value) => {
      this.bgmMap.forEach((sound) => {
        sound.volume(this.bgmVolume);
      });
      this.ambientMap.forEach((sound) => {
        sound.volume(this.ambientVolume);
      });
      this.seMap.forEach((sound) => {
        sound.volume(this.seVolume);
      });
    });

    this.bgmVolumeSubject.subscribe((value) => {
      this.bgmMap.forEach((sound) => {
        sound.volume(this.bgmVolume);
      });
    });
    this.ambientVolumeSubject.subscribe((value) => {
      this.ambientMap.forEach((sound) => {
        sound.volume(this.ambientVolume);
      });
    });
    this.seVolumeSubject.subscribe((value) => {
      this.seMap.forEach((sound) => {
        sound.volume(this.seVolume);
      });
    });

    // ミュート変更時の更新処理
    this.masterMuteSubject.subscribe((value) => {
      this.bgmMap.forEach((sound) => {
        sound.mute(this.bgmMute);
      });
      this.ambientMap.forEach((sound) => {
        sound.mute(this.ambientMute);
      });
      this.seMap.forEach((sound) => {
        sound.mute(this.seMute);
      });
    });

    this.bgmMuteSubject.subscribe((value) => {
      this.bgmMap.forEach((sound) => {
        sound.mute(this.bgmMute);
      });
    });
    this.ambientMuteSubject.subscribe((value) => {
      this.ambientMap.forEach((sound) => {
        sound.mute(this.ambientMute);
      });
    });
    this.seMuteSubject.subscribe((value) => {
      this.seMap.forEach((sound) => {
        sound.mute(this.seMute);
      });
    });

    this.loadBaseSounds();
  }

  public saveVolume(): void {
    localStorage.setItem(MASTER_VOLUME_KEY, this.masterVolumeSubject.value.toString());
    localStorage.setItem(MASTER_MUTE_KEY, this.masterMuteSubject.value.toString());
    localStorage.setItem(BGM_VOLUME_KEY, this.bgmVolumeSubject.value.toString());
    localStorage.setItem(BGM_MUTE_KEY, this.bgmMuteSubject.value.toString());
    localStorage.setItem(AMBIENT_VOLUME_KEY, this.ambientVolumeSubject.value.toString());
    localStorage.setItem(AMBIENT_MUTE_KEY, this.ambientMuteSubject.value.toString());
    localStorage.setItem(SE_VOLUME_KEY, this.seVolumeSubject.value.toString());
    localStorage.setItem(SE_MUTE_KEY, this.seMuteSubject.value.toString());
  }

  /**
   * 基本的なサウンドを読み込みます。
   * ロード時間やメモリに影響するので、汎用的なものに限っています。
   */
  private loadBaseSounds(): void {
    this.loadSe(SES.correct);
    this.loadSe(SES.incorrect);
  }

  public get masterVolume(): number {
    return this.masterVolumeSubject.value;
  }

  // BGM

  private get bgmVolume(): number {
    return this.masterVolume * this.bgmVolumeSubject.value;
  }

  private get bgmMute(): boolean {
    return this.masterMuteSubject.value || this.bgmMuteSubject.value;
  }

  public loadBgm(key: string): boolean {
    if (key === null || this.bgmMap.has(key)) {
      return false;
    }

    if (key.indexOf("http") !== -1) {
      const howl = createWebSound(key, true, this.bgmVolume, this.bgmMute);
      if (howl !== null) {
        this.bgmMap.set(key, howl);
        return true;
      }
      return false;
    } else {
      const howl = new Howl({
        src: [key],
        loop: true,
        volume: this.bgmVolume,
        mute: this.bgmMute
      });
      this.bgmMap.set(key, howl);
      return true;
    }
  }

  public playBgm(key: string): void {
    if (!this.bgmMap.has(key)) {
      if (!this.loadBgm(key)) {
        return;
      }
    }

    const bgm = this.bgmMap.get(key);
    // 同じ曲のリクエストは無効
    if (this.currentBgm === bgm) {
      return;
    }

    // 前のBGMを止める
    this.stopBgm();

    // 曲がロード済みなら鳴らす。ロード済みでないならロードをかけて、その後鳴らす
    this.currentBgm = bgm;
    this.playSound(key, bgm!, true);
  }

  public stopBgm(): void {
    if (this.currentBgm !== null) {
      this.currentBgm?.stop();
      this.currentBgm?.unload();
    }
  }

  public releaseBgm(key: string): void {
    this.release(key, this.bgmMap);
  }

  // Ambient

  private get ambientVolume(): number {
    return this.masterVolume * this.ambientVolumeSubject.value;
  }

  private get ambientMute(): boolean {
    return this.masterMuteSubject.value || this.ambientMuteSubject.value;
  }

  public loadAmbient(key: string): boolean {
    if (key === null || this.ambientMap.has(key)) {
      return false;
    }

    if (key.indexOf("http") !== -1) {
      const howl = createWebSound(key, true, this.ambientVolume, this.ambientMute);
      if (howl !== null) {
        this.ambientMap.set(key, howl);
        return true;
      }
      return false;
    } else {
      const howl = new Howl({
        src: [key],
        loop: true,
        volume: this.ambientVolume,
        mute: this.ambientMute
      });
      this.ambientMap.set(key, howl);
      return true;
    }
  }

  public playAmbient(key: string): void {
    if (!this.ambientMap.has(key)) {
      this.loadAmbient(key);
    }

    const ambient = this.ambientMap.get(key);
    this.playSound(key, ambient!, true);
  }

  public stopAmbient(key: string): void {
    if (!this.ambientMap.has(key)) {
      return;
    }

    const ambient = this.ambientMap.get(key);
    ambient?.stop();
    ambient?.unload();
  }

  public stopAllAmbients(): void {
    this.ambientMap.forEach((sound) => {
      sound.stop();
      sound.unload();
    });
  }

  public releaseAmbient(key: string): void {
    this.release(key, this.ambientMap);
  }

  // SE

  private get seVolume(): number {
    return this.masterVolume * this.seVolumeSubject.value;
  }

  private get seMute(): boolean {
    return this.masterMuteSubject.value || this.seMuteSubject.value;
  }

  /**
   * `loadedCallback` にコールバック関数を指定すると、SE が読み込まれた段階でその関数が呼ばれます。
   * クイズで SE を使いたいときに、サウンドの読み込み完了後にクイズを出せるようにできます。
   */
  public loadSe(key: string, loadedCallback?: () => void): boolean {
    if (key === null) {
      return false;
    }

    if (this.seMap.has(key)) {
      const howl = this.seMap.get(key);
      runLoadedCallback(howl!, loadedCallback);
      return true;
    }

    if (key.indexOf("http") !== -1) {
      const howl = createWebSound(key, false, this.seVolume, this.seMute);
      if (howl !== null) {
        this.seMap.set(key, howl);
        runLoadedCallback(howl, loadedCallback);
        return true;
      }
      return false;
    } else {
      const howl = new Howl({
        src: [key],
        volume: this.seVolume,
        mute: this.seMute
      });
      this.seMap.set(key, howl);
      runLoadedCallback(howl, loadedCallback);
      return true;
    }
  }

  /**
   * `endCallback` にコールバック関数を指定すると、SE の再生が終了した段階でその関数が呼ばれます。
   */
  public playSe(key: string, endCallback?: () => void): void {
    // 同フレーム内の同時再生を防ぐ 0.01s までチェック
    const currentdate = dayjs().format("HHmmss");
    const checkKey = `${key}${currentdate}${Math.floor(dayjs().millisecond() / 10)}`;
    if (this.seCheckMap.has(checkKey)) {
      return;
    }

    if (!this.seMap.has(key)) {
      this.loadSe(key);
    }

    this.seCheckMap.add(checkKey);
    const se = this.seMap.get(key);
    this.playSound(key, se!, false, () => {
      this.seCheckMap.clear();
      endCallback?.();
    });
  }

  // SE の用途上本来必要としないはずだけど、一応用意
  public stopSe(key: string): void {
    if (!this.seMap.has(key)) {
      return;
    }

    const se = this.seMap.get(key);
    se?.stop();
  }

  public releaseSe(key: string): void {
    this.release(key, this.seMap);
  }

  // 汎用処理

  /**
   * サウンドがロード済みならそれを鳴らします。
   * ロード済みでないならロードをかけて、その後に鳴らします。
   */
  // loaderror があるので Promise を使う形は保留
  private playSound(key: string, howl: Howl, useFade: boolean, endCallback?: () => void): void {
    const state = howl.state();
    if (state === "loaded") {
      howl.play();
      if (useFade) {
        howl.fade(0, 0.2, 500);
      }
    } else {
      howl.load();
      howl.once("load", () => {
        howl.play();
        if (useFade) {
          howl.fade(0, this.bgmVolume, 500);
        }
      });
      howl.once("loaderror", () => {
        writeLog("SoundSystem", "Error : Load", {key});
      });
    }

    // SE用, 終了時のコールバック処理
    if (endCallback !== undefined) {
      howl.once("end", () => {
        endCallback();
      });
    }
  }

  private release(key: string, map: Map<string, Howl>): void {
    if (!map.has(key)) {
      return;
    }

    const audio = map.get(key);
    audio?.unload();
    map.delete(key);
  }
}

const createWebSound = (key: string, isLoop: boolean, volumeValue: number, muteValue: boolean): Howl | null => {
  // もっと省略した処理にできるが可読性優先
  // ogg は iOS が対応してないため除外除外
  // 参考元: http://edit.tonyu.jp/doc/%E3%83%96%E3%83%A9%E3%82%A6%E3%82%B6%E3%81%AE%E9%9F%B3%E5%A3%B0%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E5%AF%BE%E5%BF%9C%E7%8A%B6%E6%B3%81.html
  const soundformats = [".mp3", ".mp4", ".flac", ".wav", ".mid", ".mzo"];
  const howlformats = ["mp3", "mp4", "flac", "wav", "mid", "mzo"];
  for (let i: number = 0; i < soundformats.length; i ++) {
    if (key.indexOf(soundformats[i]) !== -1) {
      const howlformat = howlformats[i];
      const howl = new Howl({
        src: [key],
        format: [howlformat],
        loop: isLoop,
        volume: volumeValue,
        mute: muteValue
      });

      return howl;
    }
  }
  return null;
};

const createVolumeBehaviorSubject = (key: string, defaultValue: number): BehaviorSubject<number> => {
  const localStorageValue = localStorage.getItem(key);
  const volume = localStorageValue !== null ? parseFloat(localStorageValue) : defaultValue;
  return new BehaviorSubject<number>(volume);
};

const createMuteBehaviorSubject = (key: string, defaultValue: boolean): BehaviorSubject<boolean> => {
  const localStorageValue = localStorage.getItem(key);
  const value = localStorageValue !== null ? parseBoolean(localStorageValue) : defaultValue;
  return new BehaviorSubject<boolean>(value);
};

const parseBoolean = (value: string | null | undefined): boolean => {
  // null, undefined は false
  if (!value) {
    return false;
  }
  // 小文字にして判定
  return value.toLowerCase() === "true" || value === "1";
};

const runLoadedCallback = (howl: Howl, loadedCallback?: () => void): void => {
  if (loadedCallback !== undefined) {
    if (howl.state() === "loaded") {
      loadedCallback();
    } else {
      howl.once("load", () => {
        loadedCallback();
      });
    }
  }
};

export const sound = new SoundSystem();