import {FieldId, Session, getSessionEntryRequirement, listSessions, useApi, writeLog} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCallback} from "react";
import {Button} from "src/components/common/button";
import {Heading} from "src/components/common/heading";
import {ScrollList, ScrollListEmpty, ScrollListLoading, ScrollListMain} from "src/components/common/scrollList";
import {size, smartphone} from "src/components/constants/constants";
import {Drawer, DrawerBody, DrawerCloseButton, DrawerDescription, DrawerHeader, DrawerTitle} from "src/components/modules/drawer";
import {createOverlay} from "src/modules/create";
import {useTranslation} from "src/modules/translation";
import {InputPasswordDialog} from "src/overlays/common/inputPasswordDialog";
import {CreateSessionDrawer} from "src/overlays/core/createSessionDrawer";
import {SessionButton} from "src/overlays/core/selectSessionDrawer/sessionButton";


export interface SelectSessionDrawer {
  /**
   * 移動するセッションが選択されたときに呼ばれるコールバック関数。
   * QROUD ゲーム側では設定しないでください。
   */
  onSessionChange: (session: Session, fieldId?: FieldId) => unknown;
  /**
   * このオーバーレイの Z index。
   * @defaultValue `300`
   */
  zIndex?: number;

  isOpen: boolean;
};

const styles = {
  drawer: css`
  `,
  body: css`
    row-gap: ${size(6)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  heading: css`
    margin-block-end: ${size(4)};
    flex-grow: 0;
    flex-shrink: 0;
  `,
  officialSection: css`
  `,
  personalSection: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  buttonSection: css`
    display: none;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    ${smartphone()} {
      display: flex;
    }
  `,
  listWrapper: css`
  `,
  list: css`
    row-gap: ${size(4)};
    column-gap: ${size(4)};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: max-content;
    flex-grow: 1;
    ${smartphone()} {
      grid-template-columns: repeat(1, 1fr);
    }
  `
};

/**
 * - **Inner Props**: {@link SelectSessionDrawer}
 * @group React Components
 * @category Builtin Overlay
 */
export const SelectSessionDrawer = createOverlay<SelectSessionDrawer>(({
  onSessionChange,
  zIndex = 300,
  isOpen
}) => {
  const {trans} = useTranslation("selectSessionDrawer");

  const [sessions = []] = useApi(listSessions, [{}]);

  const openCreateSessionDrawer = useCallback(() => {
    SelectSessionDrawer.propsSubject.update({isOpen: false});
    setTimeout(() => {
      CreateSessionDrawer.propsSubject.update({isOpen: true});
    }, 300);
  }, []);

  const handleClick = useCallback(async (session: Session, fieldId?: FieldId) => {
    const requirement = await getSessionEntryRequirement(session.id);
    writeLog("SelectSessionDrawer", "handleClick", {session, fieldId, onSessionChange});
    if (requirement.password) {
      InputPasswordDialog.propsSubject.next({
        isVisible: true,
        zIndex: 210,
        correctPassword: requirement.password,
        onConfirm: () => {
          onSessionChange?.(session, fieldId);
          SelectSessionDrawer.propsSubject.update({isOpen: false});
        }
      });
    } else {
      onSessionChange?.(session, fieldId);
      SelectSessionDrawer.propsSubject.update({isOpen: false});
    }
  }, [onSessionChange]);

  const handleClose = useCallback(() => {
    SelectSessionDrawer.propsSubject.update({isOpen: false});
  }, []);

  return (
    <Drawer css={styles.drawer} zIndex={zIndex} isOpen={isOpen} onClose={handleClose}>
      <DrawerCloseButton/>
      <DrawerHeader addonNode={(
        <Button size="lg" type="button" onClick={openCreateSessionDrawer} iconNode={<FontAwesomeIcon icon={faPlus}/>}>{trans("createSession")}</Button>
      )}
      >
        <DrawerTitle>{trans("header.title")}</DrawerTitle>
        <DrawerDescription>{trans("header.description")}</DrawerDescription>
      </DrawerHeader>
      <DrawerBody css={styles.body}>
        <section css={styles.buttonSection}>
          <Button size="lg" type="button" onClick={openCreateSessionDrawer} iconNode={<FontAwesomeIcon icon={faPlus}/>}>{trans("createSession")}</Button>
        </section>
        <section css={styles.personalSection}>
          <Heading css={styles.heading} tagName="h3">{trans("heading.personal")}</Heading>
          <ScrollList css={styles.listWrapper} items={sessions} insertMargin={true}>
            <ScrollListLoading/>
            <ScrollListEmpty>{trans("noSessions")}</ScrollListEmpty>
            <ScrollListMain css={styles.list}>
              {(session, index) => (
                <SessionButton key={session.id} session={session} onClick={handleClick}/>
              )}
            </ScrollListMain>
          </ScrollList>
        </section>
      </DrawerBody>
    </Drawer>
  );
}, {
  isOpen: false,
  onSessionChange: () => null
});
