import {Group, getGroupById, useApi} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {SingleLineText} from "src/components/common/singleLineText";
import {CustomDataAttributes} from "src/modules/data";
import {GroupAvatar} from "./groupAvatar";


export interface GroupViewProps extends CustomDataAttributes {
  /**
   * グループ ID。
   * `group` が指定されている場合は無視されます。
   */
  id?: string;
  /**
   * グループ。
   * これが指定されると、コンポーネント内部で API を呼んでグループデータを取得しなくなります。
   * すでにグループオブジェクトを取得済みの場合は、これを指定することで余計な API 呼び出しを防ぐことができます。
   */
  group?: Group;
  /**
   * アバター画像を表示するかどうか。
   * @defaultValue `true`
   */
  showAvatar?: boolean;
  /**
   * 名前を表示するかどうか。
   * @defaultValue `true`
   */
  showName?: boolean;
  /**
   * グループ ID を表示するかどうか。
   * @defaultValue `false`
   */
  showHandle?: boolean;
  /** */
  className?: string;
};

const styles = {
  root: css`
    column-gap: 0.4em;
    display: flex;
    align-items: center;
    overflow: hidden;
  `,
  right: css`
    row-gap: ${1 / 4}em;
    display: flex;
    flex-direction: column;
  `,
  name: css`
  `,
  handle: css`
    font-size: ${3 / 4}em;
    opacity: 0.6;
  `
};

/**
 * グループのアバターと名前を表示します。
 * 内部でフォントサイズやフォントスタイルなどを設定していないので、外側の設定が継承されます。
 * @group React Components
 * @category Common Component
 */
export const GroupView: React.FC<GroupViewProps> = ({
  id,
  group,
  showAvatar = true,
  showName = true,
  showHandle = false,
  className,
  ...data
}) => {
  const [innerGroup] = useApi(getGroupById, (group == null && id != null) && [id]);
  const actualGroup = group ?? innerGroup;

  return (
    <div css={styles.root} className={className} {...data}>
      {(actualGroup != null && showAvatar) && (
        <GroupAvatar group={actualGroup}/>
      )}
      {(showName || showHandle) && (
        <div css={styles.right}>
          {showName && (
            <SingleLineText css={styles.name}>{actualGroup != null ? actualGroup.name : "?"}</SingleLineText>
          )}
          {showHandle && (
            <SingleLineText css={styles.handle}>{actualGroup != null ? "@" + actualGroup.handle : "?"}</SingleLineText>
          )}
        </div>
      )}
    </div>
  );
};
