import {PlayableDeck as Deck, PlayableDeckQuery as DeckQuery, listPlayableDecks as listDecks} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faSearch} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {ChangeEvent, ReactNode, useCallback, useState, useTransition} from "react";
import {UseInfiniteQueryResult, useInfiniteQuery} from "react-query";
import {HelperText} from "src/components/common/helperText";
import {Label} from "src/components/common/label";
import {ScrollList, ScrollListEmpty, ScrollListLoading, ScrollListMain, ScrollListMoreButton} from "src/components/common/scrollList";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";
import {useTranslation} from "src/modules/translation";
import {DeckListboxPane} from "./deckListboxPane";


interface DeckListboxProps {
  deck: Deck | null;
  label?: ReactNode;
  helperText?: ReactNode;
  onChange?: (deck: Deck | null) => unknown;
  className?: string;
};

const styles = {
  root: css`
  `,
  container: css`
    height: 20em;
    border-radius: ${size(1)};
    border: solid ${borderWidth(1)} ${alpha(color("black"), 0.3)};
    background-color: ${color("white")};
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    &:focus-within {
      border-color: ${color("primary", 5)};
      outline: solid ${borderWidth(1)} ${color("primary", 5)};
    }
  `,
  listScroll: css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  listLabel: css`
    margin-block-start: ${size(2)};
    margin-block-end: ${size(1)};
    padding-inline: 0.5em;
    font-size: ${3 / 4}em;
    opacity: 0.6;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  list: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  inputContainer: css`
    border-block-end: solid ${borderWidth(1)} ${alpha(color("black"), 0.3)};
    column-gap: 0.5em;
    padding-inline: 0.5em;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  icon: css`
    padding-block: 0.5em;
    color: ${alpha(color("primary", 5), 0.6)};
    display: flex;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  input: css`
    height: 1em;
    padding-block: 0.5em;
    line-height: 1;
    flex-grow: 1;
    flex-shrink: 1;
  `
};

const useListDecksInfinite = (query: DeckQuery): [Array<Deck> | undefined, any, UseInfiniteQueryResult] => {
  const result = useInfiniteQuery({
    queryKey: ["listDecksInfinite", query],
    queryFn: (context) => {
      const pageQuery = context.pageParam ?? {skip: 0, limit: 30};
      return listDecks({...query, ...pageQuery});
    },
    getNextPageParam: (lastReturn, returns) => {
      const total = lastReturn[1];
      const skip = returns.reduce((accum, [decks]) => accum + decks.length, 0);
      if (skip < total) {
        return {skip, limit: 30};
      } else {
        return undefined;
      }
    }
  });
  const decks = result.data?.pages.flatMap(([page]) => page);
  const error = result.error;
  return [decks, error, result];
};

/**
 * @group React Components
 * @category Common Component
 */
export const DeckListbox: React.FC<DeckListboxProps> = ({
  deck,
  label,
  helperText,
  onChange,
  className
}) => {
  const {trans} = useTranslation("deckListbox");
  const [, startTransition] = useTransition();

  const [search, setSearch] = useState("");

  const [query, setQuery] = useState<DeckQuery>({});
  const [optionDecks, , {refetch, fetchNextPage, hasNextPage}] = useListDecksInfinite({includePublic: true, ...query});

  const showSearch = !!search;

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearch(search);
    startTransition(() => {
      setQuery({includePublic: true, name: search});
    });
  }, []);

  const handleChange = useCallback((deck: Deck | null) => {
    setSearch("");
    onChange?.(deck);
  }, [onChange]);

  return (
    <div className={className} css={styles.root}>
      {!!label && (
        <Label>{label}</Label>
      )}
      <div css={styles.container}>
        <div css={styles.inputContainer}>
          <div css={styles.icon}><FontAwesomeIcon icon={faSearch}/></div>
          <input css={styles.input} value={search} onChange={handleInputChange}/>
        </div>
        <div css={styles.listScroll}>
          <div css={styles.listLabel}>{showSearch ? trans("hit") : trans("selected")}</div>
          <ScrollList items={showSearch ? optionDecks : deck != null ? [deck] : []}>
            <ScrollListLoading/>
            <ScrollListEmpty>{showSearch ? trans("empty") : trans("notSelected")}</ScrollListEmpty>
            <ScrollListMain css={styles.list}>
              {(optionDeck) => (
                <DeckListboxPane key={optionDeck.id} optionDeck={optionDeck} deck={deck} onChange={handleChange}/>
              )}
              <ScrollListMoreButton hasNext={showSearch ? hasNextPage : false} onNext={() => fetchNextPage()}/>
            </ScrollListMain>
          </ScrollList>
        </div>
      </div>
      {!!helperText && (
        <HelperText>{helperText}</HelperText>
      )}
    </div>
  );
};