import {css} from "@emotion/react";
import {lineHeight, size} from "src/components/constants/constants";
import {BlackPane} from "src/components/modules/blackPane";
import {GameCover} from "src/components/modules/gameCover";
import {createOverlay} from "src/modules/create";
import {useTranslation} from "src/modules/translation";


export interface PrestartPaneProps {
  isVisible: boolean;
  canStart: boolean;
};

const styles = {
  cover: css`
    padding-block: ${size(6)};
    padding-inline: ${size(6)};  
    display: flex;
    align-items: flex-start;
    justify-content: center;
    pointer-events: none;
  `,
  pane: css`
  `,
  text: css`
    font-size: ${size(4)};
    ${lineHeight(1.4)}
  `
};

/**
 * - **Inner Props**: {@link PrestartPaneProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const PrestartPane = createOverlay<PrestartPaneProps>(({
  isVisible,
  canStart
}) => {
  const {trans} = useTranslation("prestartPane");

  return isVisible ? (
    <GameCover css={styles.cover} zIndex={110}>
      <BlackPane css={styles.pane}>
        <p css={styles.text}>
          {canStart ? trans("select") : trans("wait")}
        </p>
      </BlackPane>
    </GameCover>
  ) : null;
}, {
  isVisible: false,
  canStart: false
});
