import {AVATAR_NAMES, MovedPlayerInfo, global, socket, writeLog} from "@baton8/qroud-lib-repositories";
import {
  AvatarDrawer,
  CharacterAnimation,
  Direction,
  FollowingNodeComponent,
  Player as PlayerInterface,
  borderWidth,
  color,
  fontWeight,
  size
} from "@baton8/qroud-lib-resources";
import {css} from "@emotion/react";
import {
  Actor,
  CollisionType,
  Engine,
  Graphic,
  GraphicsGroup,
  Input,
  Random,
  Shape,
  Vector,
  vec
} from "excalibur";
import {MainScene} from "src/game/scenes/main";
import {roundDown} from "src/utils/rounding";


type GraphicName = Direction | `${Direction}Stopping`;

const styles = {
  root: css`
    max-inline-size: ${size(24)};
    font-size: ${size(4)};
    font-weight: ${fontWeight("bold")};
    color: ${color("primary", 1)};
    text-shadow:
      ${borderWidth(-1)} ${borderWidth(0)} 0rem ${color("blackText")},
      ${borderWidth(1)} ${borderWidth(0)} 0rem ${color("blackText")},
      ${borderWidth(0)} ${borderWidth(-1)} 0rem ${color("blackText")},
      ${borderWidth(0)} ${borderWidth(1)} 0rem ${color("blackText")},
      ${borderWidth(-1)} ${borderWidth(-1)} 0rem ${color("blackText")},
      ${borderWidth(1)} ${borderWidth(-1)} 0rem ${color("blackText")},
      ${borderWidth(-1)} ${borderWidth(1)} 0rem ${color("blackText")},
      ${borderWidth(1)} ${borderWidth(1)} 0rem ${color("blackText")};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transform: translate(-50%, -100%);
  `
};

export class Player extends Actor implements PlayerInterface {
  private engine!: Engine;

  public direction: Direction;
  private graphicName: GraphicName;
  public avatarName: string | null;

  /**
   * プレイヤーが移動可能かどうか。
   * これが `true` の間は、ユーザーの操作による移動ができなくなります。
   * `allowMove` メソッドと `disallowMove` メソッドによって書き換えられます。
   */
  private canMove: boolean;
  /**
   * マップ移動中かどうか。
   * これが `true` の間は、ユーザーの操作による移動ができなくなり、プレイヤー情報の送信も止まります。
   * `MainScene` によって書き換えられます。
   */
  public isTransferring: boolean;

  private oldPlayerInfo: Pick<MovedPlayerInfo, "x" | "y" | "graphicName"> | null;
  private movingPivotPos: Vector | null;
  private timer: number;

  private pointerDownHandler: any;
  private pointerUpHandler: any;

  private characterAnimation: CharacterAnimation;

  public constructor() {
    super({
      collider: Shape.Box(24, 24),
      collisionType: CollisionType.Active,
      name: "Player",
      z: 1
    });
    this.direction = "down";
    this.graphicName = "down";
    this.avatarName = null;
    this.canMove = true;
    this.isTransferring = false;
    this.oldPlayerInfo = null;
    this.movingPivotPos = null;
    this.timer = 0;
    this.addComponent(new FollowingNodeComponent());
    this.characterAnimation = new CharacterAnimation();
  }

  public override onInitialize(engine: Engine): void {
    this.characterAnimation.setupAnimations(this.graphics, this.avatarName, this.height);
    this.setupFollowingNode();
    this.setupMouseHandlers(engine);
    this.sendSocket(engine);
    this.engine = engine;
  }

  public override onPreUpdate(engine: Engine, delta: number): void {
    this.updateZ();
    this.moveByKeyboard(engine);
    this.moveByMouse(engine);
    this.characterAnimation.updateAnimation(this.graphics, this.vel);
    this.direction = this.characterAnimation.direction;
    this.sendSocketRegularly(engine, delta);
  }

  public override onPreKill(): void {
    this.cleanupMouseHandlers();
  }

  private setupMouseHandlers(engine: Engine): void {
    const pointerDownHandler = (event: Input.PointerEvent): void => {
      this.movingPivotPos = event.screenPos;
    };
    const pointerUpHandler = (): void => {
      this.movingPivotPos = null;
    };
    engine.input.pointers.on("down", pointerDownHandler);
    engine.input.pointers.on("up", pointerUpHandler);
    this.pointerDownHandler = pointerDownHandler;
    this.pointerUpHandler = pointerUpHandler;
  }

  private cleanupMouseHandlers(): void {
    this.engine.input.pointers.off("down", this.pointerDownHandler);
    this.engine.input.pointers.off("up", this.pointerUpHandler);
  }

  public setupAvatar(): void {
    const random = new Random();
    const avatarName = AVATAR_NAMES[random.integer(0, AVATAR_NAMES.length - 1)];
    AvatarDrawer.propsSubject.update({avatarName});
    this.changeAvatar(avatarName);
  }

  private setupFollowingNode(): void {
    const followingNode = this.get(FollowingNodeComponent)!;
    followingNode.anchor = "top";
    followingNode.offset = vec(0, -24);
    followingNode.node = (
      <div css={styles.root}>
        {global.user!.nickname}
      </div>
    );
  }

  private updateZ(): void {
    const scene = this.engine.currentScene as MainScene;
    if (scene.field.data) {
      const fieldHeight = scene.field?.boundingBox.height;
      this.z = roundDown(this.pos.y / fieldHeight * 20, 1);
    }
  }

  private moveByKeyboard(engine: Engine): void {
    const activeTagName = document.activeElement?.tagName;
    const isMoveAllowed = this.canMove && !this.isTransferring && (activeTagName !== "INPUT" && activeTagName !== "TEXTAREA");

    if (isMoveAllowed) {
      const dashing = engine.input.keyboard.isHeld(Input.Keys.ShiftLeft) || engine.input.keyboard.isHeld(Input.Keys.ShiftRight);
      const speed = dashing ? 300 : 180;

      const vel = vec(0, 0);
      if (engine.input.keyboard.isHeld(Input.Keys.ArrowLeft) || engine.input.keyboard.isHeld(Input.Keys.A)) {
        vel.x -= speed;
      }
      if (engine.input.keyboard.isHeld(Input.Keys.ArrowRight) || engine.input.keyboard.isHeld(Input.Keys.D)) {
        vel.x += speed;
      }
      if (engine.input.keyboard.isHeld(Input.Keys.ArrowUp) || engine.input.keyboard.isHeld(Input.Keys.W)) {
        vel.y -= speed;
      }
      if (engine.input.keyboard.isHeld(Input.Keys.ArrowDown) || engine.input.keyboard.isHeld(Input.Keys.S)) {
        vel.y += speed;
      }
      this.vel = vel;
    }
  }

  private moveByMouse(engine: Engine): void {
    const isMoveAllowed = this.canMove && this.movingPivotPos != null;

    if (isMoveAllowed) {
      const cursorPos = engine.input.pointers.primary.lastScreenPos;
      const diff = cursorPos.sub(this.movingPivotPos!);
      this.vel = diff.scale(1.75).clampMagnitude(300);
    }
  }

  public allowMove(): void {
    this.canMove = true;
  }

  public disallowMove(): void {
    this.canMove = false;
  }

  public moveTo(pos: Vector): void;
  public moveTo(x: number, y: number): void;
  public moveTo(posOrX: Vector | number, y?: number): void {
    this.pos = typeof posOrX === "number" ? vec(posOrX, y!) : posOrX;
    if (this.engine) {
      this.sendSocket(this.engine);
    }
  }

  public changeAvatar(avatarName: string): void {
    this.avatarName = avatarName;
    this.characterAnimation.setupAnimations(this.graphics, this.avatarName, this.height);
    if (this.engine) {
      this.sendGraphicSetting(this.engine);
    }
  }

  public changeOpacity(opacity: number): void {
    this.graphics.opacity = opacity;
    if (this.engine) {
      this.sendGraphicSetting(this.engine);
    }
  }

  private sendSocketRegularly(engine: Engine, delta: number): void {
    const interval = global.session?.settings.playerSocketInterval ?? Number.POSITIVE_INFINITY;
    this.timer += delta;
    if (this.timer > interval) {
      this.sendSocket(engine);
      this.timer -= interval;
    }
  }

  private sendSocket(engine: Engine): void {
    const scene = engine.currentScene as MainScene;
    const user = global.user;
    if (user != null && scene.isSessionReady && !this.isTransferring) {
      const {x, y} = this.pos;
      const {session, field} = scene;
      const graphicName = this.graphicName;
      if (this.oldPlayerInfo == null || (this.oldPlayerInfo.x !== x || this.oldPlayerInfo.y !== y || this.oldPlayerInfo.graphicName !== graphicName)) {
        const playerInfo = {
          sessionId: session.id,
          fieldId: field.id,
          fieldGroupId: field.fieldGroupId,
          x,
          y,
          graphicName
        } as MovedPlayerInfo;
        socket.emit("movePlayer", playerInfo, user.id);
      }
      this.oldPlayerInfo = {x, y, graphicName};
    }
  }

  private sendGraphicSetting(engine: Engine): void {
    const scene = engine.currentScene as MainScene;
    const user = global.user;
    if (user != null && scene.isSessionReady && !this.isTransferring) {
      const graphicSetting = {
        avatarName: this.avatarName,
        opacity: this.graphics.opacity
      };
      writeLog("Player", "sendGraphicSetting", graphicSetting);
      socket.emit("changeGraphicSetting", user.id, graphicSetting);
    }
  }
}

export const createGraphic = (graphic: Graphic, pos: Vector): Graphic => {
  const graphicsGroup = new GraphicsGroup({members: [{graphic, pos}]});
  return graphicsGroup;
};