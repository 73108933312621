import {css} from "@emotion/react";
import {Actor, Engine} from "excalibur";
import {alpha, color, filterBlur, size} from "src/components";
import {FollowingNodeComponent, withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";


const styles = {
  root: css`
    padding-block: ${size(1)};
    padding-inline: ${size(2)};
    border-radius: ${size(2)};
    font-size: ${size(4)};
    color: ${color("whiteText")};
    background-color: ${alpha(color("black"), 0.8)};
    backdrop-filter: ${filterBlur(1)};
    transform: translate(-50%, -100%);
  `
};

export interface TestFollowingNodeEntityProperties {
  message: string;
};

/**
 * デバッグ用のエンティティです。
 * @category Builtin Entity
 * @deprecated
 */
export class TestFollowingNodeEntity extends withFieldEntity(Actor) {
  private message: string;

  public constructor(configs: FieldEntityConfigs<TestFollowingNodeEntityProperties>) {
    super(configs);
    this.message = configs.properties.message;
    this.addComponent(new FollowingNodeComponent());
  }

  public override onInitialize(engine: Engine): void {
    this.setupFollowingNode();
  }

  private setupFollowingNode(): void {
    const followingNode = this.get(FollowingNodeComponent)!;
    // このエンティティの上端に React 要素を表示
    followingNode.anchor = "top";
    // 表示する React 要素
    followingNode.node = (
      <div css={styles.root}>
        {this.message}
      </div>
    );
  }
}