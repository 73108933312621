import {css} from "@emotion/react";
import {faCog, faUser} from "@fortawesome/pro-regular-svg-icons";
import {useCallback, useEffect} from "react";
import {size, smartphone} from "src/components";
import {Drawer, DrawerAddon, DrawerAddonButton, DrawerCloseButton, DrawerDescription, DrawerHeader, DrawerTitle} from "src/components/modules/drawer";
import {createOverlay} from "src/modules/create";
import {useTranslation} from "src/modules/translation";
import {AvatarDrawer} from "src/overlays/core/avatarDrawer";
import {MenuMain} from "src/overlays/core/menu/menuMain";
import {sound} from "src/sound";


export interface MenuProps {
  /** */
  onLogout: () => unknown;
  /** */
  isOpen: boolean;
  /**
   * このオーバーレイの Z index。
   * @defaultValue `300`
   */
  zIndex?: number;
};

const styles = {
  drawer: css`
  `,
  menuPageButtonList: css`
    inset-block-start: ${size(30)};
    inset-block-end: ${size(10)};
    inset-inline-start: ${size(-16)};
    inset-inline-end: auto;
    gap: ${size(4)};
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    ${smartphone()} {
      inset-block-start: ${size(-16)};
      inset-block-end: auto;
      inset-inline-start: ${size(30)};
      inset-inline-end: ${size(5)};
      flex-direction: row-reverse;
    }
  `
};

/**
 * - **Inner Props**: {@link MenuProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const Menu = createOverlay<MenuProps>(({
  isOpen,
  onLogout,
  zIndex = 300
}) => {
  const {trans} = useTranslation("menu");

  const handleClose = useCallback(() => {
    Menu.propsSubject.update({isOpen: false});
  }, []);

  const showAvatarDrawer = useCallback(() => {
    Menu.propsSubject.update({isOpen: false});
    setTimeout(() => {
      AvatarDrawer.propsSubject.update({isOpen: true});
    }, 300);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      sound.saveVolume();
    }
  }, [isOpen]);

  return (
    <Drawer css={styles.drawer} zIndex={zIndex} isOpen={isOpen} onClose={handleClose}>
      <DrawerCloseButton/>
      <DrawerAddon>
        <DrawerAddonButton icon={faUser} onClick={showAvatarDrawer}/>
        <DrawerAddonButton icon={faCog} highlight={true}/>
      </DrawerAddon>
      <DrawerHeader>
        <DrawerTitle>{trans("header.title")}</DrawerTitle>
        <DrawerDescription>{trans("header.description")}</DrawerDescription>
      </DrawerHeader>
      <MenuMain onLogout={onLogout}/>
    </Drawer>
  );
}, {
  isOpen: false,
  onLogout: () => null
});
