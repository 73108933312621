import {css} from "@emotion/react";
import {ReactNode, memo} from "react";


interface Props {
  x: number;
  y: number;
  children: ReactNode;
};

const styles = {
  body: css`
    position: absolute;
  `
};

export const FollowingNodeItem: React.FC<Props> = memo(({
  x,
  y,
  children
}) => {
  return (
    <div css={styles.body} style={{insetInlineStart: x, insetBlockStart: y}}>
      {children}
    </div>
  );
});
