import "dayjs/locale/ja";
import {global} from "@baton8/qroud-lib-repositories";
import dayjs, {Dayjs} from "dayjs";
import {Primitive} from "ts-essentials";


/**
 * 文字列フォーマット用の各種の関数を生成します。
 *
 * この関数は、マップエンティティの実装で利用されることを想定しています。
 * オーバーレイの実装では、`useTranslation` フックを利用した方が便利です。
 * @param scope スコープ
 * @return フォーマット用の関数群
 */
export const createTranslation = (scope?: string): TransCallbacks => {
  const trans = (key: string, values?: any): string => {
    const fullKey = key.includes(":") ? key.replace(":", ".") : (scope != null ? `${scope}.` : "") + key;
    const defaultMessage = values?.defaultMessage ?? `<${fullKey}>`;
    const message = global.intl.formatMessage({id: fullKey, defaultMessage}, values);
    return message;
  };

  const transDate = (date: string | number | Date | Dayjs | null | undefined): string => {
    if (global.locale === "ja") {
      return dayjs(date).locale("ja").format("YYYY/MM/DD");
    } else {
      return dayjs(date).locale("en").format("DD/MM/YYYY");
    }
  };

  const transTime = (date: string | number | Date | Dayjs | null | undefined): string => {
    if (date !== null && date !== undefined) {
      if (global.locale === "ja") {
        return dayjs(date).locale("ja").format("YYYY/MM/DD HH:mm");
      } else {
        return dayjs(date).locale("en").format("DD/MM/YYYY HH:mm");
      }
    } else {
      return "";
    }
  };

  const transNumber = (number: number | null | undefined, options?: {digit?: number, withSign?: boolean}): string => {
    const intlOptions = {minimumFractionDigits: options?.digit, maximumFractionDigits: options?.digit};
    if (number !== null && number !== undefined) {
      const intl = new Intl.NumberFormat(global.locale, intlOptions);
      if (number > 0) {
        return (options?.withSign ? "+" : "") + intl.format(number);
      } else if (number === 0) {
        return (options?.withSign ? "±" : "") + intl.format(number);
      } else {
        return "−" + intl.format(-number);
      }
    } else {
      return "";
    }
  };

  return {
    trans,
    transDate,
    transTime,
    transNumber
  };
};

type TransCallbacks = {
  trans: (key: string, values?: Record<string, Primitive | ((parts: Array<string>) => string)>) => string,
  transDate: (date: string | number | Date | Dayjs | null | undefined) => string,
  transTime: (date: string | number | Date | Dayjs | null | undefined) => string,
  transNumber: (number: number | null | undefined, options?: {digit?: number, withSign?: boolean}) => string
};