import {css} from "@emotion/react";
import {ReactNode} from "react";
import {borderWidth, color, fontWeight, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


export interface HeadingProps extends CustomDataAttributes {
  /**
   * 使用する HTML タグの名前。
   * @defaultValue `"h3"`
   */
  tagName?: keyof JSX.IntrinsicElements;
  /** */
  className?: string;
  /** */
  children?: ReactNode;
};

const styles = {
  root: css`
    padding-block: ${size(1)};
    padding-inline-start: ${size(3)};
    font-size: ${size(5)};
    font-weight: ${fontWeight("bold")};
    border-inline-start: solid ${size(1)} ${color("primary", 5)};
    display: flex;
    align-items: center;
    &:after {
      margin-inline-start: ${size(3)};
      border-block-start: dashed ${borderWidth(1)} ${color("primary", 5)};
      opacity: 0.6;
      flex-grow: 1;
      content: "";
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const Heading: React.FC<HeadingProps> = ({
  tagName = "h3",
  className,
  children,
  ...data
}) => {
  const TagName = tagName;

  return (
    <TagName css={styles.root} className={className} {...data}>
      {children}
    </TagName>
  );
};
