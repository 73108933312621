import {global} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faDoorOpen, faMap, faShare, faUser} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCallback} from "react";
import {Button} from "src/components/common/button";
import {LinkButton} from "src/components/common/linkButton";
import {SingleLineText} from "src/components/common/singleLineText";
import {fontWeight, size} from "src/components/constants/constants";
import {WhitePane} from "src/components/modules/whitePane";
import {useBehaviorSubject} from "src/modules/subject";
import {useTranslation} from "src/modules/translation";
import {SelectSessionDrawer} from "src/overlays/core/selectSessionDrawer";
import {ShareSessionLinkDialog} from "src/overlays/core/shareSessionLinkDialog";
import {OpenMenuButton} from "./openMenuButton";


interface Props {
};

const styles = {
  pane: css`
    inline-size: ${size(96)};
    column-gap: ${size(4)};
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  left: css`
    column-gap: ${size(2)};
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  right: css`
    flex-grow: 1;
  `,
  buttonList: css`
    row-gap: ${size(2)};
    font-size: ${size(3)};
    display: flex;
    flex-direction: column;
  `,
  session: css`
    column-gap: ${size(2)};
    font-size: ${size(3)};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  sessionInner: css`
    display: flex;
    align-items: center;
  `,
  field: css`
    margin-block-start: ${size(2)};
    font-size: ${size(4)};
    font-weight: ${fontWeight("bold")};
    display: flex;
    align-items: center;
  `,
  playerCount: css`
    margin-block-start: ${size(2)};
    font-size: ${size(3)};
    opacity: 0.6;
    display: flex;
    align-items: center;
  `,
  icon: css`
    margin-inline-end: 0.4em;
  `,
  shareButton: css`
    flex-grow: 0;
    flex-shrink: 0;
  `
};

export const SessionPane: React.FC<Props> = ({
}) => {
  const {trans} = useTranslation("bottomOverlay");

  const session = useBehaviorSubject(global.sessionSubject);
  const field = useBehaviorSubject(global.fieldSubject);

  const changeSession = useCallback(() => {
    SelectSessionDrawer.propsSubject.update({isOpen: true});
  }, []);

  const showLink = useCallback(() => {
    ShareSessionLinkDialog.propsSubject.update({isOpen: true});
  }, []);

  return (
    <WhitePane css={styles.pane}>
      <div css={styles.left}>
        <div>
          <OpenMenuButton/>
        </div>
        <div css={styles.buttonList}>
          <Button iconNode={<FontAwesomeIcon icon={faDoorOpen}/>} type="button" hotkeys="l" onClick={changeSession}>{trans("changeSession")}</Button>
          <Button iconNode={<FontAwesomeIcon icon={faMap}/>} type="button" hotkeys="m" disabled={true}>{trans("changeField")}</Button>
        </div>
      </div>
      <div css={styles.right}>
        <div css={styles.session}>
          <div css={styles.sessionInner}>
            <FontAwesomeIcon css={styles.icon} icon={faDoorOpen}/>
            <SingleLineText>{session?.id === "public" ? trans("officialSessionName") : session?.name ?? "?"}</SingleLineText>
          </div>
          <LinkButton css={styles.shareButton} iconNode={<FontAwesomeIcon icon={faShare}/>} onClick={showLink}>{trans("share")}</LinkButton>
        </div>
        <div css={styles.field}>
          <FontAwesomeIcon css={styles.icon} icon={faMap}/>
          <SingleLineText>{field?.name ?? "?"}</SingleLineText>
        </div>
        <div css={styles.playerCount}>
          <FontAwesomeIcon css={styles.icon} icon={faUser}/>
          <SingleLineText>?</SingleLineText>
        </div>
      </div>
    </WhitePane>
  );
};
