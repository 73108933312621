import {AggregationUnit, getAggregationTagSpec, useApi} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {useMemo} from "react";
import {CustomDataAttributes} from "src/modules/data";
import {useLocale, useTranslation} from "src/modules/translation";


export interface ScoreViewProps extends CustomDataAttributes {
  /**
   * 集計タグ。
   * これに応じて、スコアの表示形式 (小数点以下の桁数や単位) が切り替わります。
   */
  aggregationTag: string;
  /**
   * 表示する数値。
   */
  point: number;
  /** */
  withSign?: boolean;
  /** */
  className?: string;
};

const styles = {
  root: css`
  `,
  decimal: css`
    font-size: 80%;
  `,
  unit: css`
    margin-inline-start: 0.2em;
    font-size: 80%;
  `,
  sign: css`
    margin-inline-end: 0.1em;
  `
};

/**
 * スコアを適切にフォーマットして表示します。
 * イベントのスコアを表示する際はこのコンポーネントを使用することを推奨します。
 * @group React Components
 * @category Common Component
 */
export const ScoreView: React.FC<ScoreViewProps> = ({
  aggregationTag,
  point,
  withSign = false,
  className,
  ...data
}) => {
  const locale = useLocale();
  const {transNode, transNumber} = useTranslation("scoreView");

  const [spec] = useApi(getAggregationTagSpec, [aggregationTag]);

  const decimalSeparator = useMemo(() => new Intl.NumberFormat(locale).formatToParts(1.1).find((part) => part.type === "decimal")?.value ?? ".", [locale]);

  const sign = point > 0 ? "+" : point < 0 ? "−" : "±";

  const integerString = transNumber(Math.floor(point));
  const decimalString = spec != null ? Math.floor(point * (10 ** spec.fractionDigits) % (10 ** spec.fractionDigits)).toString().padStart(spec.fractionDigits, "0") : "";

  return spec != null ? (
    <span css={styles.root} className={className} {...data}>
      {transNode(getAggregationUnitKey(spec?.unit), {
        point,
        pointNode: (parts) => (
          <span>
            {withSign && <span css={styles.sign}>{sign}</span>}
            <span>{integerString}</span>
            {spec.fractionDigits > 0 && <span css={styles.decimal}>{decimalSeparator}{decimalString}</span>}
          </span>
        ),
        unit: (parts) => <span css={styles.unit}>{parts}</span>
      })}
    </span>
  ) : null;
};

const getAggregationUnitKey = (unit: AggregationUnit | undefined): string => {
  if (unit === AggregationUnit.Number) {
    return "number";
  } else if (unit === AggregationUnit.NumberPoint) {
    return "numberPoint";
  } else if (unit === AggregationUnit.TimeSecond) {
    return "second";
  } else if (unit === AggregationUnit.TimeMinute) {
    return "minute";
  } else if (unit === AggregationUnit.TimeHour) {
    return "hour";
  } else {
    return "number";
  }
};