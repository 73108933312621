import {css} from "@emotion/react";
import {ReactNode} from "react";
import {GameCover} from "src/components/modules/gameCover";
import {createOverlay} from "src/modules/create";
import {FollowingNodeItem} from "src/overlays/core/followingNodeContainer/followingNodeItem";


export interface FollowingNodeContainerProps {
  zIndex?: number;
  specs: Array<FollowingNodeSpec>;
}

export type FollowingNodeSpec = {
  key: string,
  x: number,
  y: number,
  node: ReactNode
};

const styles = {
  cover: css`
    pointer-events: none;
  `
};

/**
 * - **Inner Props**: {@link FollowingNodeContainerProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const FollowingNodeContainer = createOverlay<FollowingNodeContainerProps>(({
  zIndex = 50,
  specs
}) => {
  return (
    <GameCover css={styles.cover} zIndex={zIndex}>
      {specs.map((spec) => spec.node && (
        <FollowingNodeItem key={spec.key} x={spec.x} y={spec.y}>
          {spec.node}
        </FollowingNodeItem>
      ))}
    </GameCover>
  );
}, {
  specs: []
});