import {switchEnv} from "@baton8/quizium-lib-repositories";
import {Socket, io} from "socket.io-client";
import {SocketEventsFromClient, SocketEventsFromServer} from "src/types/socket/types";


export const QROUD_SOCKET_URL = switchEnv({
  production: "wss://qroud-api.quizium.io",
  staging: "wss://qroud-api-staging58.quizium.io",
  development: "wss://qroud-api-dev.quizium.io"
});

/**
 * QROUD の WebSocket API を扱うための Socket.IO インスタンスです。
 * 各 API ごとにそれを呼ぶ関数が用意されているので、これを直接使用することは推奨しません。
 */
export const socket = createSocket();

function createSocket(): Socket<SocketEventsFromServer, SocketEventsFromClient> {
  const instance = io(QROUD_SOCKET_URL, {autoConnect: false, transports: ["websocket"]});
  return instance;
}