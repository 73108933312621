import {css} from "@emotion/react";
import mousetrap, {ExtendedKeyboardEvent} from "mousetrap";
import {ButtonHTMLAttributes, MouseEvent, ReactNode, forwardRef, useEffect} from "react";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";


export interface LinkButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "css" | "type"> {
  /**
   * テキストの左の表示するアイコン。
   */
  iconNode?: ReactNode;
  /**
   * ボタンの種類。
   * HTML と違ってデフォルト値が `"button"` になっているので、フォームの送信ボタンとして使う場合は明示的に `"submit"` を指定してください。
   * @defaultValue `"button"`
   */
  type?: "submit" | "reset" | "button";
  /**
   * ボタンを発火するショートカットキー。
   * Mousetrap のキー設定が利用できます。
   */
  hotkeys?: string | Array<string>;
  /** */
  onClick?: (event: MouseEvent<HTMLButtonElement> | ExtendedKeyboardEvent) => void;
  /** */
  className?: string;
}

const styles = {
  root: css`
    border-radius: ${size(1)};
    color: ${color("primary", 5)};
    line-height: 1;
    text-decoration-line: underline;
    cursor: pointer;
    transition: color 0.2s ease;
    &:hover {
      color: ${color("primary", 4)};
    }
    &:focus-visible {
      outline: solid ${borderWidth(2)} ${alpha(color("primary", 5), 0.6)};
      outline-offset: ${borderWidth(1)};
    }
  `,
  icon: css`
    margin-inline-end: 0.4em;
    display: inline;
  `
};

/**
 * テキストのみのボタンです。
 * @group React Components
 * @category Common Component
 */
export const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>(({
  iconNode,
  type = "button",
  hotkeys,
  onClick,
  className,
  children,
  ...props
}, ref) => {
  useEffect(() => {
    if (hotkeys != null) {
      mousetrap.bind(hotkeys, (event) => {
        onClick?.(event);
      });
      return () => {
        mousetrap.unbind(hotkeys);
      };
    } else {
      return undefined;
    }
  }, [hotkeys, onClick]);

  return (
    <button className={className} css={styles.root} type={type} onClick={onClick} ref={ref} {...props}>
      {iconNode && (
        <div css={styles.icon}>
          {iconNode}
        </div>
      )}
      {children}
    </button>
  );
});