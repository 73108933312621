import {PublicUser, User, getUserById, global, updateUser} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormEvent, useCallback, useRef, useState} from "react";
import {Button} from "src/components/common/button";
import {Input} from "src/components/common/input";
import {size} from "src/components/constants/constants";
import {Dialog, DialogBody, DialogCloseButton} from "src/components/modules/dialog";
import {createOverlay} from "src/modules/create";


export interface InputUserNameDialogProps {
  /**
   * このオーバーレイを表示するかどうか。
   */
  isVisible: boolean;
  /**
   * このオーバーレイの Z index。
   * @defaultValue `100`
   */
  zIndex?: number;
  /**
   * ユーザーデータ
   */
  user: User | PublicUser;
  /**
   * オーバーレイを一意に識別するためのキー。
   */
  key?: string;
  /**
   * 閉じるボタンを押したときに実行されるコールバック関数。
   */
  onCancel?: () => unknown;
  /**
   * パスワードが正しく入力されたときに実行されるコールバック関数。
   */
  onConfirm?: () => unknown;
};

const styles = {
  body: css`
    row-gap: ${size(4)};
    font-size: ${size(4)};
    display: flex;
    flex-direction: column;
  `,
  guide: css`
    text-align: center;
  `,
  form: css`
    row-gap: ${size(4)};
    display: flex;
    flex-direction: column;
  `,
  buttonList: css`
    display: flex;
    justify-content: center;
  `,
  button: css`
    width: ${size(36)};
  `
};

/**
 * - **Inner Props**: {@link InputUserNameDialogProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const InputUserNameDialog = createOverlay<InputUserNameDialogProps>(({
  isVisible,
  zIndex = 100,
  user,
  onCancel,
  onConfirm
}) => {
  const [nickname, setNickname] = useState(user.nickname);
  const processingRef = useRef(false);

  const handleClose = useCallback(() => {
    onCancel?.();
    setNickname(user.nickname);
    InputUserNameDialog.propsSubject.update({isVisible: false});
  }, [onCancel, user.nickname]);

  const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!processingRef.current) {
      processingRef.current = true;
      const fullUser = await getUserById(user.id);
      await updateUser({...fullUser, nickname, locale: "ja"});
      global.userSubject.next({...fullUser, nickname, locale: "ja"});
      onConfirm?.();
      InputUserNameDialog.propsSubject.update({isVisible: false});
      processingRef.current = false;
    }
  }, [user, nickname, onConfirm]);

  return isVisible ? (
    <Dialog zIndex={zIndex} isOpen={isVisible} onClose={handleClose}>
      <DialogCloseButton/>
      <DialogBody css={styles.body}>
        <p css={styles.guide}>
          {"変更するニックネームを入力してください。"}
        </p>
        <form css={styles.form} onSubmit={handleSubmit}>
          <Input
            label={"ニックネーム"}
            type="blackText"
            required={true}
            autoComplete="off"
            value={nickname}
            onChange={(event) => setNickname(event.target.value)}
          />
          <div css={styles.buttonList}>
            <Button css={styles.button} iconNode={<FontAwesomeIcon icon={faCheck}/>} type="submit">{"変更する"}</Button>
          </div>
        </form>
      </DialogBody>
    </Dialog>
  ) : null;
}, {
  isVisible: false,
  user: User.DEFAULT,
  key: ""
});
