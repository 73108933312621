import {css} from "@emotion/react";
import {faXmarkCircle} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCallback} from "react";
import {alpha, color, filterBlur, size} from "src/components";
import {Button} from "src/components/common/button";
import {fontWeight, lineHeight} from "src/components/constants/constants";
import {RandomQuiz} from "src/entities/common/randomQuizTrigger";
import {useTranslation} from "src/modules/translation";

export interface Props {
  quiz: RandomQuiz;
  onPlay: (choice: RandomQuiz["choices"][number]) => unknown;
  onClose: () => unknown;
  isResult?: boolean;
  isCorrect?: boolean;
  correctText: string;
  selectIndex?: number;
  selectOne?: RandomQuiz;
};

const styles = {
  root: css`
    width:${size(80)};
    padding-block: ${size(1)};
    padding-inline: ${size(2)};
    border-radius: ${size(2)};
    font-size: ${size(3)};
    color: ${color("whiteText")};
    background-color: ${alpha(color("black"), 0.8)};
    backdrop-filter: ${filterBlur(1)};
    transform: translate(-50%, -100%);
  `,
  question: css`
    margin-top : ${size(3)};
    margin-left: ${size(1)};
    margin-right: ${size(1)};
    font-size: ${size(4)};
    font-weight: ${fontWeight("bold")};
    ${lineHeight(1.4)}
  `,
  answerMessageTop: css`
    margin-top: ${size(2)};
    margin-bottom: ${size(2)};
  `,
  answerMessage: css`
    text-align: center;
    font-size: ${size(4)};
    font-weight: ${fontWeight("bold")};
  `,
  buttonTop: css`
    display: grid; 
    grid-template-columns: 
    repeat(2, 1fr);
    margin-top: ${size(2)};
    margin-bottom: ${size(2)};
  `,
  selectButton: css`
    width:40%;
    inline-size: 90%;
    pointer-events: all;
    margin-top: ${size(2)};
    margin-left: ${size(2)};
    margin-right: ${size(2)};
  `,
  closeButtonTop: css`
    float: right;
    margin: ${size(-5)};
  `,
  closeButton: css`
    pointer-events: all;
    font-size: ${size(7)};
    background-color: ${color("primary", 5)};
    border-radius: ${size(4)};
    height: ${size(6.98)};
  `
};

/**
 * - **Inner Props**: {@link Props}
 * @group React Components
 * @category Builtin Overlay
 */
export const NpcQuizPane: React.FC<Props> = ({
  quiz,
  onPlay,
  onClose,
  isResult,
  isCorrect,
  correctText
}) => {
  const {trans} = useTranslation("npcQuizPane");

  const handlePlay = useCallback((choice: RandomQuiz["choices"][number]) => {
    onPlay?.(choice);
  }, [onPlay]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <div css={styles.root}>
      <div css={styles.closeButtonTop}>
        <button css={styles.closeButton} type="button" onClick={() => handleClose()}><FontAwesomeIcon icon={faXmarkCircle}/></button>
      </div>
      <p css={styles.question}>
        {`Q.${quiz.question}`}
      </p>

      <div css={styles.buttonTop}>
        {quiz.choices.map((choice, index) => (
          isResult ? (
            <Button key={index} css={styles.selectButton} colorScheme={choice.isCorrect ? "blue" : "red"} disabled={true} variant={choice.text.indexOf(correctText) !== -1 ? "solid" : "outline"}>
              {index <= 25 ? String.fromCodePoint(0x41 + index) : index}.{choice.isCorrect ? "○" : "×"}.{choice.text}
            </Button>
          ) : (
            <Button key={index} css={styles.selectButton} onClick={() => handlePlay(choice)} variant={"outline"}>
              {index <= 25 ? String.fromCodePoint(0x41 + index) : index}.{choice.text}
            </Button>
          )
        ))}
      </div>

      {isResult && (
        <div css={styles.answerMessageTop}>
          <p css={styles.answerMessage}>{isCorrect ? trans("correct") : trans("incorrect")}</p>
        </div>
      )}
    </div>
  );
};