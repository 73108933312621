import {PublicUser} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faAnglesLeft, faAnglesRight, faMinus} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {MouseEvent, useCallback} from "react";
import {Checkbox} from "src/components/common/checkbox";
import {alpha, color, size, smartphone} from "src/components/constants/constants";
import {UserView} from "src/components/modules/userView";
import {useTranslation} from "src/modules/translation";


interface Props {
  optionUser: PublicUser;
  users: Array<PublicUser>;
  position: "option" | "selected";
  onChange?: (users: Array<PublicUser>) => void;
};

const styles = {
  root: css`
    padding-block: 0.5em;
    padding-inline: 0.5em;
    column-gap: 0.5em;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: ${color("primary", 0)};
    }
  `,
  checkbox: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
  main: css`
    flex-grow: 1;
    flex-shrink: 1;
  `,
  tag: css`
    padding-block: 0.2em;
    padding-inline: 0.4em;
    font-size: ${size(3)};
    border-radius: ${size(1)};
    color: ${color("primary", 5)};
    background-color: ${alpha(color("primary", 5), 0.2)};
    transition: opacity 0.2s ease;
    flex-grow: 0;
    flex-shrink: 0;
    opacity: 0;
    .qr-multi-listbox:hover >& {
      opacity: 1;
    }
    ${smartphone()} {
      display: none;
    }
  `,
  tagIcon: css`
    margin-inline-end: 0.4em;
  `
};

export const UserMultiListboxPane: React.FC<Props> = ({
  optionUser,
  users,
  position,
  onChange
}) => {
  const {trans} = useTranslation("userMultiListbox");

  const isSelected = users.some((user) => user.id === optionUser.id);

  const handleChange = useCallback((event: MouseEvent<HTMLElement>) => {
    const willBeAdded = !isSelected;
    if (willBeAdded) {
      onChange?.([...users, optionUser]);
    } else {
      onChange?.(users.filter((user) => user.id !== optionUser.id));
    }
  }, [users, optionUser, isSelected, onChange]);

  return (
    <label css={styles.root} className="qr-multi-listbox" data-selected={isSelected} onMouseDown={handleChange}>
      <div css={styles.checkbox}>
        <Checkbox checked={isSelected} onChange={() => null}/>
      </div>
      <div css={styles.main}>
        <UserView user={optionUser} showAvatar={true} showName={true} showHandle={true}/>
      </div>
      <div css={styles.tag}>
        <FontAwesomeIcon css={styles.tagIcon} icon={isSelected ? (position === "option" ? faMinus : faAnglesLeft) : faAnglesRight}/>
        {isSelected ? trans("minus") : trans("plus")}
      </div>
    </label>
  );
};