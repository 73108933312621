import {ASSETS, EntityConstructor} from "@baton8/qroud-lib-repositories";
import {color} from "@baton8/qroud-lib-resources";
import {DevTool} from "@excaliburjs/dev-tools";
import {Color, DisplayMode, Engine, Input} from "excalibur";
import {CustomLoader} from "src/game/core/loader";
import {LoginScene} from "src/game/scenes/login";
import {MainScene} from "src/game/scenes/main";
import {getUrlParam} from "src/utils/url";


export class CustomEngine extends Engine {
  public constructor(entityConstructors: Array<EntityConstructor>) {
    super({
      displayMode: DisplayMode.FillContainer,
      canvasElementId: "screen",
      backgroundColor: Color.fromHex(color("background")),
      pointerScope: Input.PointerScope.Canvas,
      suppressHiDPIScaling: true,
      configurePerformanceCanvas2DFallback: {
        allow: false
      }
    });
    this.setupScenes(entityConstructors);
    this.setupDevTool();
  }

  private setupScenes(entityConstructors: Array<EntityConstructor>): void {
    this.add("login", new LoginScene());
    this.add("main", new MainScene(entityConstructors));
  }

  private setupDevTool(): void {
    if (getUrlParam("debug")) {
      const devtool = new DevTool(this);
    }
  }

  public async run(): Promise<void> {
    const loader = new CustomLoader(Object.values(ASSETS));
    await this.start(loader);
    this.goToScene("login");
  }
}