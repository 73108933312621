import {css} from "@emotion/react";
import {InputHTMLAttributes, forwardRef} from "react";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";


interface SliderProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "css"> {
  value: number;
  className?: string;
}

// TODO: スタイリング
const styles = {
  body: css`
    margin:  ${size(2)};
    border-radius: ${size(2)};
    border: solid ${borderWidth(1)} ${alpha(color("primary", 5), 0.3)};
    background: rgba(80, 80, 80, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
  `
};

export const Slider = forwardRef<HTMLInputElement, SliderProps>(({
  value,
  className,
  ...inputProps
}, ref) => {
  return (
    <div css={styles.body}>
      <input type="range" value={value} width="100%" min="0" max="100" ref={ref} {...inputProps}/>
    </div>
  );
});