import {APP_IDS, createEncounter} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import dayjs from "dayjs";
import {useCallback, useState} from "react";
import {Button} from "src/components/common/button";
import {alpha, color, filterBlur, fontWeight, lineHeight, size, smartphone} from "src/components/constants/constants";
import {BlackPane} from "src/components/modules/blackPane";
import {GameCover} from "src/components/modules/gameCover";
import {RandomQuiz} from "src/entities/common/randomQuizTrigger";
import {createOverlay} from "src/modules/create";


export interface RandomQuizOverlayProps {
  isVisible: boolean;
  zIndex?: number;
  quiz?: RandomQuiz;
  onClickNext?: () => void;
  onClickQuit?: () => void;
};

const styles = {
  cover: css`
    padding-block: ${size(6)};
    padding-inline: ${size(6)};
    display: flex;
    align-items: flex-start;
    justify-content: center;
    pointer-events: none;
  `,
  pane: css`
  `,
  root: css`
    padding-block: ${size(4)};
    padding-inline: ${size(6)};
    inline-size: 100%;
    row-gap: ${size(3)};
    border-end-start-radius: ${size(2)};
    border-end-end-radius: ${size(2)};
    line-height: 1;
    color: ${color("white")};
    background-color: ${alpha(color("primary", 6), 0.8)};
    backdrop-filter: ${filterBlur(1)};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
  `,
  question: css`
    font-size: ${size(4)};
    font-weight: ${fontWeight("bold")};
    ${lineHeight(1.4)}
  `,
  judge: css`
    row-gap: ${size(2)};
    font-size: ${size(4)};
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
  `,
  multiplier: css`
    margin-inline-end: 1em;
    color: ${color("primary", 2)};
  `,
  choiceList: (length: number) => css`
    width: 100%;
    column-gap: ${size(6)};
    row-gap: ${size(2)};
    display: grid;
    grid-template-columns: repeat(${Math.min(length, 4)}, 1fr);
    ${smartphone()} {
      grid-template-columns: 1fr;
    }
  `,
  choice: css`
    font-size: ${size(4)};
    column-gap: 0.5em;
    display: flex;
    &[data-correctness="true"] {
      color: ${color("blue", 2)};
    }
    &[data-correctness="false"] {
      color: ${color("red", 2)};
    }
    ${smartphone()} {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
    }
  `,
  choiceAlphabet: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
  choiceText: css`
    font-weight: ${fontWeight("bold")};
    ${lineHeight(1.4)}
    &[data-correctness="false"] {
      text-decoration-color: ${alpha(color("red", 2), 0.75)};
      text-decoration-line: line-through;
      text-decoration-style: double;
    }
  `,
  buttonList: css`
    row-gap: ${size(2)};
    font-size: ${size(4)};
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
  `,
  button: css`
    inline-size: 80%;
    pointer-events: all;
  `,
  nextButton: css`
    font-size: ${size(4)};
    margin: 0 12px 0 12px;
  `,
  icon: css`
    margin-inline-end: 0.4em;
  `
};

// TODO: スタイリング
export const RandomQuizOverlay = createOverlay<RandomQuizOverlayProps>(({
  isVisible,
  zIndex = 110,
  quiz,
  onClickNext,
  onClickQuit
}) => {
  const [isCorrect, setCorrect] = useState<boolean | null>(null);

  const handleClickChoice = useCallback((choice: RandomQuiz["choices"][number]) => () => {
    setCorrect(choice.isCorrect);
    if (quiz != null) {
      createEncounter({
        appId: APP_IDS.qroud,
        quizId: quiz.id,
        playableDeckId: quiz.deckId,
        playTime: 0,
        playAt: dayjs().unix().toString()
      }, []);
    }
  }, [quiz]);

  const handleClickNext = useCallback(() => {
    onClickNext?.();
    setCorrect(null);
  }, [onClickNext]);

  const handleClickQuit = useCallback(() => {
    onClickQuit?.();
    setCorrect(null);
  }, [onClickQuit]);

  return quiz && isVisible ? (
    <GameCover css={styles.cover} zIndex={zIndex}>
      <BlackPane css={styles.pane}>
        <p css={styles.question}>
          {quiz.question}
        </p>

        <ol css={styles.choiceList(quiz.choices.length)}>
          {quiz.choices.map((choice, index) => (
            <li key={index} css={styles.choice}>
              <Button css={styles.button} onClick={handleClickChoice(choice)}>
                {index <= 25 ? String.fromCodePoint(0x41 + index) : index}.{choice.text}
              </Button>
            </li>
          ))}
        </ol>
        {isCorrect !== null && (
          <div css={styles.judge}>
            <p css={styles.question}>{isCorrect ? "正解" : "不正解"}</p>
            <Button css={styles.nextButton} onClick={handleClickNext}>
              {"次へ"}
            </Button>
            <Button css={styles.nextButton} onClick={handleClickQuit}>
              {"終了"}
            </Button>
          </div>
        )}
      </BlackPane>
    </GameCover>
  ) : null;
}, {
  isVisible: false
});
