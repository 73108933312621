import {DetailedSession, FieldId, getSessionDirectorRequirement, getSessionEntryRequirement, useApi} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faHourglassEnd, faLockKeyhole, faPlusCircle, faUser} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import {useCallback, useState} from "react";
import {SingleLineText} from "src/components/common/singleLineText";
import {alpha, borderWidth, boxShadow, color, fontWeight, size} from "src/components/constants/constants";
import {UserView} from "src/components/modules/userView";
import {useLocale, useTranslation} from "src/modules/translation";
import {SessionButtonRealmPane} from "./sessionButtonRealmPane";


interface Props {
  session: DetailedSession;
  onClick?: (session: DetailedSession, fieldId?: FieldId) => unknown;
};

const styles = {
  root: css`
    border-radius: ${size(2)};
    background-color: ${color("white")};
    box-shadow: ${boxShadow(alpha(color("primary", 5), 0.2), 0.5)};
    border: solid ${borderWidth(1)} ${alpha(color("primary", 5), 0.3)};
    transition: background-color 0.2s ease;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &[data-hover="true"] {
      background-color: ${color("primary", 0)};
    }
  `,
  top: css`
    padding-block-start: ${size(3)};
    padding-inline: ${size(3)};
  `,
  bottom: css`
    padding-block-start: ${size(2)};
    padding-block-end: ${size(3)};
    display: flex;
    flex-direction: column;
  `,
  name: css`
    font-size: ${size(5)};
    font-weight: ${fontWeight("bold")};
  `,
  footnoteList: css`
    margin-block-start: ${size(2)};
    row-gap: ${size(1)};
    font-size: ${size(3)};
    opacity: 0.6;
    display: flex;
    flex-direction: column;
  `,
  footnoteLine: css`
    column-gap: ${size(2)};
    display: flex;
    align-items: center;
  `,
  footnoteItem: css`
    display: flex;
    align-items: center;
  `,
  iconWrapper: css`
    width: 0.9em;
    margin-inline-end: 0.4em;
    display: inline-flex;
    justify-content: center;
  `,
  icon: css`
  `,
  total: css`
    margin-block-start: ${size(1)};
    margin-inline-end: ${size(2)};
    padding-inline: ${size(1)};
    column-gap: ${size(3)};
    opacity: 0.6;
    display: flex;
    align-self: flex-end;
    align-items: baseline;
  `,
  totalLabel: css`
    font-size: ${size(3)};
  `,
  totalIcon: css`
    margin-inline-end: 0.4em;
  `,
  totalCount: css`
  `
};

export const SessionButton: React.FC<Props> = ({
  session,
  onClick
}) => {
  const locale = useLocale();
  const {trans, transNumber, transTime} = useTranslation("selectSessionDrawer");

  const [entryRequirement] = useApi(getSessionEntryRequirement, [session.id]);
  const [directorRequirement] = useApi(getSessionDirectorRequirement, [session.id]);

  const [hover, setHover] = useState(false);
  const setHoverTrue = useCallback(() => setHover(true), []);
  const setHoverFalse = useCallback(() => setHover(false), []);

  const handleClick = useCallback(async () => {
    onClick?.(session);
  }, [session, onClick]);

  return (
    <div css={styles.root} tabIndex={0} onMouseEnter={setHoverTrue} onMouseLeave={setHoverFalse} onClick={handleClick} data-hover={hover}>
      <div css={styles.top}>
        <SingleLineText css={styles.name}>
          {session.id !== "public" ? session.name : trans("officialSessionName")}
        </SingleLineText>
        <div css={styles.footnoteList}>
          <div css={styles.footnoteLine}>
            <div css={styles.footnoteItem}>
              <div css={styles.iconWrapper}><FontAwesomeIcon css={styles.icon} icon={faPlusCircle}/></div>
              {session.id !== "public" ? (
                <UserView user={session.creator.user} showAvatar={false}/>
              ) : (
                <>—</>
              )}
            </div>
            {(entryRequirement != null && entryRequirement.password) && (
              <div css={styles.footnoteItem}>
                <div css={styles.iconWrapper}><FontAwesomeIcon css={styles.icon} icon={faLockKeyhole}/></div>
                <span>{trans("locked")}</span>
              </div>
            )}
          </div>
          <div css={styles.footnoteLine}>
            <div css={styles.footnoteItem}>
              <div css={styles.iconWrapper}><FontAwesomeIcon css={styles.icon} icon={faHourglassEnd}/></div>
              {session.id !== "public" ? (
                <span>
                  <span>{trans("expireRemaining", {timeString: dayjs(session.expireAt).locale(locale).fromNow(true)})}</span>
                </span>
              ) : (
                <>—</>
              )}
            </div>
          </div>
        </div>
      </div>
      <div css={styles.bottom}>
        <SessionButtonRealmPane index={0} session={session} onClick={onClick} onMouseEnter={setHoverFalse} onMouseLeave={setHoverTrue}/>
        <SessionButtonRealmPane index={1} session={session} onClick={onClick} onMouseEnter={setHoverFalse} onMouseLeave={setHoverTrue}/>
        <div css={styles.total}>
          <div css={styles.totalLabel}>
            <FontAwesomeIcon css={styles.totalIcon} icon={faUser}/>
            <span>{trans("total")}</span>
          </div>
          <div css={styles.totalCount}>
            {transNumber(session.playerCount)}
          </div>
        </div>
      </div>
    </div>
  );
};
