import {Actor, BoundingBox, CollisionStartEvent, CollisionType} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";


/**
 * プレイヤーがこのエンティティに重なると、カメラの可動範囲をこのエンティティの中に制限します。
 * マップ内にこれを複数個配置することで、1 つのマップにも関わらず複数個のマップを行き来しているかのように見せることができます。
 * @category Builtin Entity
 * @deprecated
 */
export class CameraBoundary extends withFieldEntity(Actor) {

  public constructor(configs: FieldEntityConfigs<{}>) {
    super(configs);
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    this.limitCameraBoundary(event);
  }

  private limitCameraBoundary(event: CollisionStartEvent): void {
    if (event.other === this.player) {
      const left = this.pos.x - this.width / 2;
      const top = this.pos.y - this.height / 2;
      const boundingBox = new BoundingBox(left, top, left + this.width, top + this.height);
      this.scene.camera.strategy.lockToActor(event.other);
      this.scene.camera.strategy.limitCameraBounds(boundingBox);
    }
  }
}