import {css} from "@emotion/react";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormEvent, useCallback, useState} from "react";
import {Button} from "src/components/common/button";
import {Input} from "src/components/common/input";
import {size} from "src/components/constants/constants";
import {Dialog, DialogBody, DialogCloseButton} from "src/components/modules/dialog";
import {createOverlay} from "src/modules/create";
import {useTranslation} from "src/modules/translation";


export interface InputPasswordDialogProps {
  /**
   * このオーバーレイを表示するかどうか。
   */
  isVisible: boolean;
  /**
   * このオーバーレイの Z index。
   * @defaultValue `100`
   */
  zIndex?: number;
  /**
   * 正しいパスワード。
   */
  correctPassword: string;
  /**
   * 閉じるボタンを押したときに実行されるコールバック関数。
   */
  onCancel?: () => unknown;
  /**
   * パスワードが正しく入力されたときに実行されるコールバック関数。
   */
  onConfirm?: () => unknown;
};

const styles = {
  body: css`
    row-gap: ${size(4)};
    font-size: ${size(4)};
    display: flex;
    flex-direction: column;
  `,
  guide: css`
    text-align: center;
  `,
  form: css`
    row-gap: ${size(4)};
    display: flex;
    flex-direction: column;
  `,
  buttonList: css`
    display: flex;
    justify-content: center;
  `,
  button: css`
    width: ${size(36)};
  `
};

/**
 * - **Inner Props**: {@link InputPasswordDialogProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const InputPasswordDialog = createOverlay<InputPasswordDialogProps>(({
  isVisible,
  zIndex = 100,
  correctPassword,
  onCancel,
  onConfirm
}) => {
  const {trans} = useTranslation("inputPasswordDialog");

  const [password, setPassword] = useState("");

  const handleClose = useCallback(() => {
    onCancel?.();
    setPassword("");
    InputPasswordDialog.propsSubject.update({isVisible: false});
  }, [onCancel]);

  const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password === correctPassword) {
      onConfirm?.();
      setPassword("");
      InputPasswordDialog.propsSubject.update({isVisible: false});
    }
  }, [password, correctPassword, onConfirm]);

  return isVisible ? (
    <Dialog zIndex={zIndex} isOpen={isVisible} onClose={handleClose}>
      <DialogCloseButton/>
      <DialogBody css={styles.body}>
        <p css={styles.guide}>
          {trans("guide")}
        </p>
        <form css={styles.form} onSubmit={handleSubmit}>
          <Input
            label={trans("password")}
            type="password"
            required={true}
            autoComplete="off"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <div css={styles.buttonList}>
            <Button css={styles.button} iconNode={<FontAwesomeIcon icon={faCheck}/>} type="submit">{trans("submit")}</Button>
          </div>
        </form>
      </DialogBody>
    </Dialog>
  ) : null;
}, {
  isVisible: false,
  correctPassword: ""
});
