import {socket} from "src/clients/socket.js";
import {global} from "src/globals";
import {Chat, ChatType} from "src/types";
import {createAsyncApi} from "src/utils/api";


/**
 * @group API Wrappers
 * @category Chat
 */
export const sendChat = createAsyncApi((type: ChatType, message: string): Promise<void> => {
  const user = global.user;
  return new Promise((resolve, reject) => {
    if (user != null) {
      socket.emit("sendChat", user.id, type, message);
      resolve();
    } else {
      throw new Error("Not signed in");
    }
  });
}, "sendChat");

/**
 * @param listener リスナー
 * @group API Wrappers
 * @category Chat
 */
export const listenChatSent = (listener: (chat: Chat) => unknown): void => {
  socket.on("chatSent", listener);
};

/**
 * @param listener リスナー
 * @group API Wrappers
 * @category Chat
 */
export const unlistenChatSent = (listener: (chat: Chat) => unknown): void => {
  socket.off("chatSent", listener);
};