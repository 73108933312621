import {Actor, CollisionStartEvent, CollisionType} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";


export interface MoveTriggerProperties {
  /**
   * 移動先のマップ ID。
   */
  fieldId?: string;
  /**
   * これを指定すると、指定したオブジェクトの場所にプレイヤーを移動させます。
   */
  objectId?: number;
  /**
   * 移動先の X 座標。
   */
  x?: number;
  /**
   * 移動先の Y 座標。
   */
  y?: number;
};

/**
 * プレイヤーがこのエンティティに触れたときに、プレイヤーを別の場所に移動させます。
 * 移動先のマップは `fieldId` で指定します。
 * - `fieldId` が指定されている — ID が `fieldId` のマップに移動
 * - `fieldId` が指定されていない — 同じマップ内で移動
 *
 * さらに `objectId`, `x`, `y` を指定することで、移動先の座標も指定できます。
 * - `objectId` が指定されている — そのオブジェクトがある位置に移動
 * - `objectId` が指定されていない — 座標 (`x`, `y`) に移動
 *
 * `objectId` が指定されておらず、さらに `x`, `y` の少なくとも一方が指定されていなかったときは、該当マップのスポーンポイントに移動します。
 * @category Builtin Entity
 */
export class MoveTrigger extends withFieldEntity(Actor) {
  private readonly properties: MoveTriggerProperties;

  public constructor(configs: FieldEntityConfigs<MoveTriggerProperties>) {
    super(configs);
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    this.move(event);
  }

  private move(event: CollisionStartEvent): void {
    if (event.other === this.player) {
      const {fieldId, objectId, x, y} = this.properties;
      if (objectId != null) {
        this.scene.moveToObject(fieldId ?? null, objectId);
      } else {
        this.scene.moveTo(fieldId ?? null, x, y);
      }
    }
  }
}