import {css} from "@emotion/react";
import {ReactNode} from "react";
import {size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


interface DialogHeaderProps extends CustomDataAttributes {
  className?: string;
  children?: ReactNode;
};

const styles = {
  root: css`
    margin-block-end: ${size(8)};
    column-gap: ${size(10)};
    font-size: ${size(4)};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0; 
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DialogHeader: React.FC<DialogHeaderProps> = ({
  className,
  children,
  ...data
}) => {
  return (
    <div css={styles.root} className={className} {...data}>
      {children}
    </div>
  );
};
