import {css} from "@emotion/react";
import {faCircleNotch} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {color, size} from "src/components/constants/constants";
import {GameCover} from "src/components/modules/gameCover";
import {createOverlay} from "src/modules/create";


export interface LoadingCoverProps {
  isVisible: boolean;
  zIndex?: number;
}

const styles = {
  panel: css`
    inline-size: 100%;
    block-size: 100%;
    background-color: ${color("white")};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  icon: css`
    font-size: ${size(16)};
    color: ${color("primary", 5)};
  `
};

/**
 * マップのローディング中に表示するオーバーレイです。
 * マップの代わりの表示という意図があるため、各種 UI より下二表示されるように、デフォルトの Z index は 90 に指定されています。
 *
 * - **Inner Props**: {@link LoadingCoverProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const LoadingCover = createOverlay<LoadingCoverProps>(({
  isVisible,
  zIndex = 90
}) => {
  return isVisible ? (
    <GameCover zIndex={zIndex}>
      <div css={styles.panel}>
        <FontAwesomeIcon css={styles.icon} icon={faCircleNotch} spin={true}/>
      </div>
    </GameCover>
  ) : null;
}, {
  isVisible: false
});
