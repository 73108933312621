export const COLORS = {
  primary: {
    9: "#1B495B",
    8: "#1F6074",
    7: "#23798F",
    6: "#2595AA",
    5: "#26B4C6",
    4: "#48C6D6",
    3: "#6CD5E3",
    2: "#92E3EE",
    1: "#BBF0F6",
    0: "#E6FAFD"
  },
  pink: {
    9: "#723241",
    8: "#903C53",
    7: "#B04567",
    6: "#D14D7D",
    5: "#F35494",
    4: "#FA6EA6",
    3: "#FF89B9",
    2: "#FFA8CC",
    1: "#FFC8E0",
    0: "#FFEBF4"
  },
  blue: {
    9: "#2F3371",
    8: "#394290",
    7: "#4151B0",
    6: "#4862D1",
    5: "#4E74F3",
    4: "#688AFB",
    3: "#85A2FF",
    2: "#A5BAFF",
    1: "#C6D4FF",
    0: "#EAEFFF"
  },
  red: {
    9: "#713B2D",
    8: "#904536",
    7: "#B04B3E",
    6: "#D14F44",
    5: "#F3504A",
    4: "#FB6A65",
    3: "#FF8783",
    2: "#FFA6A2",
    1: "#FFC7C5",
    0: "#FFEAEA"
  },

  white: "#FFFFFF",
  black: "#112222",
  whiteText: "#DDDDDD",
  blackText: "#444444",

  background: "#F6F6F6"
};