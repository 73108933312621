import {css} from "@emotion/react";
import {faXmarkCircle} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCallback} from "react";
import {alpha, color, filterBlur, size} from "src/components";
import {Button} from "src/components/common/button";
import {fontWeight, lineHeight} from "src/components/constants/constants";
import {useTranslation} from "src/modules/translation";


export interface Props {
  message: string;
  onPlay?: () => unknown;
  onClose?: () => unknown;
};

const styles = {
  root: css`
    width:${size(80)};
    padding-block: ${size(1)};
    padding-inline: ${size(2)};
    border-radius: ${size(2)};
    font-size: ${size(3)};
    color: ${color("whiteText")};
    background-color: ${alpha(color("black"), 0.8)};
    backdrop-filter: ${filterBlur(1)};
    transform: translate(-50%, -100%);
  `,
  question: css`
    margin-top : ${size(3)};
    margin-left: ${size(1)};
    margin-right: ${size(1)};
    font-size: ${size(4)};
    font-weight: ${fontWeight("bold")};
    ${lineHeight(1.4)}
  `,
  buttonTop: css`
    display: flex;
    margin-top: ${size(2)};
    margin-bottom: ${size(1)};
  `,
  closeButtonTop: css`
    float: right;
    margin: ${size(-5)};
  `,
  closeButton: css`
    pointer-events: all;
    font-size: ${size(7)};
    background-color: ${color("primary", 5)};
    border-radius: ${size(4)};
    height: ${size(6.98)};
  `,
  selectButton: css`
    width:48%;
    inline-size: 100%;
    pointer-events: all;
    margin: ${size(1)};
    display: table-cell;
  `
};

/**
 * - **Inner Props**: {@link Props}
 * @group React Components
 * @category Builtin Overlay
 */
export const YesNoPane: React.FC<Props> = ({
  message,
  onPlay,
  onClose
}) => {
  const {trans} = useTranslation("yesNoPane");

  const handlePlay = useCallback(() => {
    onPlay?.();
  }, [onPlay]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <div css={styles.root}>
      <div css={styles.closeButtonTop}>
        <button css={styles.closeButton} type="button" onClick={handleClose}><FontAwesomeIcon icon={faXmarkCircle}/></button>
      </div>
      <p css={styles.question}>
        {trans(message)}
      </p>

      <div css={styles.buttonTop}>
        <Button css={styles.selectButton} onClick={handlePlay}>
          {trans("yes")}
        </Button>
        <Button css={styles.selectButton} onClick={handleClose}>
          {trans("no")}
        </Button>
      </div>
    </div>
  );
};