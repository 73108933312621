import {css} from "@emotion/react";
import {ReactNode} from "react";
import {CustomDataAttributes} from "src/modules/data";


export interface SingleLineTextProps extends CustomDataAttributes {
  /**
   * 使用する HTML タグの名前。
   * @defaultValue `"span"`
   */
  tagName?: keyof JSX.IntrinsicElements;
  /** */
  className?: string;
  /** */
  children?: ReactNode;
};

const styles = {
  root: css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const SingleLineText: React.FC<SingleLineTextProps> = ({
  tagName = "span",
  children,
  className,
  ...data
}) => {
  const TagName = tagName;

  return (
    <TagName css={styles.root} className={className} {...data}>
      {children}
    </TagName>
  );
};
