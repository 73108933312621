import {css} from "@emotion/react";
import {ReactNode} from "react";
import {size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


export interface GameCoverProps extends CustomDataAttributes {
  /**
   * ルート要素の Z index。
   */
  zIndex?: number;
  /** */
  className?: string;
  /** */
  children?: ReactNode;
};

const styles = {
  spacer: css`
    inset: ${size(0)};
    position: absolute;
  `
};

/**
 * ゲーム画面 (サイドバーやヘッダーを除いた部分) と同じ大きさかつ同じ位置に表示されるコンポーネントです。
 * ゲーム画面に重ねて UI などを表示したい場合に利用できます。
 * オーバーレイの最も外側の要素として配置してください。
 *
 * スタイリングは行っていないので、適宜 Emotion の `css` プロパティなどを利用してください。
 * @group React Components
 * @category Common Component
 */
export const GameCover: React.FC<GameCoverProps> = ({
  zIndex,
  className,
  children,
  ...props
}) => {
  return (
    <div css={styles.spacer} className={className} style={zIndex ? {zIndex} : undefined} {...props}>
      {children}
    </div>
  );
};
