import {Actor} from "excalibur";
import {PlayerTriggerComponent} from "./component";


type ActorForPlayerTrigger = Pick<Actor, "get" | "addComponent">;

/**
 * `PlayerTriggerComponent` を追加する mix-in です。
 * プレイヤーがエンティティに触れたときやエンティティの方を向いているときに、イベントが発生するようになります。
 * 詳細は `{@link StoriesComponent}` を参照してください。
 * @category Excalibur ECS
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withPlayerTrigger<B extends new(...args: Array<any>) => ActorForPlayerTrigger>(base: B) {
  return class extends base {
    public constructor(...args: Array<any>) {
      super(...args);
      this.addComponent(new PlayerTriggerComponent());
    }

    /**
     * `this.get(PlayerTriggerComponent)` のショートハンドです。
     */
    public get playerTrigger(): PlayerTriggerComponent {
      return this.get(PlayerTriggerComponent)!;
    }
  };
};