import {css} from "@emotion/react";
import {faTimes} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useContext} from "react";
import {color, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";
import {useTranslation} from "src/modules/translation";
import {context} from "./context";


interface DialogCloseButtonProps extends CustomDataAttributes {
  className?: string;
};

const styles = {
  button: css`
    inset-block-start: ${size(4)};
    inset-inline-end: ${size(4)};
    font-size: ${size(6)};
    color: ${color("primary", 5)};
    transition: color 0.2s ease;
    cursor: pointer;
    position: absolute;
    &:hover {
      color: ${color("primary", 4)};
    }
  `,
  icon: css`
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DialogCloseButton: React.FC<DialogCloseButtonProps> = ({
  className,
  ...data
}) => {
  const {trans} = useTranslation("drawer");

  const {onClose} = useContext(context);

  return (
    <button css={styles.button} className={className} type="button" onClick={onClose} aria-label={trans("close")} {...data}>
      <FontAwesomeIcon css={styles.icon} icon={faTimes}/>
    </button>
  );
};
