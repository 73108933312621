import {css} from "@emotion/react";
import {ReactNode, forwardRef} from "react";
import {lineHeight, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


export interface HelperTextProps extends CustomDataAttributes {
  children?: ReactNode;
  className?: string;
}

const styles = {
  root: css`
    margin-block-start: ${size(1)};
    font-size: ${3 / 4}em;
    opacity: 0.6;
    ${lineHeight(1.4)}
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const HelperText = forwardRef<HTMLParagraphElement, HelperTextProps>(({
  children,
  className,
  ...data
}, ref) => {
  return (
    <p css={styles.root} className={className} ref={ref} {...data}>
      {children}
    </p>
  );
});