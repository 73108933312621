import {css} from "@emotion/react";
import {IconDefinition} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ExtendedKeyboardEvent} from "mousetrap";
import {ButtonHTMLAttributes, MouseEvent, useContext} from "react";
import {CircleIconButton} from "src/components/common/circleIconButton";
import {smartphone} from "src/components/constants/constants";
import {context} from "src/components/modules/drawer/context";


export interface DrawerAddonButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children" | "type" | "onClick"> {
  icon: IconDefinition;
  highlight?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement> | ExtendedKeyboardEvent) => void;
  className?: string;
}

const styles = {
  button: css`
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    &[data-open="true"] {
      opacity: 1;
      pointer-events: all;
    }
  `,
  iconDesktop: css`
    ${smartphone()} {
      display: none;
    }
  `,
  iconSmartphone: css`
    display: none;
    ${smartphone()} {
      display: unset;
    }
  `
};

/**
 * - **Inner Props**: {@link Props}
 * @group React Components
 * @category Builtin Overlay
 */
export const DrawerAddonButton: React.FC<DrawerAddonButtonProps> = (({
  className,
  icon,
  highlight,
  onClick,
  ...data
}) => {
  const {isOpen, onClose} = useContext(context);

  return (
    <CircleIconButton
      css={styles.button}
      className={className}
      variant={highlight ? "solid" : "outline"}
      onClick={onClick}
      aria-label={"changePage"}
      data-open={isOpen}
      iconNode={[
        <FontAwesomeIcon key="desktop" css={styles.iconDesktop} icon={icon}/>,
        <FontAwesomeIcon key="smartphone" css={styles.iconSmartphone} icon={icon}/>
      ]}
      {...data}
    />
  );
});