import {css} from "@emotion/react";
import {ReactNode, forwardRef} from "react";
import {size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


export interface LabelProps extends CustomDataAttributes {
  className?: string;
  children?: ReactNode;
}

const styles = {
  root: css`
    margin-block-end: ${size(1)};
    font-size: ${3 / 4}em;
    opacity: 0.6;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const Label = forwardRef<HTMLDivElement, LabelProps>(({
  className,
  children,
  ...data
}, ref) => {
  return (
    <div css={styles.root} className={className} ref={ref} {...data}>
      {children}
    </div>
  );
});