import {css} from "@emotion/react";
import {InputHTMLAttributes, ReactNode, forwardRef} from "react";
import {alpha, borderWidth, color, lineHeight} from "src/components/constants/constants";


export interface RadioProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "css"> {
  /**
   * ラジオボタンの上に表示するラベル。
   */
  label?: ReactNode;
  /** */
  className?: string;
}

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    &[data-disabled="true"] {
      opacity: 0.5;
    }
  `,
  container: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  `,
  label: css`
    margin-inline-start: 0.5em;
    ${lineHeight(1.4)}
  `,
  original: css`
    width: 0rem;
    height: 0rem;
    opacity: 0;
  `,
  input: css`
    width: 1.1em;
    height: 1.1em;
    border-radius: 100em;
    line-height: 1;
    border: solid ${borderWidth(1)} ${alpha(color("black"), 0.3)};
    background-color: ${color("white")};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    input:focus +& {
      border-color: ${color("primary", 5)};
      outline: solid ${borderWidth(1)} ${color("primary", 5)};
    }
    input:disabled +& {
      cursor: inherit;
    }
  `,
  icon: css`
    width: 0.7em;
    height: 0.7em;
    border-radius: 100em;
    background-color: ${color("primary", 5)};
    display: none;
    transition: background-color 0.2s ease;
    .qr-radio:not([data-disabled="true"]):hover & {
      background-color: ${color("primary", 4)};
    }
    input:checked +* >& {
      display: block;
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const Radio = forwardRef<HTMLInputElement, RadioProps>(({
  label,
  className,
  ...inputProps
}, ref) => {
  return (
    <label className={["qr-radio", className].join(" ")} css={styles.root} data-disabled={inputProps.disabled}>
      <div css={styles.container}>
        <input type="radio" css={styles.original} ref={ref} {...inputProps}/>
        <div css={styles.input}>
          <div css={styles.icon}/>
        </div>
        {!!label && (
          <div css={styles.label}>{label}</div>
        )}
      </div>
    </label>
  );
});