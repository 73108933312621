import {css} from "@emotion/react";
import {ReactNode} from "react";
import {alpha, color, fontWeight, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


interface DialogTitleProps extends CustomDataAttributes {
  className?: string;
  children?: ReactNode;
};

const styles = {
  root: css`
    padding-inline: 0.5em;
    font-size: ${size(6)};
    font-weight: ${fontWeight("bold")};
    color: ${color("primary", 6)};
    letter-spacing: 0.1em;
    background-image: linear-gradient(transparent 60%, ${alpha(color("primary", 5), 0.2)} 60%);
    flex-grow: 0;
    flex-shrink: 0;
  `,
  inner: css`
    display: inline-block;
    transform: skew(-3deg, 0deg);
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DialogTitle: React.FC<DialogTitleProps> = ({
  className,
  children,
  ...data
}) => {
  return (
    <h2 css={styles.root} className={className} {...data}>
      <span css={styles.inner}>
        {children}
      </span>
    </h2>
  );
};
