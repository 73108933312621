import {css} from "@emotion/react";
import {ExtendedKeyboardEvent} from "mousetrap";
import {ButtonHTMLAttributes, ReactNode, forwardRef} from "react";
import {MouseEvent} from "react";
import {alpha, borderWidth, boxShadow, color, lineHeight} from "src/components/constants/constants";


export interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "css" | "type" | "onClick"> {
  /**
   * ボタンのカラースキーム。
   * @defaultValue `"primary"`
   */
  colorScheme?: "primary";
  /**
   * ボタンのスタイル。
   * - `"solid"` — ボタンの背景がカラースキームの色で塗りつぶされる
   * - `"outline"` — ボタンの背景が白
   * @defaultValue `"solid"`
   */
  variant?: "solid" | "outline";
  /**
   * テキストの左の表示するアイコン。
   */
  iconNode: ReactNode;
  /**
   * ボタンの種類。
   * HTML と違ってデフォルト値が `"button"` になっているので、フォームの送信ボタンとして使う場合は明示的に `"submit"` を指定してください。
   * @defaultValue `"button"`
   */
  type?: "submit" | "reset" | "button";
  /** */
  onClick?: (event: MouseEvent<HTMLButtonElement> | ExtendedKeyboardEvent) => void;
  /** */
  className?: string;
}

const styles = {
  root: (colorScheme: NonNullable<Props["colorScheme"]>) => css`
    padding-block: 0.5em;
    padding-inline: 1em;
    box-shadow: ${boxShadow(alpha(color(colorScheme, 5), 0.2), 0.5)};
    border-radius: 100em;
    line-height: 1;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:focus-visible {
      outline: solid ${borderWidth(2)} ${alpha(color(colorScheme, 5), 0.6)};
      outline-offset: ${borderWidth(1)};
    }
    &:disabled {
      box-shadow: ${boxShadow(alpha(color(colorScheme, 5), 0), 0.5)};
      opacity: 0.5;
      cursor: inherit;
    }
    &[data-variant="solid"] {
      color: ${color("white")};
      background-color: ${color(colorScheme, 5)};
      &:hover:not(:disabled) {
        background-color: ${color(colorScheme, 4)};
      }
    }
    &[data-variant="outline"] {
      color: ${color(colorScheme, 5)};
      background-color: ${color("white")};
      &:hover:not(:disabled) {
        background-color: ${color(colorScheme, 0)};
      }
    }
  `,
  children: css`
    flex-grow: 0;
    flex-shrink: 0;
    white-space: pre-wrap;
    ${lineHeight(1.4)}
  `,
  icon: css`
    margin-inline-end: 0.5em;
    font-size: 1.5em;
    display: inline;
    flex-grow: 0;
    flex-shrink: 0;
  `
};

/**
 * フォーム要素としてのボタンです。
 * @group React Components
 * @category Common Component
 */
export const MenuButton = forwardRef<HTMLButtonElement, Props>(({
  colorScheme = "primary",
  variant = "solid",
  iconNode,
  type = "button",
  onClick,
  className,
  children,
  ...props
}, ref) => {
  return (
    <button
      className={className}
      css={styles.root(colorScheme)}
      type={type}
      onClick={onClick}
      ref={ref}
      data-variant={variant}
      {...props}
    >
      <div css={styles.icon}>
        {iconNode}
      </div>
      <div css={styles.children}>
        {children}
      </div>
    </button>
  );
});