import {Actor} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {sound} from "src/sound";


interface Properties {
  soundFile: string;
};

export class SoundPlayer extends withFieldEntity(Actor) {
  public constructor(configs: FieldEntityConfigs<Properties>) {
    super(configs);
    sound.playBgm(configs.properties.soundFile);
  }
}