import {Field, FieldId} from "@baton8/qroud-lib-repositories";
import {Component, Entity, System, SystemType} from "excalibur";
import {GhostGroup} from "src/entities/types/ghostGroup";
import {Player} from "src/entities/types/player";


const COMPONENT_TYPE = "qroud.fieldEntity" as const;
const SYSTEM_TYPES = ["qroud.fieldEntity"] as const;

export type FieldEntityProperties = {[K in string]?: any};

export type FieldEntityConfigs<P extends FieldEntityProperties> = {
  objectId: number,
  x: number,
  y: number,
  width: number,
  height: number,
  name: string,
  properties: P & {preserve?: boolean},
  player: Player,
  ghostGroup: GhostGroup,
  field: Field
};

/**
 * @category Excalibur ECS
 */
export class FieldEntityComponent extends Component<typeof COMPONENT_TYPE> {
  public readonly type = COMPONENT_TYPE;

  /**
   * このエンティティが存在しているフィールドです。
   * エンティティはマップ移動しても維持されることがあるので、ゲーム中にこの値は変わる可能性があります。
   */
  public field: Field;

  /**
   * プレイヤーエンティティへの参照です。
   */
  public readonly player: Player;

  /**
   * ゴーストを管理するエンティティへの参照です。
   */
  public readonly ghostGroup: GhostGroup;

  /**
   * Tiled 上のオブジェクト ID です。
   */
  public readonly objectId: number;

  /**
   * このエンティティが最初に生成されたフィールドの ID です。
   * マップ間で維持されるエンティティの場合、この値は `this.field.id` と異なる場合があります。
   */
  public readonly originalFieldId: FieldId;

  public constructor(configs?: FieldEntityConfigs<{[K in string]?: any}>) {
    super();
    if (configs == null) {
      throw new Error("FieldEntityComponent does not support automatic addition. Please add it manually.");
    }
    // TODO: ここで `this.getComponent(TransformComponent)` して `x` とか `y` とか設定すれば良くない?
    // いやコンストラクタより後に呼ばれるからダメか… (柔軟性を考えて)
    this.field = configs.field;
    this.player = configs.player;
    this.ghostGroup = configs.ghostGroup;
    this.objectId = configs.objectId;
    this.originalFieldId = configs.field.id;
  }

}

/**
 * @category Excalibur ECS
 */
export class FieldEntitySystem extends System<FieldEntityComponent> {
  public readonly types = SYSTEM_TYPES;
  public readonly systemType = SystemType.Update;

  public override update(entities: Array<Entity>, delta: number): void {
  }
}