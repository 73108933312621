export const getUrlParam = (key: string): string | null => {
  const url = new URL(document.location.href);
  const params = url.searchParams;
  const email = params.get(key);
  return email;
};

export const setUrlParam = (key: string, value: string | undefined | null): void => {
  const url = new URL(document.location.href);
  const params = url.searchParams;
  if (value != null) {
    params.set(key, value);
  } else {
    params.delete(key);
  }
  url.search = params.toString();
  window.history.replaceState({}, "", url.toString());
};

export const deleteUrlParam = (key: string): void => {
  const url = new URL(document.location.href);
  const params = url.searchParams;
  params.delete(key);
  url.search = params.toString();
  window.history.replaceState({}, "", url.toString());
};