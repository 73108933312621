import {css} from "@emotion/react";
import {ReactElement, ReactNode, useContext} from "react";
import {Scroll} from "src/components/common/scroll";
import {context} from "./context";


export interface ScrollListMainProps<T> {
  className?: string;
  children: ((item: T, index: number) => ReactNode) | Array<((item: T, index: number) => ReactNode) | ReactNode>;
};

export type ScrollListMainComponent = <T extends {} = any>(props: ScrollListMainProps<T>) => ReactElement | null;

const styles = {
  scroll: css`
    flex-grow: 1;
  `,
  root: css`
    flex-grow: 1;
    position: relative;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const ScrollListMain: ScrollListMainComponent = ({
  className,
  children
}) => {
  const {insertMargin, isLoading, isEmpty, displayedItems, mainRef} = useContext(context);

  const normedChildren = typeof children === "function" ? [children] : children;

  return !isLoading && !isEmpty ? (
    <Scroll css={styles.scroll} insertMargin={insertMargin}>
      <div className={className} css={styles.root} ref={mainRef}>
        {normedChildren.map((child) => typeof child === "function" ? displayedItems.map(child) : child)}
      </div>
    </Scroll>
  ) : null;
};