import {Competition} from "@baton8/qroud-lib-repositories";
import {Actor, CollisionType, Engine, Input} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {HoldCompetitionDrawer} from "src/overlays";


interface Properties {
};

/**
 * プレイヤーがこのエンティティに対してアクションを起こしたときに、イベント開催ダイアログを表示します。
 * @category Builtin Entity
 */
export class HoldCompetitionTrigger extends withFieldEntity(Actor) {
  private isActivated: boolean;
  private onConfirm?: (competition: Competition) => unknown;

  private isButtonPressed: boolean;
  private pointerDownHandler: any;

  public constructor(configs: FieldEntityConfigs<Properties>) {
    super(configs);
    this.body.collisionType = CollisionType.Passive;
    this.isActivated = false;
    this.isButtonPressed = false;
  }

  public override onInitialize(engine: Engine): void {
    this.setupMouseHandlers(engine);
  }

  public override onPreUpdate(engine: Engine, delta: number): void {
    this.checkCollision();
  }

  public override onPreKill(): void {
    this.cleanupMouseHandlers();
  }

  public activate(onConfirm: (competition: Competition) => unknown): void {
    this.isActivated = true;
    this.onConfirm = onConfirm;
  }

  public deactivate(): void {
    this.isActivated = false;
    this.onConfirm = undefined;
  }

  private setupMouseHandlers(engine: Engine): void {
    const pointerDownHandler = (event: Input.PointerEvent): void => {
      const collider = this.collider.get();
      if (collider.contains(event.worldPos)) {
        this.isButtonPressed = true;
      }
    };
    engine.input.pointers.primary.on("down", pointerDownHandler);
    this.pointerDownHandler = pointerDownHandler;
  }

  private cleanupMouseHandlers(): void {
    this.engine.input.pointers.primary.off("down", this.pointerDownHandler);
  }

  private checkCollision(): void {
    if (this.isActivated) {
      if (this.engine.input.keyboard.wasPressed(Input.Keys.Space) || this.isButtonPressed) {
        if (this.collider.collide(this.player.collider).length > 0) {
          HoldCompetitionDrawer.propsSubject.next({
            isOpen: true,
            onConfirm: (competition) => this.onConfirm?.(competition)
          });
        }
      }
    }
    this.isButtonPressed = false;
  }
}
