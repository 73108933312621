import {writeLog} from "@baton8/qroud-lib-repositories";
import {Actor, CollisionStartEvent, CollisionType, Color, Engine, Font, Text, vec} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {PazzleObject} from "./pazzleObject";

export interface PazzleControllerProperties {

    saveId: string;

    answer: string;

    /**
     * 移動先のマップ ID。
     */
    fieldId?: string;
    /**
     * これを指定すると、指定したオブジェクトの場所にプレイヤーを移動させます。
     */
    objectId?: number;
    /**
     * 移動先の X 座標。
     */
    x?: number;
    /**
     * 移動先の Y 座標。
     */
    y?: number;
  };

export class PazzlePlayer extends withFieldEntity(Actor) {
  private readonly properties: PazzleControllerProperties;
  private objects?: Array<PazzleObject>;
  private objectsSet: Set<PazzleObject>;
  private answer: string;
  private isCorrect: boolean;
  private successView: Actor;

  public constructor(configs: FieldEntityConfigs<PazzleControllerProperties>) {
    super({
      ...configs
      // color: Color.Red,
      // width: 96,
      // height: 96
    });
    this.properties = configs.properties;
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));

    this.objectsSet = new Set();

    this.answer = this.properties.answer;
    this.isCorrect = false;

    this.successView = new Actor();
    this.addChild(this.successView);
    const text = new Text({
      text: "成功！",
      font: new Font({
        bold: true,
        color: Color.Red,
        size: 30
      })
    });
    this.successView.graphics.add("text", text);

    writeLog("PazzleController", "v0.8");
  }

  public override onPreUpdate(_engine: Engine, _delta: number): void {

  }

  public onCollisionStart(event: CollisionStartEvent): void {
    if (event.other instanceof PazzleObject) {
      this.objectsSet?.add(event.other);
      const aa = Array.from(this.objectsSet).sort((a, b) => a.objectId - b.objectId);
      this.objectsSet = new Set([...aa]);
    }
  }

  public changeTergetValue(leftValue: number, rightValue: number): void {

    if (this.isCorrect) {
      return;
    }

    this.objects = [...this.objectsSet];

    const posa = vec(this.objects[leftValue].pos.x, this.objects[leftValue].pos.y);
    const posb = vec(this.objects[rightValue].pos.x, this.objects[rightValue].pos.y);
    this.objects[leftValue].pos = posb;
    this.objects[rightValue].pos = posa;

    const obja = this.objects[leftValue];
    const objb = this.objects[rightValue];
    this.objects[leftValue] = objb;
    this.objects[rightValue] = obja;


    let moji = "";
    this.objects.map((a): void => {
      moji += a.moji;
    });

    if (moji.indexOf(this.properties.answer) !== -1) {
      writeLog("###", "正解！", {moji});
      this.successView.graphics.show("text");
      this.isCorrect = true;
    }

    this.objectsSet = new Set([...this.objects]);

    writeLog("###", "changeTergetValue", {moji});
  }


}