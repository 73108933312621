import {css} from "@emotion/react";
import {ReactElement, ReactNode, useMemo, useRef} from "react";
import {CustomDataAttributes} from "src/modules/data";
import {Provider} from "./context";


export interface ScrollListProps<T> extends CustomDataAttributes {
  items?: ReadonlyArray<T>;
  insertMargin?: boolean;
  className?: string;
  children: ReactNode;
};

export type ScrollListComponent = <T extends {} = any>(props: ScrollListProps<T>) => ReactElement;

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-block-size: 0rem;
    flex-grow: 1;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const ScrollList: ScrollListComponent = ({
  items,
  insertMargin,
  className,
  children,
  ...data
}) => {
  const isEmpty = items != null && items.length <= 0;
  const isLoading = items == null;

  const mainRef = useRef<HTMLDivElement>(null);

  const contextValue = useMemo(() => ({
    insertMargin,
    isLoading,
    isEmpty,
    displayedItems: items ?? [],
    mainRef
  }), [
    insertMargin,
    isLoading,
    isEmpty,
    items,
    mainRef
  ]);

  return (
    <div className={className} css={styles.root} {...data}>
      <Provider value={contextValue}>
        {children}
      </Provider>
    </div>
  );
};