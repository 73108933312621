import {Actor, CollisionType} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";


/**
 * 当たり判定を作るエンティティです。
 * 他のエンティティが通り抜けられない壁などの場所を指定するのに利用してください。
 * @category Builtin Entity
 */
export class Collision extends withFieldEntity(Actor) {

  public constructor(configs: FieldEntityConfigs<{}>) {
    super(configs);
    this.body.collisionType = CollisionType.Fixed;
  }
}