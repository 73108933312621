import {css, keyframes} from "@emotion/react";
import {ReactNode, useMemo, useRef, useState} from "react";
import {FormEvent} from "react";
import {alpha, boxShadow, color, filterBlur, size, smartphone} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";
import {Provider} from "./context";


export interface DialogProps extends CustomDataAttributes {
  /** */
  isOpen: boolean;
  /** */
  onClose?: () => unknown;
  /**
   * フォーム送信時に実行されるコールバック関数。
   * これに関数が登録されていると、ドロワーの中身のルートとなる要素が `<div>` から `<form>` になり、その要素にこのコールバック関数が登録されます。
   */
  onSubmit?: (event: FormEvent<HTMLFormElement>) => unknown;
  /**
   * ルート要素の Z index。
   */
  zIndex?: number;
  /** */
  className?: string;
  /** */
  children?: ReactNode;
};

const styles = {
  root: css`
    inset: ${size(0)};
    inline-size: 100%;
    block-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    pointer-events: none;
  `,
  background: css`
    inline-size: 100%;
    block-size: 100%;
    inset: 0rem;
    background-color: ${alpha(color("white"), 0.3)};
    backdrop-filter: ${filterBlur(1)};
    opacity: 0;
    z-index: 0;
    position: absolute;
    animation: ${keyframes`
      to {opacity: 0;}
      from {opacity: 1;}
    `} 0.3s ease;
    &[data-open="true"] {
      opacity: 1;
      pointer-events: all;
      animation: ${keyframes`
        from {opacity: 0;}
        to {opacity: 1;}
      `} 0.3s ease;
    }
  `,
  mainContainer: css`
    inline-size: 100%;
    block-size: 100%;
    padding-block: ${size(4)};
    padding-inline: ${size(4)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  `,
  main: css`
    inline-size: 100%;
    max-inline-size: ${size(128)};
    max-block-size: 100%;
    padding-block: ${size(8)};
    padding-inline: ${size(10)};
    font-size: ${size(4)};
    border-radius: ${size(2)};
    background-color: ${alpha(color("white"), 0.95)};
    backdrop-filter: ${filterBlur(1)};
    box-shadow: ${boxShadow(alpha(color("primary", 5), 0.5), 1)};
    opacity: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    animation: ${keyframes`
      to {opacity: 0;}
      from {opacity: 1;}
    `} 0.3s ease;
    &[data-open="true"] {
      opacity: 1;
      pointer-events: all;
      animation: ${keyframes`
        from {opacity: 0;}
        to {opacity: 1;}
      `} 0.3s ease;
    }
    ${smartphone()} {
      padding-inline: ${size(8)};
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  zIndex,
  className,
  children,
  ...data
}) => {
  const TagName = onSubmit ? "form" : "div";

  const [prevOpen, setPrevOpen] = useState(isOpen);
  const [isExisting, setExisting] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  if (isOpen !== prevOpen) {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    if (isOpen) {
      setExisting(true);
    } else {
      intervalRef.current = setTimeout(() => setExisting(false), 300);
    }
    setPrevOpen(isOpen);
  }

  const contextValue = useMemo(() => ({
    isOpen,
    onClose
  }), [
    isOpen,
    onClose
  ]);

  return isExisting ? (
    <aside css={styles.root} className={className} style={zIndex ? {zIndex} : undefined} {...data}>
      <div css={styles.background} data-open={isOpen} onClick={onClose}/>
      <div css={styles.mainContainer}>
        <TagName css={styles.main} data-open={isOpen} onSubmit={onSubmit as any}>
          <Provider value={contextValue}>
            {children}
          </Provider>
        </TagName>
      </div>
    </aside>
  ) : null;
};
