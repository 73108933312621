import {LIBRARY_PAGE_URL, User, createUser, getMe, writeLog} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {ChangeEvent, FormEvent, useCallback, useRef, useState} from "react";
import {Button} from "src/components/common/button";
import {Checkbox} from "src/components/common/checkbox";
import {Heading} from "src/components/common/heading";
import {Input} from "src/components/common/input";
import {Link} from "src/components/common/link";
import {LinkButton} from "src/components/common/linkButton";
import {PasswordInput} from "src/components/common/passwordInput";
import {alpha, color, fontWeight, lineHeight, size, smartphone} from "src/components/constants/constants";
import {useTranslation} from "src/modules/translation";
import {useToast} from "src/overlays/common/toast";
import {LoginFormCover} from "src/overlays/core/loginFormCover";


interface Props {
  onSuccess?: (user: User) => void;
  onMoveToLogin: () => void;
};

const styles = {
  root: css`
    max-width: ${size(128)};
    font-size: ${size(4)};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  `,
  copy: css`
    margin-block-end: ${size(12)};
    padding-inline: ${size(12)};
    font-size: ${size(6)};
    color: ${color("primary", 6)};
    text-align: center;
    white-space: pre-line;
    ${lineHeight(1.6)}
    ${smartphone()} {
      display: none;
    }
  `,
  emphasis: css`
    font-weight: ${fontWeight("bold")};
    background-image: linear-gradient(transparent 60%, ${alpha(color("primary", 5), 0.2)} 60%);
  `,
  form: css`
    margin-block-start: ${size(5)};
  `,
  inputList: css`
    row-gap: ${size(4)};
    display: flex;
    flex-direction: column;
  `,
  input: css`
  `,
  checkboxContainer: css`
    margin-block-start: ${size(6)};
  `,
  checkbox: css`
    align-self: flex-start;
  `,
  buttonList: css`
    margin-block-start: ${size(6)};
    row-gap: ${size(3)};
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  button: css`
    inline-size: ${size(40)};
  `
};

export const RegisterForm: React.FC<Props> = ({
  onSuccess,
  onMoveToLogin
}) => {
  const {trans, transNode} = useTranslation("loginFormCover");

  const [handle, setHandle] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [campaignCode, setCampaignCode] = useState("");

  const [isLoading, setLoading] = useState(false);

  const showToast = useToast();

  const nicknameSyncingRef = useRef(true);

  const handleHandleChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setHandle(event.target.value);
    if (nicknameSyncingRef.current) {
      setNickname(event.target.value);
    }
  }, []);
  const handleNicknameChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setNickname(event.target.value);
    nicknameSyncingRef.current = false;
  }, []);

  const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    try {
      await createUser({handle, nickname, email, password, campaignCode});
      const me = await getMe();
      LoginFormCover.propsSubject.update({isVisible: false});
      writeLog("RegisterForm", "handleSubmit", me);
      onSuccess?.(me);
    } catch (error) {
      showToast(trans("createUserFailed"));
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [handle, nickname, email, password, campaignCode, showToast, onSuccess, trans]);

  return (
    <>
      <p css={styles.copy}>
        {transNode("copy", {
          em: (parts) => <em css={styles.emphasis}>{parts}</em>
        })}
      </p>
      <div css={styles.root}>
        <Heading>{trans("heading.register")}</Heading>
        <form css={styles.form} onSubmit={handleSubmit}>
          <div css={styles.inputList}>
            <Input
              css={styles.input}
              label={trans("handleWithInfo")}
              type="text"
              required={true}
              maxLength={64}
              autoComplete="username"
              value={handle}
              onChange={handleHandleChange}
            />
            <Input
              css={styles.input}
              label={trans("nickname")}
              type="text"
              required={true}
              autoComplete="nickname"
              value={nickname}
              onChange={handleNicknameChange}
            />
            <Input
              css={styles.input}
              label={trans("email")}
              type="email"
              required={true}
              autoComplete="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <PasswordInput
              css={styles.input}
              label={trans("passwordWithInfo")}
              type="password"
              required={true}
              minLength={8}
              autoComplete="new-password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <Input
              css={styles.input}
              label={trans("campaignCode")}
              type="text"
              required={true}
              autoComplete="off"
              autoFocus={true}
              value={campaignCode}
              onChange={(event) => setCampaignCode(event.target.value)}
            />
          </div>
          <div css={styles.checkboxContainer}>
            <Checkbox
              css={styles.checkbox}
              required={true}
              label={transNode("agree", {
                terms: (parts) => <Link href={`${LIBRARY_PAGE_URL}/terms`} target="_blank">{parts}</Link>,
                privacy: (parts) => <Link href={`${LIBRARY_PAGE_URL}/privacy`} target="_blank">{parts}</Link>
              })}
            />
          </div>
          <div css={styles.buttonList}>
            <Button css={styles.button} type="submit" isLoading={isLoading}>{trans("register")}</Button>
            <LinkButton type="button" onClick={onMoveToLogin}>{trans("moveToLogin")}</LinkButton>
          </div>
        </form>
      </div>
    </>
  );
};
