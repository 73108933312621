import {AnswerStatus, getCompetitionQuizResult, useApi} from "@baton8/quizium-lib-repositories";
import {css} from "@emotion/react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faMinus, faO, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Fragment} from "react";
import {color, fontWeight, size} from "src/components/constants/constants";
import {ScoreView} from "src/components/modules/scoreView";
import {UserView} from "src/components/modules/userView";
import {CustomDataAttributes} from "src/modules/data";
import {useTranslation} from "src/modules/translation";


export interface QuizRankingViewProps extends CustomDataAttributes {
  /**
   * イベントの ID。
   */
  competitionId: string | null;
  /**
   * クイズの ID。
   */
  quizId: string | null;
  /**
   * 集計タグ。
   */
  aggregationTags: [string, string?, string?] | null;
  /**
   * 表示するユーザーの数。
   * @defaultValue 10
   */
  size?: number;
  /** */
  className?: string;
};

const styles = {
  root: css`
    line-height: 1;
  `,
  title: css`
    margin-block-end: ${size(3)};
    font-size: ${size(4)};
    font-weight: ${fontWeight("bold")};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  titleName: css`
    margin-inline-start: 0.5em;
  `,
  table: css`
    row-gap: ${size(2)};
    column-gap: ${size(2)};
    display: grid;
    grid-template-columns: max-content 1fr max-content max-content;
    align-items: center;
  `,
  rank: css`
    font-size: ${size(3)};
    color: ${color("primary", 5)};
    text-align: center;
  `,
  name: css`
    font-size: ${size(4)};
    font-weight: ${fontWeight("bold")};
    overflow: hidden;
  `,
  score: css`
    row-gap: ${size(0.5)};
    display: flex;
    flex-direction: column;
    text-align: end;
  `,
  scorePrimary: css`
    font-size: ${size(5)};
    font-weight: ${fontWeight("bold")};
  `,
  scoreSecondary: css`
    font-size: ${size(4)};
  `,
  correctness: css`
    margin-inline-start: ${size(1)};
    font-size: ${size(8)};
    display: flex;
    justify-content: center;
    &[data-status="correct"] {
      color: ${color("blue", 5)};
    }
    &[data-status="incorrect"] {
      color: ${color("red", 5)};
    }
    &[data-status="neutral"],
    &[data-status="unqualified"] {
      color: ${color("primary", 5)};
    }
  `,
  comment: css`
    font-size: ${size(3)};
    text-align: center;
    opacity: 0.6;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const QuizRankingView: React.FC<QuizRankingViewProps> = ({
  competitionId,
  quizId,
  aggregationTags,
  size = 10,
  className,
  ...data
}) => {
  const {trans} = useTranslation("quizRankingView");

  const [quizResult] = useApi(getCompetitionQuizResult, competitionId != null && quizId != null && aggregationTags != null && [competitionId, quizId, aggregationTags], {refetchInterval: 3000});
  const userResults = quizResult;

  return userResults != null && userResults.length > 0 ? (
    <div css={styles.root} className={className} {...data}>
      {(userResults != null && aggregationTags != null) && (
        <div css={styles.table}>
          {userResults.slice(0, size).map((result, index) => (
            <Fragment key={index}>
              <div css={styles.rank}>
                {result.rank}
              </div>
              <div css={styles.name}>
                <UserView id={result.userId}/>
              </div>
              <div css={styles.score}>
                <div css={styles.scorePrimary}>
                  <ScoreView aggregationTag={aggregationTags[0]} point={result.points[0]} withSign={true}/>
                </div>
                {(aggregationTags[1] != null && result.points[1] != null) && (
                  <div css={styles.scoreSecondary}>
                    <ScoreView aggregationTag={aggregationTags[1]} point={result.points[1]} withSign={true}/>
                  </div>
                )}
              </div>
              <div css={styles.correctness} data-status={getAnswerStatusKey(result.answerStatus)}>
                <FontAwesomeIcon icon={getAnswerStatusIcon(result.answerStatus)}/>
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  ) : (
    <div>
      <div css={styles.comment}>
        {trans("empty")}
      </div>
    </div>
  );
};

const getAnswerStatusKey = (status: AnswerStatus): string => {
  if (status === AnswerStatus.Correct) {
    return "correct";
  } else if (status === AnswerStatus.Incorrect) {
    return "incorrect";
  } else if (status === AnswerStatus.Neutral) {
    return "neutral";
  } else if (status === AnswerStatus.Unqualified) {
    return "unqualified";
  } else {
    return "unqualified";
  }
};

const getAnswerStatusIcon = (status: AnswerStatus): IconProp => {
  if (status === AnswerStatus.Correct) {
    return faO;
  } else if (status === AnswerStatus.Incorrect) {
    return faTimes;
  } else if (status === AnswerStatus.Neutral) {
    return faMinus;
  } else if (status === AnswerStatus.Unqualified) {
    return faMinus;
  } else {
    return faMinus;
  }
};