import {global} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {useCallback} from "react";
import {QuizRankingView} from "src/components";
import {Button} from "src/components/common/button";
import {Scroll} from "src/components/common/scroll";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";
import {Dialog, DialogBody, DialogCloseButton, DialogHeader, DialogTitle} from "src/components/modules/dialog";
import {createOverlay} from "src/modules/create";
import {useBehaviorSubject} from "src/modules/subject";
import {useTranslation} from "src/modules/translation";


export interface QuizResultDialogProps {
  /**
   * このオーバーレイを表示するかどうか。
   * @initialValue `false`
   */
  isOpen: boolean;
  /**
   * このオーバーレイの Z index。
   * @initialValue `undefined`
   * @defaultValue `100`
   */
  zIndex?: number;
  /**
   * イベントの ID。
   * @initialValue `null`
   */
  competitionId: string | null;
  /**
   * クイズの ID。
   * @initialValue `null`
   */
  quizId: string | null;
  /**
   * 集計タグ。
   * @initialValue `null`
   */
  aggregationTags: [string, string?, string?] | null;
  /**
   * 「閉じる」ボタンが押されたときに実行されるコールバック関数。
   * このコールバック関数が設定されていなくても、「閉じる」ボタンを押すとダイアログは閉じます。
   * @initialValue `undefined`
   */
  onClose?: () => unknown;
};

const styles = {
  dialog: css`
  `,
  body: css`
    font-size: ${size(4)};
    display: flex;
    flex-direction: column;
  `,
  record: css`
    inline-size: 100%;
    max-inline-size: ${size(48)};
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  ranking: css`
    margin-block-start: ${size(3)};
    padding-block-start: ${size(3)};
    border-block-start: solid ${borderWidth(1)} ${alpha(color("primary", 5), 0.2)};
    flex-grow: 1;
    flex-shrink: 1;
  `,
  buttonList: css`
    margin-block-start: ${size(4)};
    row-gap: ${size(2)};
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  button: css`
    width: ${size(36)};
  `
};

/**
 * 指定されたイベントで出題された 1 問のクイズの結果をゲーム画面の中央に表示するオーバーレイです。
 * プレイヤーの得点と順位および全体ランキングが表示されます。
 * - **Inner Props**: {@link QuizResultDialogProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const QuizResultDialog = createOverlay<QuizResultDialogProps>(({
  isOpen,
  zIndex = 100,
  competitionId,
  quizId,
  aggregationTags,
  onClose
}) => {
  const {trans} = useTranslation("quizResultDialog");

  const user = useBehaviorSubject(global.userSubject);

  const handleClose = useCallback(() => {
    QuizResultDialog.propsSubject.update({isOpen: false});
    onClose?.();
  }, [onClose]);

  return (
    <Dialog css={styles.dialog} zIndex={zIndex} isOpen={isOpen} onClose={handleClose}>
      <DialogCloseButton/>
      <DialogHeader>
        <DialogTitle>{trans("title")}</DialogTitle>
      </DialogHeader>
      {aggregationTags != null && (
        <DialogBody css={styles.body}>
          <div css={styles.record}>
            ここに自分の結果を表示する予定です
          </div>
          <Scroll css={styles.ranking}>
            <QuizRankingView competitionId={competitionId} quizId={quizId} aggregationTags={aggregationTags} size={1000}/>
          </Scroll>
          <div css={styles.buttonList}>
            <Button css={styles.button} type="submit" onClick={handleClose}>{trans("close")}</Button>
          </div>
        </DialogBody>
      )}
    </Dialog>
  );
}, {
  isOpen: false,
  competitionId: null,
  quizId: null,
  aggregationTags: null
});
