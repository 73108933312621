import {css} from "@emotion/react";
import {faCircleNotch} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useContext} from "react";
import {color, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";
import {context} from "./context";


export interface ScrollListLoadingProps extends CustomDataAttributes {
  className?: string;
};

const styles = {
  root: css`
    font-size: ${size(8)};
    color: ${color("primary", 5)};
    align-self: center;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const ScrollListLoading: React.FC<ScrollListLoadingProps> = ({
  className,
  ...data
}) => {
  const {isLoading} = useContext(context);

  return isLoading ? (
    <div className={className} css={styles.root} {...data}>
      <FontAwesomeIcon icon={faCircleNotch} spin={true}/>
    </div>
  ) : null;
};