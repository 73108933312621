import {ChangeEvent, useCallback, useState} from "react";
import {useTranslation} from "src/modules/translation";
import {sound} from "src/sound";
import {VolumePane} from "./volumePane";


interface SoundVolumeProps {
};

export const SoundVolumePane: React.FC<SoundVolumeProps> = ({
}) => {
  const {trans} = useTranslation("soundMenu");

  // 表示の更新がこれ以外上手くいかなかった
  const [masterValue, setMasterValue] = useState(Math.trunc(sound.masterVolumeSubject.value * 100));
  const [masterMute, setMute] = useState(sound.masterMuteSubject.value);
  const [bgmValue, setBgmValue] = useState(Math.trunc(sound.bgmVolumeSubject.value * 100));
  const [bgmMute, setBgmMute] = useState(sound.bgmMuteSubject.value);
  const [ambientValue, setAmbientValue] = useState(Math.trunc(sound.ambientVolumeSubject.value * 100));
  const [ambientMute, setAmbientMute] = useState(sound.ambientMuteSubject.value);
  const [seValue, setSeValue] = useState(Math.trunc(sound.seVolumeSubject.value * 100));
  const [seMute, setSeMute] = useState(sound.seMuteSubject.value);

  const handleMasterVolumeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    sound.masterVolumeSubject.next(parseFloat(event.target.value) / 100);
    setMasterValue(parseInt(event.target.value));
  }, []);

  const handleMasterMuteChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    sound.masterMuteSubject.next(event.target.checked);
    setMute(event.target.checked);
  }, []);

  const handleBgmVolumeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    sound.bgmVolumeSubject.next(parseFloat(event.target.value) / 100);
    setBgmValue(parseInt(event.target.value));
  }, []);

  const handleBgmMuteChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    sound.bgmMuteSubject.next(event.target.checked);
    setBgmMute(event.target.checked);
  }, []);

  const handleAmbientVolumeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    sound.ambientVolumeSubject.next(parseFloat(event.target.value) / 100);
    setAmbientValue(parseInt(event.target.value));
  }, []);

  const handleAmbientMuteChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    sound.ambientMuteSubject.next(event.target.checked);
    setAmbientMute(event.target.checked);
  }, []);

  const handleSeVolumeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    sound.seVolumeSubject.next(parseFloat(event.target.value) / 100);
    setSeValue(parseInt(event.target.value));
  }, []);

  const handleSeMuteChange = (event: ChangeEvent<HTMLInputElement>): void => {
    sound.seMuteSubject.next(event.target.checked);
    setSeMute(event.target.checked);
  };

  return (
    <div>
      <VolumePane title={trans("masterVolume")} mute={masterMute} value={masterValue} onMuteChange={handleMasterMuteChange} onValueChange={handleMasterVolumeChange}/>
      <VolumePane title={trans("bgmVolume")} mute={bgmMute} value={bgmValue} onMuteChange={handleBgmMuteChange} onValueChange={handleBgmVolumeChange}/>
      <VolumePane title={trans("ambientVolume")} mute={ambientMute} value={ambientValue} onMuteChange={handleAmbientMuteChange} onValueChange={handleAmbientVolumeChange}/>
      <VolumePane title={trans("seVolume")} mute={seMute} value={seValue} onMuteChange={handleSeMuteChange} onValueChange={handleSeVolumeChange}/>
    </div>
  );
};

