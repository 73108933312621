import {css} from "@emotion/react";
import {ExtendedKeyboardEvent} from "mousetrap";
import {MouseEvent} from "react";
import {size} from "src/components/constants/constants";


interface AvatarImageProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "children" | "onClick"> {
  /*
  * アバターの名前。
  */
  avatarName: string | null;
  /** */
  onClick?: (event: MouseEvent<HTMLDivElement> | ExtendedKeyboardEvent) => void;
};

const styles = {
  imageCover: css`
    block-size: ${size(12)};
    inline-size: ${size(12)};
    display: flex;
    justify-content: center;
    overflow: hidden;
  `,
  avatarImage: css`
    height: 400%;
    transform: translateY(-2%);
  `
};

/**
 * - **Inner Props**: {@link AvatarImageProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const AvatarImage: React.FC<AvatarImageProps> = (({
  avatarName,
  onClick,
  ...props
}) => {
  return (
    <div css={styles.imageCover} onClick={onClick} {...props}>
      <img css={styles.avatarImage} alt={`${avatarName}`} src={`assets/sprites/characters/${avatarName}`}/>
    </div>
  );
});