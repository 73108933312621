import {PublicUser, listUsers, useApi} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faSearch} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Fuse from "fuse.js";
import React, {ReactNode, useCallback, useEffect, useRef, useState, useTransition} from "react";
import {HelperText} from "src/components/common/helperText";
import {Label} from "src/components/common/label";
import {ScrollList, ScrollListEmpty, ScrollListLoading, ScrollListMain} from "src/components/common/scrollList";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";
import {useTranslation} from "src/modules/translation";
import {UserMultiListboxPane} from "./userMultiListboxPane";


interface UserMultiListboxProps extends CustomDataAttributes {
  users: Array<PublicUser>;
  label?: ReactNode;
  helperText?: ReactNode;
  onChange?: (users: Array<PublicUser>) => unknown;
  className?: string;
};

const styles = {
  root: css`
  `,
  container: css`
    height: 16em;
    border-radius: ${size(1)};
    border: solid ${borderWidth(1)} ${alpha(color("black"), 0.3)};
    background-color: ${color("white")};
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    position: relative;
    &:focus-within {
      border-color: ${color("primary", 5)};
      outline: solid ${borderWidth(1)} ${color("primary", 5)};
    }
  `,
  left: css`
    display: flex;
    flex-direction: column;
  `,
  right: css`
    border-inline-start: solid ${borderWidth(1)} ${alpha(color("black"), 0.3)};
    display: flex;
    flex-direction: column;
  `,
  listScroll: css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  listLabel: css`
    margin-block-start: ${size(2)};
    margin-block-end: ${size(1)};
    padding-inline: 0.5em;
    font-size: ${3 / 4}em;
    opacity: 0.6;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  list: css`
    display: flex;
    flex-direction: column;
  `,
  inputContainer: css`
    border-block-end: solid ${borderWidth(1)} ${alpha(color("black"), 0.3)};
    column-gap: 0.5em;
    padding-inline: 0.5em;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  icon: css`
    padding-block: 0.5em;
    color: ${alpha(color("primary", 5), 0.6)};
    display: flex;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  input: css`
    height: 1em;
    padding-block: 0.5em;
    line-height: 1;
    flex-grow: 1;
    flex-shrink: 1;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const UserMultiListbox: React.FC<UserMultiListboxProps> = ({
  users,
  label,
  helperText,
  onChange,
  className,
  ...data
}) => {
  const {trans} = useTranslation("userMultiListbox");

  const [[allUsers = []] = []] = useApi(listUsers, [{}]);
  const [optionUsers, setOptionUsers] = useState(allUsers.slice(0, 100));

  const [, startTransition] = useTransition();

  const fuseRef = useRef(new Fuse<PublicUser>([]));
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = useCallback(() => {
    const pattern = inputRef.current?.value;
    if (pattern != null) {
      startTransition(() => {
        if (pattern !== "") {
          const optionUsers = fuseRef.current.search(pattern).slice(0, 100).map((result) => result.item);
          setOptionUsers(optionUsers);
        } else {
          const optionUsers = allUsers.slice(0, 100);
          setOptionUsers(optionUsers);
        }
      });
    }
  }, [allUsers]);

  useEffect(() => {
    fuseRef.current = new Fuse(allUsers, {keys: ["handle", "nickname"]});
    handleInputChange();
  }, [allUsers, handleInputChange]);

  return (
    <div className={["qr-multi", className].join(" ")} css={styles.root} {...data}>
      {!!label && (
        <Label>{label}</Label>
      )}
      <div css={styles.container}>
        <div css={styles.left}>
          <div css={styles.inputContainer}>
            <div css={styles.icon}><FontAwesomeIcon icon={faSearch}/></div>
            <input css={styles.input} ref={inputRef} onChange={handleInputChange}/>
          </div>
          <div css={styles.listScroll}>
            <div css={styles.listLabel}>{trans("hit")}</div>
            <ScrollList items={optionUsers}>
              <ScrollListLoading/>
              <ScrollListEmpty>{trans("empty")}</ScrollListEmpty>
              <ScrollListMain css={styles.list}>
                {(optionUser) => (
                  <UserMultiListboxPane key={optionUser.id} optionUser={optionUser} users={users} position="option" onChange={onChange}/>
                )}
              </ScrollListMain>
            </ScrollList>
          </div>
        </div>
        <div css={[styles.right, styles.listScroll]}>
          <div css={styles.listLabel}>{trans("selected")}</div>
          <ScrollList items={users}>
            <ScrollListLoading/>
            <ScrollListEmpty>{trans("notSelected")}</ScrollListEmpty>
            <ScrollListMain css={styles.list}>
              {(optionUser) => (
                <UserMultiListboxPane key={optionUser.id} optionUser={optionUser} users={users} position="selected" onChange={onChange}/>
              )}
            </ScrollListMain>
          </ScrollList>
        </div>
      </div>
      {!!helperText && (
        <HelperText>{helperText}</HelperText>
      )}
    </div>
  );
};