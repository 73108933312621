import {css} from "@emotion/react";
import {ReactNode, useContext} from "react";
import {lineHeight, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";
import {context} from "./context";


export interface ScrollListEmptyProps extends CustomDataAttributes {
  className?: string;
  children?: ReactNode;
};

const styles = {
  root: css`
    margin-inline: 0.5em;
    font-size: ${size(4)};
    opacity: 0.6;
    text-align: center;
    align-self: center;
    ${lineHeight(1.4)}
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const ScrollListEmpty: React.FC<ScrollListEmptyProps> = ({
  className,
  children,
  ...data
}) => {
  const {isLoading, isEmpty} = useContext(context);

  return !isLoading && isEmpty ? (
    <div className={className} css={styles.root} {...data}>
      {children}
    </div>
  ) : null;
};