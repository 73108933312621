import {LIBRARY_PAGE_URL} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faArrowUpRightFromSquare, faLeft} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dispatch, SetStateAction, useCallback} from "react";
import {LinkButton} from "src/components/common/linkButton";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";
import {DrawerBody} from "src/components/modules/drawer";
import {useTranslation} from "src/modules/translation";


interface Props {
  setMode: Dispatch<SetStateAction<string>>;
};

const styles = {
  body: css`
    display: flex;
    flex-direction: column;
  `,
  top: css`
    display: flex;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  iframeWrapper: css`
    margin-block-start: ${size(4)};
    border: solid ${borderWidth(1)} ${alpha(color("primary", 5), 0.3)};
    flex-grow: 1;
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    &::before {
      inset: ${size(0)};
      box-shadow: ${size(0)} ${size(0)} ${size(4)} ${alpha(color("primary", 5), 0.1)} inset;
      position: absolute;
      pointer-events: none;
      z-index: 1;
      content: "";
    }
  `,
  iframe: css`
    inline-size: 100%;
    block-size: 100%;
    border: none;
  `
};

export const HoldCompetitionDrawerLibraryPart: React.FC<Props> = ({
  setMode
}) => {
  const {trans} = useTranslation("holdCompetitionDrawer");

  const handleBack = useCallback(() => {
    setMode("main");
  }, [setMode]);

  return (
    <>
      <DrawerBody css={styles.body}>
        <div css={styles.top}>
          <LinkButton iconNode={<FontAwesomeIcon icon={faLeft}/>} onClick={handleBack}>{trans("back")}</LinkButton>
          <LinkButton iconNode={<FontAwesomeIcon icon={faArrowUpRightFromSquare}/>}>{trans("openInNewTab")}</LinkButton>
        </div>
        <div css={styles.iframeWrapper}>
          <iframe css={styles.iframe} src={LIBRARY_PAGE_URL}/>
        </div>
      </DrawerBody>
    </>
  );
};
