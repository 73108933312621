import {css} from "@emotion/react";
import {faEye, faEyeSlash} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InputHTMLAttributes, ReactNode, forwardRef, useCallback, useId, useState} from "react";
import {HelperText} from "src/components/common/helperText";
import {Label} from "src/components/common/label";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";


export interface PasswordInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "css"> {
  /**
   * テキスト欄の上に表示するラベル。
   */
  label?: ReactNode;
  /**
   * テキスト欄の下に表示する説明文。
   */
  helperText?: ReactNode;
  /**
   * 初期状態でパスワードを表示するかどうか。
   */
  initialReveal?: boolean;
  /** */
  className?: string;
}

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
  `,
  inputContainer: css`
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  input: css`
    height: 1em;
    padding-block: 0.5em;
    padding-inline: 0.5em;
    border: solid ${borderWidth(1)} ${alpha(color("black"), 0.3)};
    background-color: ${color("white")};
    border-radius: ${size(1)};
    line-height: 1;
    box-sizing: content-box;
    flex-grow: 1;
    flex-shrink: 1;
    &:focus {
      border-color: ${color("primary", 5)};
      outline: solid ${borderWidth(1)} ${color("primary", 5)};
    }
    &:disabled {
      cursor: inherit;
    }
  `,
  button: css`
    width: 1.2em;
    height: 1.2em;
    inset-inline-end: 0.5em;
    inset-block-end: 0.5em;
    border-radius: ${size(1)};
    color: ${color("primary", 5)};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    transition: color 0.2s ease;
    &:hover {
      color: ${color("primary", 4)};
    }
    &:focus-visible {
      outline: solid ${borderWidth(2)} ${alpha(color("primary", 4), 0.6)};
      outline-offset: ${borderWidth(1)};
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(({
  label,
  helperText,
  initialReveal = false,
  className,
  ...inputProps
}, ref) => {
  const id = useId();

  const [reveal, setReveal] = useState(initialReveal);

  const toggleReveal = useCallback(() => {
    setReveal((reveal) => !reveal);
  }, []);

  return (
    <label className={className} css={styles.root} htmlFor={id}>
      {!!label && (
        <Label>{label}</Label>
      )}
      <div css={styles.inputContainer}>
        <input css={styles.input} id={id} ref={ref} {...inputProps} type={reveal ? "input" : "password"}/>
        <button css={styles.button} type="button" onClick={toggleReveal}>
          <FontAwesomeIcon icon={reveal ? faEyeSlash : faEye}/>
        </button>
      </div>
      {!!helperText && (
        <HelperText>{helperText}</HelperText>
      )}
    </label>
  );
});