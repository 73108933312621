import {getCompetition, getCompetitionUserResult, global, useApi} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faAnglesRight, faCrown, faSwords} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCallback, useEffect, useState} from "react";
import {CircleIconButton} from "src/components/common/circleIconButton";
import {SingleLineText} from "src/components/common/singleLineText";
import {alpha, borderWidth, color, fontWeight, size} from "src/components/constants/constants";
import {RankView} from "src/components/modules/rankView";
import {RankingView} from "src/components/modules/rankingView";
import {UserRecordView} from "src/components/modules/userRecordView";
import {WhitePane} from "src/components/modules/whitePane";
import {useBehaviorSubject} from "src/modules/subject";
import {useTranslation} from "src/modules/translation";
import {isCompetitionSlideOpenSubject, isCompetitionSlideVisibleSubject} from "src/overlays/core/bottomOverlay/subject";


interface Props {
  /**
   * ランキングを表示する際に使用するイベントの ID。
   * `null` を指定すると、イベント結果が非表示になります。
   */
  competitionId: string | null;
  /**
   * ランキングを表示する際に使用する集計タグ。
   * `null` を指定すると、イベント結果が非表示になります。
   */
  aggregationTags: [string, string?, string?] | null;
};

const styles = {
  root: css`
    row-gap: ${size(4)};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `,
  paneWrapper: css`
    transform: translate(100%, 0%) translate(${size(6)}, 0rem);
    transition: transform 0.3s ease;
    &[data-open="true"] {
      transform: translate(0%, 0%) translate(0rem, 0rem);
    }
  `,
  pane: css`
    inline-size: ${size(48)};
    display: flex;
    flex-direction: column;
  `,
  title: css`
    font-size: ${size(4)};
    font-weight: ${fontWeight("bold")};
    color: ${color("primary", 5)};
    display: flex;
    align-items: center;
  `,
  record: css`
    margin-block-start: ${size(2)};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  ranking: css`
    margin-block-start: ${size(3)};
    padding-block-start: ${size(3)};
    border-block-start: solid ${borderWidth(1)} ${alpha(color("primary", 5), 0.2)};
  `,
  icon: css`
    margin-inline-end: 0.4em;
  `
};

export const CompetitionSlide: React.FC<Props> = ({
  competitionId,
  aggregationTags
}) => {
  const {trans} = useTranslation("bottomOverlay");

  const user = useBehaviorSubject(global.userSubject);

  const [competition] = useApi(getCompetition, competitionId != null && [competitionId]);
  const [userResult] = useApi(getCompetitionUserResult, (competitionId != null && aggregationTags != null && user != null) && [competitionId, user.id, aggregationTags], {refetchInterval: 3000});

  const [isOpen, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((isOpen) => !isOpen);
  }, []);

  useEffect(() => {
    isCompetitionSlideVisibleSubject.next(competitionId != null && aggregationTags != null);
  }, [competitionId, aggregationTags]);
  useEffect(() => {
    if (competitionId != null && aggregationTags != null) {
      isCompetitionSlideOpenSubject.next(isOpen);
    } else {
      isCompetitionSlideOpenSubject.next(false);
    }
  }, [isOpen, competitionId, aggregationTags]);

  return (competitionId != null && aggregationTags != null) ? (
    <div css={styles.root}>
      <div css={styles.paneWrapper} data-open={isOpen}>
        <WhitePane css={styles.pane}>
          <div css={styles.title}>
            <FontAwesomeIcon css={styles.icon} icon={faSwords}/>
            <SingleLineText>{competition?.title ?? "?"}</SingleLineText>
          </div>
          {userResult != null && (
            <div css={styles.record}>
              <RankView rank={userResult.rank}/>
              <UserRecordView aggregationTags={aggregationTags} points={userResult.points} answerCounts={userResult.answerCounts}/>
            </div>
          )}
          <div css={styles.ranking}>
            <RankingView competitionId={competitionId} aggregationTags={aggregationTags} size={10}/>
          </div>
        </WhitePane>
      </div>
      <CircleIconButton
        iconNode={<FontAwesomeIcon icon={isOpen ? faAnglesRight : faCrown}/>}
        variant="outline"
        onClick={toggleOpen}
        aria-label={trans("showCompetition")}
      />
    </div>
  ) : null;
};
