export const ASSETS = {
};

export const AVATAR_NAMES = [
  "fantasyAnimalA2.png",
  "fantasyAnimalA5.png",
  "fantasyAnimalA7.png",
  "fantasyAnimalB1.png",
  "fantasyAnimalB3.png",
  "fantasyAnimalB5.png",
  "fantasyAnimalB7.png",
  "fantasyAnimalC0.png",
  "fantasyAnimalC1.png",
  "fantasyAnimalC2.png",
  "fantasyAnimalC3.png",
  "fantasyAnimalC4Var2.png",
  "fantasyAnimalC4.png",
  "fantasyAnimalC5.png",
  "fantasyAnimalC6.png",
  "fantasyAnimalC7.png",
  "fantasyAnimalD0.png",
  "fantasyAnimalD1.png",
  "fantasyAnimalD2.png",
  "fantasyAnimalD3.png",
  "fantasyAnimalD4.png",
  "fantasyAnimalD5.png",
  "fantasyAnimalD6.png",
  "fantasyAnimalD7.png",
  "fantasyAnimalE0.png",
  "fantasyAnimalE1.png",
  "fantasyAnimalE2.png",
  "fantasyAnimalE3.png",
  "fantasyAnimalE4.png",
  "fantasyAnimalE5.png",
  "fantasyAnimalE6.png",
  "fantasyAnimalE7.png",
  "fantasyAnimalE8.png",
  "fantasyBirdA5.png",
  "fantasyBirdA7.png",
  "fantasyBirdB1.png",
  "fantasyBirdB3.png",
  "fantasyBirdB5.png",
  "fantasyBirdB7.png",
  "fantasyBison.png",
  "fantasyBuffalo.png",
  "fantasyCamel.png",
  "fantasyElephant.png",
  "fantasyElephant2.png",
  "fantasyHippo.png",
  "fantasyHorse0.png",
  "fantasyHorse1.png",
  "fantasyHorse2.png",
  "fantasyHorse3.png",
  "fantasyHorse4.png",
  "fantasyHorse5.png",
  "fantasyHorse6.png",
  "fantasyHorse7.png",
  "fantasyHorse8.png",
  "fantasyLion.png",
  "fantasyLion2.png",
  "fantasyLioness.png",
  "fantasyLioness2.png",
  "fantasySeal.png",
  "fantasyWalrus.png",
  "fantasyZebra.png",
  "fantasyZebra2.png"
];