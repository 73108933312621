import {css} from "@emotion/react";
import {AnchorHTMLAttributes, forwardRef} from "react";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";


export interface LinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "css"> {
  className?: string;
}

const styles = {
  root: css`
    border-radius: ${size(1)};
    color: ${color("primary", 5)};
    line-height: 1;
    text-decoration-line: underline;
    cursor: pointer;
    transition: color 0.2s ease;
    &:hover {
      color: ${color("primary", 4)};
    }
    &:focus-visible {
      outline: solid ${borderWidth(2)} ${alpha(color("primary", 5), 0.6)};
      outline-offset: ${borderWidth(1)};
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(({
  className,
  children,
  ...props
}, ref) => {
  return (
    <a className={className} css={styles.root} ref={ref} {...props}>
      {children}
    </a>
  );
});