import {css} from "@emotion/react";
import {faCircleNotch} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {color, lineHeight, size} from "src/components/constants/constants";
import {GameCover} from "src/components/modules/gameCover";
import {createOverlay} from "src/modules/create";
import {useTranslation} from "src/modules/translation";


export interface DisconnectedCoverProps {
  isVisible: boolean;
  zIndex?: number;
}

const styles = {
  panel: css`
    inline-size: 100%;
    block-size: 100%;
    row-gap: ${size(8)};
    background-color: ${color("white")};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  icon: css`
    font-size: ${size(16)};
    color: ${color("primary", 5)};
  `,
  message: css`
    font-size: ${size(6)};
    color: ${color("primary", 5)};
    white-space: pre-wrap;
    text-align: center;
    ${lineHeight(1.4)}
  `
};

/**
 * 接続が切断したときに表示されるオーバーレイです。
 * 全ての UI より上に表示されるように、デフォルトの Z index は 400 に指定されています。
 *
 * - **Inner Props**: {@link DisconnectedCoverProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const DisconnectedCover = createOverlay<DisconnectedCoverProps>(({
  isVisible,
  zIndex = 400
}) => {
  const {trans} = useTranslation("disconnectedCover");

  return isVisible ? (
    <GameCover zIndex={zIndex}>
      <div css={styles.panel}>
        <FontAwesomeIcon css={styles.icon} icon={faCircleNotch} spin={true}/>
        <p css={styles.message}>{trans("message")}</p>
      </div>
    </GameCover>
  ) : null;
}, {
  isVisible: false
});
