import {css} from "@emotion/react";
import {ReactNode} from "react";
import {CustomDataAttributes} from "src/modules/data";


interface DrawerBodyProps extends CustomDataAttributes {
  className?: string;
  children?: ReactNode;
};

const styles = {
  root: css`
    flex-grow: 1;
    flex-shrink: 1;
    z-index: 2;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DrawerBody: React.FC<DrawerBodyProps> = ({
  className,
  children,
  ...data
}) => {
  return (
    <div css={styles.root} className={className} {...data}>
      {children}
    </div>
  );
};
