import {Actor, CollisionStartEvent, CollisionType, Vector} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {Npc} from "src/entities/common/npc";


/**
 * `Npc` エンティティの移動範囲を指定します。
 * `Npc` エンティティがこれに重なると、この内部でしか移動しなくなります。
 */
export class NpcMoveArea extends withFieldEntity(Actor) {

  public constructor(configs: FieldEntityConfigs<{}>) {
    super(configs);
    this.body.collisionType = CollisionType.Passive;
    this.on("collisionstart", this.onCollisionStart.bind(this));
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    if (event.other instanceof Npc) {
      const npc = event.other as Npc;
      npc.areaStartPosition = new Vector(this.pos.x - this.width / 2 + npc.width / 2, this.pos.y - this.height / 2 - npc.height / 2);
      npc.areaEndPosition = new Vector(npc.areaStartPosition.x + this.width - npc.width / 2, npc.areaStartPosition.y + this.height);
    }
  }
}