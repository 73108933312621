import {PublicUser, getImage, orNull, useApi} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";


interface Props {
  user: PublicUser;
};

const styles = {
  root: css`
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
  `
};

export const UserAvatar: React.FC<Props> = ({
  user
}) => {
  const icon = user.icon;
  const [image] = useApi(orNull(getImage), [icon], {staleTime: Infinity});

  const src = image?.url;
  const background = image != null ? "white" : `hsl(${parseInt(user.id.slice(0, 8), 16) % 360}, 30%, 50%)`;

  return src != null ? (
    <img css={styles.root} src={src}/>
  ) : (
    <div css={styles.root} style={{background}}/>
  );
};
