import {DetailedSession, FieldId, writeLog} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faMap, faSwords, faUser} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCallback, useMemo} from "react";
import {SingleLineText} from "src/components/common/singleLineText";
import {alpha, color, fontWeight, size} from "src/components/constants/constants";


interface Props {
  index: number; // TODO: これはサンプル表示用
  session: DetailedSession;
  onClick?: (session: DetailedSession, fieldId?: FieldId) => unknown;
  onMouseEnter?: () => unknown;
  onMouseLeave?: () => unknown;
};

const styles = {
  root: css`
    padding-block: ${size(1)};
    padding-inline: ${size(3)};
    column-gap: ${size(2)};
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
    cursor: pointer;
    &[data-competition="true"] {
      --icon-color: ${color("pink", 5)};
      --tag-background-color: ${alpha(color("pink", 5), 0.2)};
      &:hover {
        background-color: ${color("pink", 0)};
      }
    }
    &:not([data-competition="true"]) {
      --icon-color: ${color("primary", 5)};
      --tag-background-color: ${alpha(color("primary", 5), 0.2)};
      &:hover {
        background-color: ${color("primary", 0)};
      }
    }
  `,
  iconWrapper: css`
    inline-size: 0.9em;
    font-size: ${size(4)};
    color: var(--icon-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  `,
  icon: css`
  `,
  nameContainer: css`
    row-gap: ${size(1)};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-grow: 1;
  `,
  name: css`
    font-size: ${size(4)};
    font-weight: ${fontWeight("bold")};
  `,
  tag: css`
    padding-block: ${size(0.5)};
    padding-inline: ${size(1)};
    font-size: ${size(3)};
    border-radius: ${size(1)};
    background-color: var(--tag-background-color);
  `,
  playerCountContainer: css`
    display: flex;
    align-items: center;
    opacity: 0.6;
  `,
  playerCountIcon: css`
    margin-inline-end: 0.4em;
    font-size: ${size(3)};
  `,
  playerCount: css`
    font-size: ${size(4)};
  `
};

export const SessionButtonRealmPane: React.FC<Props> = ({
  index,
  session,
  onClick,
  onMouseEnter,
  onMouseLeave
}) => {
  const hasCompetition = useMemo(() => Math.random() < 0.7, []);

  const handleClick = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const fieldId = index === 0 ? "sharingLobby" : "approx";
    writeLog("SessionButtonRealmPane", "handleClick", {session, fieldId});
    onClick?.(session, fieldId);
  }, [session, index, onClick]);

  return (
    <button css={styles.root} type="button" onClick={handleClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} data-competition={hasCompetition}>
      <div css={styles.icon}>
        <div css={styles.iconWrapper}><FontAwesomeIcon icon={hasCompetition ? faSwords : faMap}/></div>
      </div>
      <div css={styles.nameContainer}>
        <SingleLineText css={styles.name}>
          {index === 0 ? "得点山分け択一" : "近似値"}
        </SingleLineText>
      </div>
      <div css={styles.playerCountContainer}>
        <div css={styles.playerCountIcon}>
          <FontAwesomeIcon icon={faUser}/>
        </div>
        <div css={styles.playerCount}>
          ?
        </div>
      </div>
    </button>
  );
};
