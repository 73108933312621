import {css} from "@emotion/react";
import {useCallback} from "react";
import {lineHeight, size} from "src/components/constants/constants";
import {BlackPane} from "src/components/modules/blackPane";
import {GameCover} from "src/components/modules/gameCover";
import {createOverlay} from "src/modules/create";


export interface MessageDialogProps {
  /**
   * このオーバーレイを表示するかどうか。
   * @initialValue `false`
   */
  isVisible: boolean;
  /**
   * このオーバーレイの Z index。
   * @initialValue `undefined`
   * @defaultValue `100`
   */
  zIndex?: number;
  /**
   * 表示するメッセージ。
   * @initialValue `null`
   */
  message: string | null;
  /**
   * 「閉じる」ボタンが押されたときに実行されるコールバック関数。
   * @initialValue `undefined`
   */
  onConfirm?: () => unknown;
}

const styles = {
  cover: css`
    padding-block: ${size(6)};
    padding-inline: ${size(6)};  
    display: flex;
    align-items: flex-start;
    justify-content: center;
    pointer-events: none;
  `,
  pane: css`
  `,
  text: css`
    font-size: ${size(4)};
    ${lineHeight(1.4)}
  `
};

/**
 * - **Inner Props**: {@link MessageDialogProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const MessageDialog = createOverlay<MessageDialogProps>(({
  isVisible,
  zIndex = 100,
  message,
  onConfirm
}) => {
  // TODO: これを使う
  const handleClose = useCallback(() => {
    onConfirm?.();
    MessageDialog.propsSubject.update({isVisible: false});
  }, [onConfirm]);

  return isVisible ? (
    <GameCover css={styles.cover} zIndex={zIndex}>
      <BlackPane css={styles.pane}>
        <p css={styles.text}>
          {message}
        </p>
      </BlackPane>
    </GameCover>
  ) : null;
}, {
  isVisible: false,
  message: null
});