import {PublicUser, User, getUserById, useApi} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faPen} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCallback} from "react";
import {SingleLineText} from "src/components/common/singleLineText";
import {CustomDataAttributes} from "src/modules/data";
import {InputUserNameDialog} from "src/overlays";
import {UserAvatar} from "./userAvatar";


export interface UserViewProps extends CustomDataAttributes {
  /**
   * ユーザーの ID。
   * `user` が指定されている場合は無視されます。
   */
  id?: string;
  /**
   * ユーザー。
   * これが指定されると、コンポーネント内部で API を呼んでユーザーデータを取得しなくなります。
   * すでにユーザーオブジェクトを取得済みの場合は、これを指定することで余計な API 呼び出しを防ぐことができます。
   */
  user?: User | PublicUser;
  /**
   * アバター画像を表示するかどうか。
   * @defaultValue `true`
   */
  showAvatar?: boolean;
  /**
   * 名前を表示するかどうか。
   * @defaultValue `true`
   */
  showName?: boolean;
  /**
   * ユーザー ID を表示するかどうか。
   * @defaultValue `false`
   */
  showHandle?: boolean;
  /**
   * 名前を編集するボタンを表示するかどうか。
   */
  showEditName?: boolean;
  /** */
  className?: string;
};

const styles = {
  root: css`
    column-gap: 0.4em;
    display: flex;
    align-items: center;
    overflow: hidden;
  `,
  right: css`
    row-gap: ${1 / 4}em;
    display: flex;
    flex-direction: column;
  `,
  nickname: css`
  `,
  handle: css`
    font-size: ${3 / 4}em;
    opacity: 0.6;
    word-break : break-all;
  `,
  button: css`
    cursor: pointer;
  `,
  icon: css`
    font-size: ${3 / 4}em;
  `
};

/**
 * ユーザーのアバターと名前を表示します。
 * 内部でフォントサイズやフォントスタイルなどを設定していないので、外側の設定が継承されます。
 * @group React Components
 * @category Common Component
 */
export const UserView: React.FC<UserViewProps> = ({
  id,
  user,
  showAvatar = true,
  showName = true,
  showHandle = false,
  showEditName = false,
  className,
  ...data
}) => {
  const [innerUser] = useApi(getUserById, (user == null && id != null) && [id]);
  const actualUser = user ?? innerUser;

  const handleClick = useCallback(() => {
    if (actualUser) {
      InputUserNameDialog.propsSubject.next({
        isVisible: true,
        user: actualUser,
        key: actualUser.nickname
      });
    }
  }, [actualUser]);

  return (
    <div css={styles.root} className={className} {...data}>
      {(actualUser != null && showAvatar) && (
        <UserAvatar user={actualUser}/>
      )}
      {(showName || showHandle) && (
        <div css={styles.right}>
          {showName && (
            <SingleLineText css={styles.nickname}>{actualUser != null ? actualUser.nickname : "?"}</SingleLineText>
          )}
          {showHandle && (
            <SingleLineText css={styles.handle}>{actualUser != null ? "@" + actualUser.handle : "?"}</SingleLineText>
          )}
        </div>
      )}
      {showEditName && (
        <button css={styles.button} type="button" onClick={handleClick}>
          <FontAwesomeIcon css={styles.icon} icon={faPen}/>
        </button>
      )}
    </div>
  );
};
