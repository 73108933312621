import {Actor, CollisionType} from "excalibur";
import {FieldEntityConfigs, withFieldEntity} from "src/ecs";


/**
 * @deprecated
 * 現在は非推奨です。
 * 代わりに `withFieldEntity(Actor)` を継承する形でマップエンティティを実装してください。
 */
export class FieldEntity extends withFieldEntity(Actor) {
  public constructor(configs: FieldEntityConfigs<{[K in string]?: any}>) {
    super({
      x: configs.x,
      y: configs.y,
      width: configs.width,
      height: configs.height,
      name: configs.name,
      collisionType: CollisionType.PreventCollision
    });
  }
}