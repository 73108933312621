import {
  Animation,
  AnimationStrategy,
  Color,
  Graphic,
  GraphicsComponent,
  GraphicsGroup,
  ImageSource,
  Rectangle,
  SpriteSheet,
  Vector,
  vec
} from "excalibur";
import {Direction} from "src/entities";


export class CharacterAnimation {
  public direction: Direction;
  public graphicName: string;
  public imageSource: ImageSource | null;

  public constructor() {
    this.direction = "down";
    this.graphicName = "down";
    this.imageSource = null;
  }

  public async setupAnimations(graphics: GraphicsComponent, avatarName: string | null, height: number): Promise<void> {
    const transparent = new Rectangle({width: 1, height: 1, color: Color.Transparent});
    graphics.add("left", transparent);
    graphics.add("right", transparent);
    graphics.add("up", transparent);
    graphics.add("down", transparent);
    graphics.add("leftStopping", transparent);
    graphics.add("rightStopping", transparent);
    graphics.add("upStopping", transparent);
    graphics.add("downStopping", transparent);

    if (avatarName !== null) {
      this.imageSource = new ImageSource(`assets/sprites/characters/${avatarName}`);
      await this.imageSource.load();

      const spriteWidth = this.imageSource.width / 3;
      const spriteHeight = this.imageSource.height / 4;
      const pos = vec(0, height / 2 - spriteHeight / 2);

      const spriteSheet = SpriteSheet.fromImageSource({
        image: this.imageSource,
        grid: {columns: 3, rows: 4, spriteWidth, spriteHeight}
      });

      graphics.add("left", this.createGraphic(Animation.fromSpriteSheet(spriteSheet, [3, 4, 3, 5], 150, AnimationStrategy.Loop), pos));
      graphics.add("right", this.createGraphic(Animation.fromSpriteSheet(spriteSheet, [6, 7, 6, 8], 150, AnimationStrategy.Loop), pos));
      graphics.add("up", this.createGraphic(Animation.fromSpriteSheet(spriteSheet, [9, 10, 9, 11], 150, AnimationStrategy.Loop), pos));
      graphics.add("down", this.createGraphic(Animation.fromSpriteSheet(spriteSheet, [0, 1, 0, 2], 150, AnimationStrategy.Loop), pos));
      graphics.add("leftStopping", this.createGraphic(spriteSheet.sprites[4], pos));
      graphics.add("rightStopping", this.createGraphic(spriteSheet.sprites[7], pos));
      graphics.add("upStopping", this.createGraphic(spriteSheet.sprites[10], pos));
      graphics.add("downStopping", this.createGraphic(spriteSheet.sprites[1], pos));
    }
  }

  public updateAnimation(graphics: GraphicsComponent, vel: Vector): void {
    const magnitude = vel.size;
    const angle = vel.toAngle() * 180 / Math.PI;
    if (magnitude > 0) {
      if (angle <= -135 || angle > 135) {
        graphics.use("left");
        this.direction = "left";
        this.graphicName = "left";
      } else if (angle <= -45) {
        graphics.use("up");
        this.direction = "up";
        this.graphicName = "up";
      } else if (angle <= 45) {
        graphics.use("right");
        this.direction = "right";
        this.graphicName = "right";
      } else {
        graphics.use("down");
        this.direction = "down";
        this.graphicName = "down";
      }
    } else {
      graphics.use(`${this.direction}Stopping`);
      this.graphicName = `${this.direction}Stopping`;
    }
  }

  private createGraphic(graphic: Graphic, pos: Vector): Graphic {
    const graphicsGroup = new GraphicsGroup({members: [{graphic, pos}]});
    return graphicsGroup;
  }
}