import {
  ColliderComponent,
  Component,
  Entity,
  System,
  SystemType,
  TransformComponent,
  Vector,
  vec
} from "excalibur";
import {ReactNode} from "react";
import {MainScene} from "src/entities";
import {FollowingNodeContainer, FollowingNodeSpec} from "src/overlays";


const COMPONENT_TYPE = "qroud.followingNode" as const;
const SYSTEM_TYPES = ["qroud.followingNode"] as const;

const COMPONENT_DEPENDENCIES = [TransformComponent, ColliderComponent];

// これを変えると更新頻度を落とせる。
// しかし、更新頻度を落とすとガクガクしてとても違和感があったので、0 に設定しておく。
const UPDATE_INTERVAL = 0;

export type FollowingNodeAnchor = "center" | "topLeft" | "top" | "topRight" | "left" | "right" | "bottomLeft" | "bottom" | "bottomRight";

/**
 * @category Excalibur ECS
 */
export class FollowingNodeComponent extends Component<typeof COMPONENT_TYPE> {
  public readonly type = COMPONENT_TYPE;
  public override readonly dependencies = COMPONENT_DEPENDENCIES;

  /**
   * 表示する React コンポーネント。
   */
  public node: ReactNode;
  /**
   * React コンポーネントを表示する位置。
   */
  public anchor: FollowingNodeAnchor;
  /**
   * `anchor` で設定された位置から React コンポーネントをオフセットする量。
   */
  public offset: Vector;

  public constructor(initialValue?: Partial<Pick<FollowingNodeComponent, "node" | "anchor" | "offset">>) {
    super();
    this.node = initialValue?.node ?? null;
    this.anchor = initialValue?.anchor ?? "center";
    this.offset = initialValue?.offset ?? vec(0, 0);
  }
}

/**
 * @category Excalibur ECS
 */
export class FollowingNodeSystem extends System<FollowingNodeComponent> {
  public readonly types = SYSTEM_TYPES;
  public readonly systemType = SystemType.Update;

  private scene!: MainScene;
  private timer: number = 0;

  public override initialize(scene: MainScene): void {
    this.scene = scene;
  }

  public override update(entities: Array<Entity>, delta: number): void {
    this.sendProps(entities, delta);
  }

  private sendProps(entities: Array<Entity>, delta: number): void {
    this.timer += delta;
    if (this.timer > UPDATE_INTERVAL) {
      const specs = entities.map((entity) => this.createSpec(entity));
      FollowingNodeContainer.propsSubject.next({specs});
      this.timer -= UPDATE_INTERVAL;
    }
  }

  private createSpec(entity: Entity): FollowingNodeSpec {
    const transform = entity.get(TransformComponent)!;
    const collider = entity.get(ColliderComponent)!;
    const followingNode = entity.get(FollowingNodeComponent)!;

    const anchorPos = getAnchorPos(transform.pos.x, transform.pos.y, collider.localBounds.width, collider.localBounds.height, followingNode.anchor);
    const pagePos = this.scene.engine.screen.worldToPageCoordinates(anchorPos).add(followingNode.offset);
    return {
      key: `${this.scene.field.id}.${entity.id}`,
      x: pagePos.x,
      y: pagePos.y,
      node: followingNode.node
    };
  }
}

const getAnchorPos = (x: number, y: number, width: number, height: number, anchor: FollowingNodeAnchor): Vector => {
  if (anchor === "center") {
    return vec(x, y);
  } else if (anchor === "topLeft") {
    return vec(x - width / 2, y - height / 2);
  } else if (anchor === "top") {
    return vec(x, y - height / 2);
  } else if (anchor === "topRight") {
    return vec(x + width / 2, y - height / 2);
  } else if (anchor === "left") {
    return vec(x - width / 2, y);
  } else if (anchor === "right") {
    return vec(x + width / 2, y);
  } else if (anchor === "bottomLeft") {
    return vec(x - width / 2, y + height / 2);
  } else if (anchor === "bottom") {
    return vec(x, y + height / 2);
  } else if (anchor === "bottomRight") {
    return vec(x + width / 2, y + height / 2);
  } else {
    anchor satisfies never;
    throw new Error("This never happens");
  }
};
