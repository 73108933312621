import {css} from "@emotion/react";
import {ReactNode} from "react";
import {color, fontWeight, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


interface DrawerTitleProps extends CustomDataAttributes {
  className?: string;
  children?: ReactNode;
};

const styles = {
  root: css`
    font-size: ${size(8)};
    font-weight: ${fontWeight("bold")};
    color: ${color("primary", 6)};
    letter-spacing: 0.15em;
    transform: skew(-3deg, 0deg);
    flex-grow: 0;
    flex-shrink: 0;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DrawerTitle: React.FC<DrawerTitleProps> = ({
  className,
  children,
  ...data
}) => {
  return (
    <h2 css={styles.root} className={className} {...data}>
      {children}
    </h2>
  );
};
