import {css} from "@emotion/react";
import {ReactNode} from "react";
import {alpha, color, filterBlur, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


export interface BlackPaneProps extends CustomDataAttributes {
  /** */
  className?: string;
  /** */
  children?: ReactNode;
};

const styles = {
  root: css`
    padding-block: ${size(3)};
    padding-inline: ${size(4)};
    border-radius: ${size(2)};
    color: ${color("whiteText")};
    background-color: ${alpha(color("black"), 0.8)};
    backdrop-filter: ${filterBlur(1)};
    box-sizing: border-box;
    position: relative;
    pointer-events: all;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const BlackPane: React.FC<BlackPaneProps> = ({
  className,
  children,
  ...data
}) => {
  return (
    <div css={styles.root} className={className} {...data}>
      {children}
    </div>
  );
};
