import {RefObject, createContext} from "react";


type ContextValue = {
  insertMargin?: boolean,
  isLoading: boolean,
  isEmpty: boolean,
  displayedItems: ReadonlyArray<any>,
  mainRef: RefObject<HTMLDivElement>
};

export const context = createContext<ContextValue>({
  isLoading: true,
  isEmpty: false,
  displayedItems: [],
  mainRef: {current: null}
});
export const Provider = context.Provider;