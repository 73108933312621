import {DisconnectDescription} from "socket.io-client/build/esm/socket";
import {socket} from "src/clients/socket.js";
import {PlayerInfo} from "src/types";


/**
 * 自身がサーバーに接続したときに実行するリスナーを設定します。
 * @param listener リスナー
 * @group API Wrappers
 * @category Connection
 */
export const listenConnected = (listener: () => unknown): void => {
  socket.on("connect", listener);
};

/**
 * @group Types
 * @category Connection
 */
export type DisconnectReason = "io server disconnect" | "io client disconnect" | "ping timeout" | "transport close" | "transport error" | "parse error";

/**
 * 自身がサーバーから切断したときに実行するリスナーを設定します。
 * @param listener リスナー
 * @group API Wrappers
 * @category Connection
 */
export const listenDisconnected = (listener: (reason: DisconnectReason, description?: DisconnectDescription) => unknown): void => {
  socket.on("disconnect", listener);
};

/**
 * 同じセッションにいる別のプレイヤー切断したときに実行するリスナーを設定します。
 * @param listener リスナー
 * @group API Wrappers
 * @category Connection
 */
export const listenPlayerDisconnected = (listener: (playerInfo: PlayerInfo) => unknown): void => {
  socket.on("playerDisconnected", listener);
};