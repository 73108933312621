import {css} from "@emotion/react";
import {faDownLong} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ReactNode, useContext} from "react";
import {alpha, borderWidth, color} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";
import {useTranslation} from "src/modules/translation";
import {context} from "./context";


export interface ScrollListMoreButtonProps extends CustomDataAttributes {
  /**
   * 読み込んでいないデータが残っているかどうか。
   * これが `true` の場合にのみこのコンポーネントが表示されます。
   */
  hasNext?: boolean;
  /**
   * このコンポーネントがクリックされたときに呼び出されるコールバック関数。
   */
  onNext?: () => unknown;
  /** */
  className?: string;
  /** */
  children?: ReactNode;
};

const styles = {
  root: css`
    column-gap: 0.4em;
    color: ${color("primary", 5)};
    display: flex;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      color: ${color("primary", 4)};
    }
    &:focus-visible {
      outline: solid ${borderWidth(2)} ${alpha(color("primary", 5), 0.6)};
      outline-offset: ${borderWidth(1)};
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const ScrollListMoreButton: React.FC<ScrollListMoreButtonProps> = ({
  hasNext,
  onNext,
  className,
  children,
  ...data
}) => {
  const {trans} = useTranslation("scrollList");

  const {isLoading, isEmpty} = useContext(context);

  return !isLoading && !isEmpty && hasNext ? (
    <button className={className} css={styles.root} type="button" onClick={onNext} {...data}>
      <FontAwesomeIcon icon={faDownLong}/>
      <div>
        {children != null ? children : trans("more")}
      </div>
      <FontAwesomeIcon icon={faDownLong}/>
    </button>
  ) : null;
};