import {Actor, CollisionEndEvent, CollisionStartEvent, CollisionType} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {Ghost} from "src/entities/types/ghostGroup";


export interface HideGhostsTriggerProperties {
};

/**
 * プレイヤーがこのエンティティに触れている間、他のプレイヤーのうち同じくこのエンティティに触れているプレイヤーが見えなくなります。
 * @category Builtin Entity
 */
export class HideGhostsTrigger extends withFieldEntity(Actor) {
  private isActivated: boolean;

  public constructor(configs: FieldEntityConfigs<HideGhostsTriggerProperties>) {
    super(configs);
    this.body.collisionType = CollisionType.Passive;
    this.isActivated = false;
    this.on("collisionstart", this.onCollisionStart.bind(this));
    this.on("collisionend", this.onCollisionEnd.bind(this));
  }

  public onCollisionStart(event: CollisionStartEvent): void {
    this.activate(event);
  }

  public onCollisionEnd(event: CollisionEndEvent): void {
    this.deactivate(event);
  }

  public checkHide(ghost: Ghost): boolean {
    if (this.isActivated) {
      return this.collider.collide(ghost.collider).length > 0;
    } else {
      return false;
    }
  }

  private activate(event: CollisionStartEvent): void {
    if (event.other === this.player) {
      this.isActivated = true;
    }
  }

  private deactivate(event: CollisionEndEvent): void {
    if (event.other === this.player) {
      this.isActivated = false;
    }
  }
}