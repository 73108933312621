import {css} from "@emotion/react";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InputHTMLAttributes, ReactNode, forwardRef} from "react";
import {HelperText} from "src/components/common/helperText";
import {alpha, borderWidth, color, lineHeight, size} from "src/components/constants/constants";


export interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "css"> {
  /**
   * チェックボックスの上に表示するラベル。
   */
  label?: ReactNode;
  /**
   * チェックボックスの下に表示する説明文。
   */
  helperText?: ReactNode;
  /** */
  className?: string;
}

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    &[data-disabled="true"] {
      opacity: 0.5;
    }
  `,
  container: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  `,
  label: css`
    margin-inline-start: 0.5em;
    ${lineHeight(1.4)}
  `,
  original: css`
    width: 0rem;
    height: 0rem;
    opacity: 0;
  `,
  input: css`
    width: 1.1em;
    height: 1.1em;
    border-radius: ${size(1)};
    line-height: 1;
    border: solid ${borderWidth(1)} ${alpha(color("black"), 0.3)};
    background-color: ${color("white")};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    input:focus +& {
      border-color: ${color("primary", 5)};
      outline: solid ${borderWidth(1)} ${color("primary", 5)};
    }
    input:disabled +& {
      cursor: inherit;
    }
  `,
  icon: css`
    font-size: 90%;
    color: ${color("primary", 5)};
    display: none;
    transition: color 0.2s ease;
    .qr-checkbox:not([data-disabled="true"]):hover & {
      color: ${color("primary", 4)};
    }
    input:checked +* >& {
      display: block;
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
  label,
  helperText,
  className,
  ...inputProps
}, ref) => {
  return (
    <label className={["qr-checkbox", className].join(" ")} css={styles.root} data-disabled={inputProps.disabled}>
      <div css={styles.container}>
        <input type="checkbox" css={styles.original} ref={ref} {...inputProps}/>
        <div css={styles.input}>
          <FontAwesomeIcon css={styles.icon} icon={faCheck}/>
        </div>
        {!!label && (
          <div css={styles.label}>{label}</div>
        )}
      </div>
      {!!helperText && (
        <HelperText>{helperText}</HelperText>
      )}
    </label>
  );
});