import {Actor, CollisionType} from "excalibur";
import {TriggerMethod, TriggerType, withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";
import {withPlayerTrigger} from "src/ecs/playerTrigger/with";
import {MessageDialog} from "src/overlays";


export interface ShowMessageTriggerProperties {
  /**
   * 表示するメッセージ。
   */
  message: string;
  /**
   * トリガーの種類。
   * @defaultValue `"none"`
   */
  triggerType?: TriggerType;
  /**
   * トリガーを判定する方法。
   * @defaultValue `"touch"`
   */
  triggerMethod?: TriggerMethod;
  /**
   * これが `true` のときは、プレイヤーがこのエンティティから離れたときに自動的にメッセージを消します。
   * これが `false` のときは、メッセージウィンドウの閉じるボタンを押さない限りメッセージは消えません。
   * @defaultValue `false`
   */
  hideOnEnd?: boolean;
};

/**
 * プレイヤーがこのエンティティに対してアクションを起こしたときに、メッセージを表示します。
 * どんなアクションに反応するかは、カスタムプロパティで設定できます。
 * @category Builtin Entity
 */
export class ShowMessageTrigger extends withPlayerTrigger(withFieldEntity(Actor)) {
  private message: string;

  public constructor(configs: FieldEntityConfigs<ShowMessageTriggerProperties>) {
    super(configs);
    this.body.collisionType = CollisionType.Passive;
    this.message = configs.properties.message;
    this.setupEventListeners(configs.properties);
  }

  private setupEventListeners(properties: ShowMessageTriggerProperties): void {
    const triggerType = properties.triggerType ?? "none";
    const triggerMethod = properties.triggerMethod ?? "touch";
    const hideOnExit = properties.hideOnEnd ?? false;
    if (triggerMethod === "touch") {
      if (triggerType === "none") {
        this.on("playerTrigger.touchstart", this.showMessage.bind(this));
      } else {
        this.on("playerTrigger.touchpressed", this.showMessage.bind(this));
      }
      if (hideOnExit) {
        this.on("playerTrigger.touchend", this.hideMessage.bind(this));
      }
    } else {
      if (triggerType === "none") {
        this.on("playerTrigger.facestart", this.showMessage.bind(this));
      } else {
        this.on("playerTrigger.facepressed", this.showMessage.bind(this));
      }
      if (hideOnExit) {
        this.on("playerTrigger.faceend", this.hideMessage.bind(this));
      }
    }
  }

  private showMessage(): void {
    MessageDialog.propsSubject.update({isVisible: true, message: this.message});
  }

  private hideMessage(): void {
    MessageDialog.propsSubject.update({isVisible: false, message: null});
  }
}