import {css} from "@emotion/react";
import {ReactNode} from "react";
import {size, smartphone} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


interface DrawerHeaderProps extends CustomDataAttributes {
  addonNode?: ReactNode;
  className?: string;
  children?: ReactNode;
};

const styles = {
  root: css`
    margin-block-end: ${size(10)};
    column-gap: ${size(12)};
    font-size: ${size(4)};
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0; 
    z-index: 2;
  `,
  main: css`
    flex-grow: 1;
    flex-shrink: 1;
  `,
  addon: css`
    flex-grow: 0;
    flex-shrink: 0;
    ${smartphone()} {
      display: none;
    }
  `
};

/**
 * ドロワーのヘッダーです。
 * 子には `DrawerTitle` と `DrawerDescription` を 1 つずつ置いてください (省略も可能)。
 *
 * `addonNode` に要素を渡すことで、ヘッダーの右側に任意の要素を表示することができます。
 * ただし、`addonNode` はスマートフォンではスペースの都合上表示されません。
 * そのため、`addonNode` を指定した場合は、スマートフォンのときにも同様の要素を別の場所で表示するなどの処置を取ってください。
 * @group React Components
 * @category Common Component
 */
export const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  addonNode,
  className,
  children,
  ...data
}) => {
  return (
    <div css={styles.root} className={className} {...data}>
      <div css={styles.main}>
        {children}
      </div>
      {addonNode && (
        <div css={styles.addon}>
          {addonNode}
        </div>
      )}
    </div>
  );
};
