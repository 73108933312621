import {User} from "@baton8/quizium-lib-repositories";


/**
 * @group Enums
 * @category Chat
 */
export const ChatType = {
  /**
   * 同じセッションにいるプレイヤーに対してチャットを送ります。
   */
  Session: "session",
  /**
   * 同じサブセッションにいるプレイヤーに対してチャットを送ります。
   */
  SubSession: "subSession"
} as const;
/**
 * @group Enums
 * @category Chat
 */
export type ChatType = typeof ChatType[keyof typeof ChatType];

/**
 * @group Types
 * @category Chat
 */
export interface Chat {
  type: ChatType;
  message: string;
  user: User;
}