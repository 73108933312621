import {Chat, ChatType, global, listenChatSent, sendChat, unlistenChatSent} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faPaperPlaneTop} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import mousetrap from "mousetrap";
import {FormEvent, useCallback, useEffect, useRef, useState} from "react";
import SimpleBarCore from "simplebar-core";
import {Scroll} from "src/components/common/scroll";
import {alpha, borderWidth, color, lineHeight, size} from "src/components/constants/constants";
import {BlackPane} from "src/components/modules/blackPane";
import {CustomDataAttributes} from "src/modules/data";
import {useTranslation} from "src/modules/translation";
import {ChatView} from "./chatView";


interface Props extends CustomDataAttributes {
  className?: string;
};

const styles = {
  pane: css`
    row-gap: ${size(2)};
    display: flex;
    flex-direction: column;
  `,
  listScroll: css`
    flex-grow: 1;
  `,
  list: css`
    row-gap: ${size(2)};
    font-size: ${size(3)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
  form: css`
    column-gap: ${size(2)};
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  input: css`
    padding-block: 0.3em;
    padding-inline: 0.5em;
    font-size: ${size(3)};
    border-radius: ${size(1)};
    background-color: ${color("primary", 9)};
    flex-grow: 1;
  `,
  button: css`
    font-size: ${size(4)};
    color: ${color("primary", 3)};
    transition: color 0.2s ease;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    &:hover {
      color: ${color("primary", 2)};
    }
    &:focus-visible {
      outline: solid ${borderWidth(2)} ${alpha(color("primary", 3), 0.6)};
      outline-offset: ${borderWidth(1)};
    }
  `,
  instruction: css`
    ${lineHeight(1.4)}
  `
};

export const ChatPane: React.FC<Props> = ({
  className,
  ...data
}) => {
  const {trans} = useTranslation("bottomOverlay");

  const [chats, setChats] = useState<Array<Chat>>([]);

  const inputElementRef = useRef<HTMLInputElement>(null);
  const simpleBarRef = useRef<SimpleBarCore>(null);

  const sendComment = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const inputElement = inputElementRef.current;
    if (inputElement != null) {
      const message = inputElement.value;
      if (message) {
        await sendChat(ChatType.Session, message);
        setChats((chats) => [...chats, {type: ChatType.Session, message, user: global.user!}]);
      }
      inputElement.value = "";
      document.getElementById("screen")?.focus();
    }
  }, []);

  useEffect(() => {
    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement != null) {
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [chats]);

  useEffect(() => {
    const listener = (chat: Chat): void => {
      if (chat.user.id !== global.user?.id) {
        setChats((chats) => [...chats, chat]);
      }
    };
    listenChatSent(listener);
    return () => {
      unlistenChatSent(listener);
    };
  }, []);

  useEffect(() => {
    mousetrap.bind("/", (event) => {
      const inputElement = inputElementRef.current;
      if (inputElement != null) {
        inputElement.focus();
        inputElement.value = "";
      }
    });
    return () => {
      mousetrap.unbind("/");
    };
  }, []);

  return (
    <BlackPane css={styles.pane} className={className} {...data}>
      <Scroll css={styles.listScroll} ref={simpleBarRef}>
        <div css={styles.list}>
          {chats.length <= 0 && (
            <p css={styles.instruction}>{trans("chatInstruction")}</p>
          )}
          {chats.map((chat, index) => (
            <ChatView key={index} chat={chat}/>
          ))}
        </div>
      </Scroll>
      <form css={styles.form} onSubmit={sendComment}>
        <input css={styles.input} ref={inputElementRef}/>
        <button css={styles.button} type="submit" aria-label={trans("sendComment")}><FontAwesomeIcon icon={faPaperPlaneTop}/></button>
      </form>
    </BlackPane>
  );
};
