import {css} from "@emotion/react";
import mousetrap, {ExtendedKeyboardEvent} from "mousetrap";
import {ButtonHTMLAttributes, MouseEvent, ReactNode, forwardRef, useEffect} from "react";
import {alpha, boxShadow, color, size} from "src/components/constants/constants";


export interface CircleIconButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "css" | "type" | "children" | "onClick"> {
  /**
   * ボタンのカラースキーム。
   * @defaultValue `"primary"`
   */
  colorScheme?: "primary";
  /**
   * ボタンのスタイル。
   * - `"solid"` — ボタンの背景がカラースキームの色で塗りつぶされる
   * - `"outline"` — ボタンの背景が白
   * @defaultValue `"solid"`
   */
  variant?: "solid" | "outline";
  /**
   * テキストの左の表示するアイコン。
   */
  iconNode?: ReactNode;
  /**
   * ボタンの種類。
   * HTML と違ってデフォルト値が `"button"` になっているので、フォームの送信ボタンとして使う場合は明示的に `"submit"` を指定してください。
   * @defaultValue `"button"`
   */
  type?: "submit" | "reset" | "button";
  /**
   * ボタンを発火するショートカットキー。
   * Mousetrap のキー設定が利用できます。
   */
  hotkeys?: string | Array<string>;
  /** */
  onClick?: (event: MouseEvent<HTMLButtonElement> | ExtendedKeyboardEvent) => void;
  /** */
  className?: string;
}

const styles = {
  root: (colorScheme: NonNullable<CircleIconButtonProps["colorScheme"]>) => css`
    width: ${size(12)};
    height: ${size(12)};
    padding-inline: ${size(0)} !important;
    font-size: ${size(6)};
    box-shadow: ${boxShadow(alpha(color(colorScheme, 5), 0.2), 0.5)};
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    border-radius: 100em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all;
    &:hover:not(:disabled) {
      background-color: ${color(colorScheme, 4)};
    }
    &[data-variant="solid"] {
      color: ${color("white")};
      background-color: ${color(colorScheme, 5)};
      &:hover:not(:disabled) {
        background-color: ${color(colorScheme, 4)};
      }
    }
    &[data-variant="outline"] {
      color: ${color(colorScheme, 5)};
      background-color: ${color("white")};
      &:hover:not(:disabled) {
        background-color: ${color(colorScheme, 0)};
      }
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const CircleIconButton = forwardRef<HTMLButtonElement, CircleIconButtonProps>(({
  colorScheme = "primary",
  variant = "solid",
  iconNode,
  type = "button",
  disabled,
  hotkeys,
  onClick,
  className,
  ...props
}, ref) => {
  useEffect(() => {
    if (hotkeys != null) {
      mousetrap.bind(hotkeys, (event) => {
        onClick?.(event);
      });
      return () => {
        mousetrap.unbind(hotkeys);
      };
    } else {
      return undefined;
    }
  }, [hotkeys, onClick]);

  return (
    <button
      className={className}
      css={styles.root(colorScheme)}
      type={type}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
      data-variant={variant}
      {...props}
    >
      {iconNode}
    </button>
  );
});