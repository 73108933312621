import {global, socket, writeLog} from "@baton8/qroud-lib-repositories";
import {Engine} from "excalibur";
import {BehaviorSubject, Observable, distinctUntilChanged, pairwise} from "rxjs";
import {FieldEntityConfigs} from "src/ecs";
import {FieldEntity} from "src/entities/core/fieldEntity";
import {UpdatableSubject} from "src/modules/subject";


export type GameState = {[K in string]?: any} & {progress?: number};

export abstract class Controller<S extends GameState> extends FieldEntity {
  private timer: number;

  /**
   * ゲームステートを管理する UpdatableSubject です。
   * ゲームステートを更新する場合は、これの `next` メソッドや `update` メソッドを利用してください。
   */
  public readonly stateSubject: UpdatableSubject<S>;

  /**
   * ゲームステートが流れる Observable です。
   * 1 つ前のゲームステートと現在のゲームステートを同時に流します。
   * ステートの変更を検知して何らかの処理を行いたい場合は、これを購読してください。
   */
  public readonly stateObservable: Observable<[S, S]>;

  private readonly isPrimarySubject: BehaviorSubject<boolean>;

  /**
   * 自分がプライマリ (ゲームステート同期の代表と成るプレイヤー) かどうかが流れる Observable です。
   * これが `true` であるプレイヤーがもつゲームステートに、他のプレイヤー全員のゲームステートが同期されます。
   */
  public readonly isPrimaryObservable: Observable<boolean>;

  /**
   * ゲームステートの初期値です。
   * ゲームステートをリセットする場合は、これを `stateSubject.next` に渡してください。
   */
  public readonly initialSpec: S;

  public constructor(configs: FieldEntityConfigs<{[K in string]?: any}>, initialSpec: S) {
    super(configs);
    this.initialSpec = initialSpec;
    this.timer = 0;
    this.stateSubject = new UpdatableSubject(initialSpec);
    this.isPrimarySubject = new BehaviorSubject(false);
    this.stateObservable = this.stateSubject.pipe(pairwise());
    this.isPrimaryObservable = this.isPrimarySubject.pipe(distinctUntilChanged());
    this.setupEventHandlers();
  }

  private setupEventHandlers(): void {
    this.on("initialize", ({engine}) => {
      this.setupSocket(engine);
    });
    this.on("preupdate", ({engine, delta}) => {
      this.onUpdateState(engine, delta);
      this.sendSocket(delta);
    });
    this.on("kill", () => {
      this.cleanupSocket();
    });
    this.on("postkill", () => {
    });
  }

  private setupSocket(engine: Engine): void {
    const user = global.user;
    const sessionId = this.scene.session.id;
    const fieldGroupId = this.scene.field.fieldGroupId;

    if (user != null) {
      socket.emit("startGame", sessionId, fieldGroupId, user.id, (isPrimary) => {
        this.isPrimarySubject.next(isPrimary);
        writeLog("Controller", "startGame", {isPrimary});
      });
      socket.on("gameStateSent", (state) => {
        const prevState = this.state;
        if (prevState.progress == null || state.progress == null || prevState.progress <= state.progress) {
          this.stateSubject.next(state);
        }
      });
      socket.on("primaryReplaced", (primaryUserId) => {
        const isPrimary = user.id === primaryUserId;
        this.isPrimarySubject.next(isPrimary);
        writeLog("Controller", "primaryReplaced", {isPrimary});
      });
    } else {
      throw new Error("Not signed in");
    }
  }

  private cleanupSocket(): void {
    socket.off("gameStateSent");
    socket.off("primaryReplaced");
  }

  private sendSocket(delta: number): void {
    if (this.isPrimarySubject.value) {
      const user = global.user;
      if (user != null) {
        const interval = global.session?.settings.playerSocketInterval ?? Number.POSITIVE_INFINITY;
        this.timer += delta;
        if (this.timer > interval) {
          socket.emit("sendGameState", this.state, user.id);
          this.timer -= interval;
        }
      }
    }
  }

  /**
   * ステートを更新するタイミングで呼ばれるメソッドです。
   * 毎フレーム呼ばれます。
   * 必要ならサブクラスでオーバーライドしてください。
   * @param engine Excalibur ゲームエンジン
   * @param delta 前フレームとの差分時間 (ミリ秒単位)
   */
  protected onUpdateState(engine: Engine, delta: number): void {
  }

  /**
   * 現在のゲームステートを返します。
   * `stateSubject.value` のショートハンドです。
   */
  public get state(): S {
    return this.stateSubject.value;
  }

  /**
   * ゲームステートを更新します。
   * `stateSubject.next` のショートハンドです。
   */
  public set state(state: S) {
    this.stateSubject.next(state);
  }

  /**
   * 自分がプライマリ (ゲームステート同期の代表となるプレイヤー) かどうかを返します。
   * これが `true` であるプレイヤーがもつゲームステートに、他のプレイヤー全員のゲームステートが同期されます。
   */
  public get isPrimary(): boolean {
    return this.isPrimarySubject.value;
  }
}
