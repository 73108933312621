import {Api, AsyncApi, AsyncRawApi, QuiziumBackendErrorCode, QuiziumError, RawApi} from "@baton8/quizium-lib-repositories";
import axios, {AxiosError} from "axios";


export const createAsyncApi = <F extends AsyncRawApi, N extends string>(rawApi: F, apiName: N): AsyncApi<F, N> => {
  const api = Object.assign((async (...args) => {
    try {
      return await rawApi(...args);
    } catch (error) {
      if (error instanceof QuiziumError) {
        throw error;
      } else if (axios.isAxiosError(error)) {
        throw createQuiziumErrorFromAxios(error);
      } else {
        throw new QuiziumError("unknown", undefined, error);
      }
    }
  }) as F, {
    apiName
  });
  return api;
};

export const createApi = <F extends RawApi, N extends string>(rawApi: F, apiName: N): Api<F, N> => {
  const api = Object.assign(((...args) => {
    try {
      return rawApi(...args);
    } catch (error) {
      if (error instanceof QuiziumError) {
        throw error;
      } else if (axios.isAxiosError(error)) {
        throw createQuiziumErrorFromAxios(error);
      } else {
        throw new QuiziumError("unknown", undefined, error);
      }
    }
  }) as F, {
    apiName
  });
  return api;
};

const createQuiziumErrorFromAxios = (cause: AxiosError): QuiziumError => {
  const code = (() => {
    const data = cause.response?.data as any;
    if (data != null && typeof data === "object" && typeof data.message === "string") {
      const fullCode = data.message as string;
      if (fullCode.startsWith("quizium:")) {
        const code = fullCode.replace(/^quizium:/, "quizium") as QuiziumBackendErrorCode;
        return code;
      }
    } else if (cause.response?.status === 504) {
      return "quiziumTimeout";
    }
    return "unknown";
  })();
  const status = cause.response?.status;
  const error = new QuiziumError(code, status, cause);
  return error;
};
