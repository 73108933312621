import {css} from "@emotion/react";
import {faAnglesRight, faHandBackPointUp} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ReactNode, useCallback, useState} from "react";
import {CircleIconButton} from "src/components/common/circleIconButton";
import {size} from "src/components/constants/constants";
import {GameCover} from "src/components/modules/gameCover";
import {WhitePane} from "src/components/modules/whitePane";
import {useBehaviorSubject} from "src/modules/subject";
import {useTranslation} from "src/modules/translation";
import {isCompetitionSlideOpenSubject, isCompetitionSlideVisibleSubject} from "src/overlays/core/bottomOverlay/subject";


export interface DirectionSlideProps {
  /**
   * ルート要素の Z index。
   * @defaultValue `100`
   */
  zIndex?: number;
  /** */
  children?: ReactNode;
};

const styles = {
  cover: css`
    padding-block: ${size(4)};
    padding-inline: ${size(4)};
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  `,
  root: css`
    row-gap: ${size(4)};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `,
  paneWrapper: css`
    transform: translate(100%, 0%) translate(${size(6)}, 0rem);
    transition: transform 0.3s ease;
    &[data-open="true"] {
      transform: translate(0%, 0%) translate(${size(0)}, 0rem);
    }
    &[data-space="true"] {
      transform: translate(100%, 0%) translate(${size(6)}, 0rem);
      &[data-open="true"] {
        transform: translate(0%, 0%) translate(${size(-52)}, 0rem);
      }
    }
  `,
  pane: css`
    inline-size: ${size(48)};
    display: flex;
    flex-direction: column;
  `,
  button: css`
    transition: margin-inline-end 0.3s ease;
    &[data-space="true"] {
      margin-inline-end: ${size(16)};
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DirectionSlide: React.FC<DirectionSlideProps> = ({
  zIndex = 100 - 1,
  children
}) => {
  const {trans} = useTranslation("directionSlide");

  const isCompetitionSlideVisible = useBehaviorSubject(isCompetitionSlideVisibleSubject);
  const isCompetitionSlideOpen = useBehaviorSubject(isCompetitionSlideOpenSubject);

  const [isOpen, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((isOpen) => !isOpen);
  }, []);

  return (
    <GameCover css={styles.cover} zIndex={zIndex}>
      <div css={styles.root}>
        <div css={styles.paneWrapper} data-open={isOpen} data-space={isCompetitionSlideOpen}>
          <WhitePane css={styles.pane}>
            {children}
          </WhitePane>
        </div>
        <CircleIconButton
          css={styles.button}
          iconNode={<FontAwesomeIcon icon={isOpen ? faAnglesRight : faHandBackPointUp}/>}
          variant="outline"
          onClick={toggleOpen}
          aria-label={trans("show")}
          data-space={isCompetitionSlideVisible}
        />
      </div>
    </GameCover>
  );
};
