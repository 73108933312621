import {global, logout as rawLogout} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faArrowsRotate, faPersonWalking, faPlus, faShare, faSignOutAlt} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useCallback} from "react";
import {Button} from "src/components";
import {Heading} from "src/components/common/heading";
import {Scroll} from "src/components/common/scroll";
import {size, smartphone} from "src/components/constants/constants";
import {DrawerBody} from "src/components/modules/drawer";
import {useBehaviorSubject} from "src/modules/subject";
import {useTranslation} from "src/modules/translation";
import {CreateSessionDrawer} from "src/overlays/core/createSessionDrawer";
import {Menu} from "src/overlays/core/menu/menu";
import {SoundVolumePane} from "src/overlays/core/menu/soundVolumePane";
import {SelectSessionDrawer} from "src/overlays/core/selectSessionDrawer";
import {ShareSessionLinkDialog} from "src/overlays/core/shareSessionLinkDialog";
import {ChangeLocaleForm} from "./changeLocaleForm";
import {GameInfoView} from "./gameInfoView";
import {LegalInfoList} from "./legalInfoList";
import {MaterialList} from "./materialList";
import {MenuButton} from "./menuButton";
import {MenuFieldPane} from "./menuFieldPane";
import {MenuSessionPane} from "./menuSessionPane";


export interface MenuMainProps {
  /** */
  onLogout: () => unknown;
};

const styles = {
  body: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  scroll: css`
    font-size: ${size(4)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
  vertical: css`
    row-gap: ${size(6)};
    display: flex;
    flex-direction: column;
  `,
  horizontal: css`
    column-gap: ${size(8)};
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    ${smartphone()} {
      row-gap: ${size(6)};
      grid-template-columns: repeat(1, 1fr);
    }
  `,
  heading: css`
    margin-block-end: ${size(4)};
  `,
  buttonList: css`
    margin-block-start: ${size(3)};
    column-gap: ${size(4)};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `,
  button: css`
  `
};

/**
 * - **Inner Props**: {@link MenuMainProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const MenuMain: React.FC<MenuMainProps> = (({
  onLogout
}) => {
  const {trans} = useTranslation("menu");

  const session = useBehaviorSubject(global.sessionSubject);
  const field = useBehaviorSubject(global.fieldSubject);

  const changeSession = useCallback(() => {
    Menu.propsSubject.update({isOpen: false});
    setTimeout(() => {
      SelectSessionDrawer.propsSubject.update({isOpen: true});
    }, 300);
  }, []);

  const createSession = useCallback(() => {
    Menu.propsSubject.update({isOpen: false});
    setTimeout(() => {
      CreateSessionDrawer.propsSubject.update({isOpen: true});
    }, 300);
  }, []);

  const showLink = useCallback(() => {
    Menu.propsSubject.update({isOpen: false});
    setTimeout(() => {
      ShareSessionLinkDialog.propsSubject.update({isOpen: true});
    }, 300);
  }, []);

  const logout = useCallback(async () => {
    await rawLogout();
    onLogout();
  }, [onLogout]);

  return (
    <DrawerBody css={styles.body}>
      <Scroll css={styles.scroll} insertMargin={true}>
        <div css={styles.vertical}>
          <div css={styles.horizontal}>
            <section>
              <Heading css={styles.heading}>{trans("heading.session")}</Heading>
              <MenuSessionPane session={session}/>
              <div css={styles.buttonList}>
                <MenuButton css={styles.button} type="button" iconNode={<FontAwesomeIcon icon={faArrowsRotate}/>} onClick={changeSession}>{trans("changeSession")}</MenuButton>
                <MenuButton css={styles.button} type="button" iconNode={<FontAwesomeIcon icon={faPlus}/>} onClick={createSession}>{trans("createSession")}</MenuButton>
              </div>
            </section>
            <section>
              <Heading css={styles.heading}>{trans("heading.field")}</Heading>
              <MenuFieldPane field={field}/>
              <div css={styles.buttonList}>
                <MenuButton css={styles.button} type="button" iconNode={<FontAwesomeIcon icon={faPersonWalking}/>} disabled={true}>{trans("changeField")}</MenuButton>
                <MenuButton css={styles.button} type="button" iconNode={<FontAwesomeIcon icon={faShare}/>} onClick={showLink}>{trans("shareSessionLink")}</MenuButton>
              </div>
            </section>
          </div>
          <div css={styles.horizontal}>
            <section>
              <Heading css={styles.heading}>{trans("heading.language")}</Heading>
              <ChangeLocaleForm/>
            </section>
            <section>
              <Heading css={styles.heading}>{trans("heading.sound")}</Heading>
              <SoundVolumePane/>
            </section>
            <section>
              <Heading css={styles.heading}>{trans("heading.logout")}</Heading>
              <Button css={styles.button} type="button" iconNode={<FontAwesomeIcon icon={faSignOutAlt}/>} onClick={logout}>{trans("logout")}</Button>
            </section>
          </div>
          <div css={styles.horizontal}>
            <div css={styles.vertical}>
              <section>
                <Heading css={styles.heading}>{trans("heading.legalInfo")}</Heading>
                <LegalInfoList/>
              </section>
              <section>
                <Heading css={styles.heading}>{trans("heading.material")}</Heading>
                <MaterialList/>
              </section>
            </div>
            <section>
              <Heading css={styles.heading}>{trans("heading.gameInfo")}</Heading>
              <GameInfoView/>
            </section>
          </div>
        </div>
      </Scroll>
    </DrawerBody>
  );
});
