import {client} from "src/clients/client";
import {global} from "src/globals";
import {DetailedSession, Session, SessionId} from "src/types";
import {createAsyncApi} from "src/utils/api";


/**
 * @group API Wrappers
 * @category Session
 */
export const getSession = createAsyncApi(async (id: SessionId): Promise<Session> => {
  const res = await client.get(`/api/session/${id}`);
  return res.data.session;
}, "getSession");

/**
 * @group Types
 * @category Session
 */
export interface SessionQuery {
  limit?: number;
  skip?: number;
}

/**
 * @group API Wrappers
 * @category Session
 */
export const listSessions = createAsyncApi(async (query: SessionQuery): Promise<Array<DetailedSession>> => {
  const user = global.user;
  if (user != null) {
    const params = {limit: query.limit, skip: query.skip};
    const res = await client.get(`/api/session/user/${user.handle}`, {params});
    return res.data;
  } else {
    throw new Error("Not signed in");
  }
}, "listSessions");
