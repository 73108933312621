import {User, getMe, login, writeLog} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {FormEvent, useCallback, useState} from "react";
import {Button} from "src/components/common/button";
import {Heading} from "src/components/common/heading";
import {Input} from "src/components/common/input";
import {LinkButton} from "src/components/common/linkButton";
import {PasswordInput} from "src/components/common/passwordInput";
import {size} from "src/components/constants/constants";
import {useTranslation} from "src/modules/translation";
import {useToast} from "src/overlays/common/toast";
import {LoginFormCover} from "src/overlays/core/loginFormCover";
import {deleteUrlParam, getUrlParam} from "src/utils/url";


interface Props {
  onSuccess?: (user: User) => void;
  onMoveToRegister: () => void;
};

const styles = {
  root: css`
    max-width: ${size(128)};
    font-size: ${size(4)};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  `,
  form: css`
    margin-block-start: ${size(6)};
  `,
  inputList: css`
    row-gap: ${size(4)};
    display: flex;
    flex-direction: column;
  `,
  input: css`
  `,
  buttonList: css`
    margin-block-start: ${size(6)};
    row-gap: ${size(3)};
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  button: css`
    inline-size: ${size(40)};
  `
};

export const LoginForm: React.FC<Props> = ({
  onSuccess,
  onMoveToRegister
}) => {
  const {trans} = useTranslation("loginFormCover");

  const [email, setEmail] = useState(getUrlParam("handle") ?? "");
  const [password, setPassword] = useState(getUrlParam("password") ?? "");

  const [isLoading, setLoading] = useState(false);

  const showToast = useToast();

  const handleSuccess = useCallback((user: User) => {
    LoginFormCover.propsSubject.update({isVisible: false});
    deleteUrlParam("handle");
    deleteUrlParam("password");
    onSuccess?.(user);
  }, [onSuccess]);

  const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    try {
      await login(email, password);
      const me = await getMe();
      writeLog("LoginForm", "handleSubmit", me);
      handleSuccess(me);
    } catch (error) {
      showToast(trans("loginFailed"));
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [email, password, handleSuccess, showToast, trans]);

  return (
    <div css={styles.root}>
      <Heading>{trans("heading.login")}</Heading>
      <form css={styles.form} onSubmit={handleSubmit}>
        <div css={styles.inputList}>
          <Input
            css={styles.input}
            label={trans("email")}
            type="email"
            required={true}
            autoComplete="email"
            autoFocus={true}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <PasswordInput
            css={styles.input}
            label={trans("password")}
            type="password"
            required={true}
            autoComplete="current-password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <div css={styles.buttonList}>
          <Button css={styles.button} type="submit" isLoading={isLoading}>{trans("login")}</Button>
          <LinkButton type="button" onClick={onMoveToRegister}>{trans("moveToRegister")}</LinkButton>
        </div>
      </form>
    </div>
  );
};
