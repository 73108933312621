import {css} from "@emotion/react";
import {CSSProperties, ReactElement, useEffect, useRef, useState} from "react";
import {borderWidth, color, size} from "src/components/constants/constants";


export interface SegmentedButtonGroupProps {
  index: number;
  className?: string;
  children: Array<ReactElement>;
}

const styles = {
  root: css`
    margin-block: ${size(1)};
    margin-inline: ${size(0)};
    border-radius: ${size(1)};
    background-color: ${color("primary", 8)};
    border: solid ${borderWidth(1)} ${color("primary", 5)};
    line-height: 1;
    display: flex;
    position: relative;
    z-index: 0;
  `,
  highlight: css`
    border-radius: ${size(1)};
    width: 30%;
    height: 100%;
    background-color: ${color("primary", 4)};
    position: absolute;
    transition: width 0.2s ease, height 0.2s ease, left 0.2s ease, top 0.2s ease, background-color 0.2s ease;  // TODO: これ 0.2s?
    z-index: 1;
    &:hover {
      background-color: ${color("primary", 3)};
    }
  `,
  separator: css`
    margin-block: ${size(2)};
    border-inline-start: solid ${borderWidth(1)} ${color("primary", 5)};
  `
};

/**
 * 横に並べられた複数の項目のうち 1 つだけ有効にできるコントロールです。
 * 子には 1 個以上の `SegmentedButton` を指定してください。
 *
 * 典型的な利用方法は以下の通りです。
 * ```tsx
 * const [index, setIndex] = useState(0);
 * const node = (
 *   <SegmentedButtonGroup index={index}>
 *     <SegmentedButton onClick={() => setIndex(0)}>Button 1</SegmentedButton>
 *     <SegmentedButton onClick={() => setIndex(1)}>Button 2</SegmentedButton>
 *     <SegmentedButton onClick={() => setIndex(2)}>Button 3</SegmentedButton>
 *   </SegmentedButtonGroup>
 * );
 * ```
 * @deprecated
 * @group React Components
 * @category Common Component
 */
export const SegmentedButtonGroup: React.FC<SegmentedButtonGroupProps> = ({
  index,
  className,
  children
}) => {
  const rootElementRef = useRef<HTMLDivElement>(null);
  const [highlightStyle, setHighlightStyle] = useState<CSSProperties>({width: "0rem", height: "0rem", left: "0rem", top: "0rem", display: "none"});

  useEffect(() => {
    const updateHighlightStyle = (): void => {
      if (rootElementRef.current) {
        const rootElement = rootElementRef.current;
        const selectedElement = rootElement.children[index * 2 + 1] as HTMLDivElement;
        const width = `calc(${selectedElement.clientWidth}px + ${size(2)})`;
        const height = `calc(${selectedElement.clientHeight}px + ${size(2)})`;
        const left = `calc(${selectedElement.offsetLeft}px - ${size(1)})`;
        const top = `calc(${selectedElement.offsetTop}px - ${size(1)})`;
        setHighlightStyle({width, height, left, top, display: "block"});
      } else {
        setHighlightStyle({width: "0rem", height: "0rem", left: "0rem", top: "0rem", display: "none"});
      }
    };
    updateHighlightStyle();
    window.addEventListener("resize", updateHighlightStyle);
    return () => {
      window.removeEventListener("resize", updateHighlightStyle);
    };
  }, [index]);

  return (
    <div className={className} css={styles.root} ref={rootElementRef}>
      <div css={styles.highlight} style={highlightStyle}/>
      {Array.from({length: children.length * 2 - 1}).map((dummy, index) => index % 2 === 0 ? (
        children[index / 2]
      ) : (
        <div key={`separator-${index}`} css={styles.separator}/>
      ))}
    </div>
  );
};
