import {DetailedSession, Session} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faUser} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SingleLineText} from "src/components/common/singleLineText";
import {alpha, borderWidth, color, fontWeight, size} from "src/components/constants/constants";
import {DummyUserAvatar} from "src/components/modules/userView/dummyUserAvatar";
import {useTranslation} from "src/modules/translation";


export interface Props {
  session: Session | DetailedSession | null;
};

const styles = {
  root: css`
    block-size: ${size(20)};
    padding-block: ${size(3)};
    padding-inline: ${size(3)};
    border-radius: ${size(2)};
    background-color: ${color("white")};
    border: solid ${borderWidth(1)} ${alpha(color("primary", 5), 0.3)};
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
  `,
  name: css`
    font-size: ${size(5)};
    font-weight: ${fontWeight("bold")};
    flex-grow: 1;
    flex-shrink: 1;
  `,
  right: css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  playerCountContainer: css`
    display: flex;
    align-items: center;
    opacity: 0.6;
  `,
  playerCountIcon: css`
    margin-inline-end: 0.4em;
    font-size: ${size(3)};
  `,
  playerCount: css`
    font-size: ${size(4)};
  `,
  user: css`
    margin-block-start: ${size(1)};
    display: flex;
    & >* +* {
      margin-inline-start: -0.5em;
    }
  `
};

/**
 * - **Inner Props**: {@link Props}
 * @group React Components
 * @category Builtin Overlay
 */
export const MenuSessionPane: React.FC<Props> = ({
  session
}) => {
  const {trans} = useTranslation("menu");

  return session != null && (
    <div css={styles.root}>
      <SingleLineText css={styles.name}>
        {session.id !== "public" ? session.name : trans("officialSessionName")}
      </SingleLineText>
      <div css={styles.right}>
        <div css={styles.playerCountContainer}>
          <div css={styles.playerCountIcon}>
            <FontAwesomeIcon icon={faUser}/>
          </div>
          <div css={styles.playerCount}>
            {"playerCount" in session ? session.playerCount : "?"}
          </div>
        </div>
        <div css={styles.user}>
          {Array.from({length: 5}).map((dummy, index) => (
            <DummyUserAvatar key={index}/>
          ))}
        </div>
      </div>
    </div>
  );
};