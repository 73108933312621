import {css} from "@emotion/react";
import {faO, faXmark} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {alpha, color, fontWeight, size} from "src/components/constants/constants";
import {ScoreView} from "src/components/modules/scoreView";
import {CustomDataAttributes} from "src/modules/data";
import {useTranslation} from "src/modules/translation";


interface UserRecordViewProps extends CustomDataAttributes {
  aggregationTags: [string, string?, string?];
  points: [number, number?, number?];
  answerCounts: {correct: number, incorrect: number};
  className?: string;
};

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `,
  score: css`
    row-gap: ${size(0.5)};
    display: flex;
    flex-direction: column;
    text-align: end;
  `,
  scorePrimary: css`
    font-size: ${size(6)};
    font-weight: ${fontWeight("bold")};
  `,
  scoreSecondary: css`
    font-size: ${size(3)};
  `,
  correctnessList: css`
    margin-block-start: ${size(1)};
    padding-block: 0.2em;
    padding-inline: 0.4em;
    font-size: ${size(3)};
    column-gap: ${size(2)};
    border-radius: ${size(1)};
    background-color: ${alpha(color("primary", 5), 0.2)};
    display: flex;
    align-items: center;
  `,
  correctness: css`
    font-size: ${size(3)};
    display: flex;
    align-items: center;
  `,
  correctnessNumber: css`
    margin-inline-end: 0.25em;
  `,
  correctnessType: css`
    font-size: 80%;
  `
};

/**
 * ユーザーのゲーム成績を表示します。
 * 以下の情報が表示されます。
 * - 順位
 * - スコア
 * - 正答数と誤答数
 * @group React Components
 * @category Common Component
 */
export const UserRecordView: React.FC<UserRecordViewProps> = ({
  aggregationTags,
  points,
  answerCounts,
  className,
  ...data
}) => {
  const {transNumber} = useTranslation("userStatusView");

  return (
    <div css={styles.root} className={className} {...data}>
      <div css={styles.score}>
        <div css={styles.scorePrimary}>
          <ScoreView aggregationTag={aggregationTags[0]} point={points[0]}/>
        </div>
        {(aggregationTags[1] != null && points[1] != null) && (
          <div css={styles.scoreSecondary}>
            <ScoreView aggregationTag={aggregationTags[1]} point={points[1]}/>
          </div>
        )}
      </div>
      <div css={styles.correctnessList}>
        <div css={styles.correctness}>
          <div css={styles.correctnessNumber}>{transNumber(answerCounts.correct)}</div>
          <div css={styles.correctnessType}><FontAwesomeIcon icon={faO}/></div>
        </div>
        <div css={styles.correctness}>
          <div css={styles.correctnessNumber}>{transNumber(answerCounts.incorrect)}</div>
          <div css={styles.correctnessType}><FontAwesomeIcon icon={faXmark}/></div>
        </div>
      </div>
    </div>
  );
};