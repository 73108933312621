import {EntityConstructor, FieldGroupId, FieldId, Field as FieldInterface, writeLog} from "@baton8/qroud-lib-repositories";
import {FieldEntityComponent, FieldEntityProperties} from "@baton8/qroud-lib-resources";
import {smartphone} from "@baton8/qroud-lib-resources";
import {TiledMapResource, TiledObject} from "@excaliburjs/plugin-tiled";
import {Actor, BoundingBox, CollisionType, Entity, GraphicsComponent, Scene, TileMap} from "excalibur";
import pathUtil from "path-browserify";
import {GhostGroup} from "src/game/entities/ghostGroup";
import {Player} from "src/game/entities/player";
import {MainScene} from "src/game/scenes/main";


type FieldInitializeOptions = {
  player: Player,
  ghostGroup: GhostGroup
};

export type RenderingOrder = "above" | "below";

export class Field extends TiledMapResource implements FieldInterface {
  public readonly id: FieldId;
  public name: string;
  public fieldGroupId: FieldGroupId;
  public redirectFieldId: FieldId | undefined;

  private readonly entityConstructors: Array<EntityConstructor<Entity>>;

  private readonly entitiesByObjectId: Map<number, Entity>;
  private readonly entitiesByConstructor: Map<EntityConstructor<Entity>, Array<Entity>>;

  private rootActor: Actor;
  private observer?: ResizeObserver;

  public constructor(id: string, entityConstructors: Array<EntityConstructor<Entity>>) {
    super(`assets/maps/${id}.tmx`, {
      startingLayerZIndex: -20
    });
    this.id = id;
    this.name = "";
    this.fieldGroupId = "";
    this.entityConstructors = entityConstructors;
    this.rootActor = new Actor({collisionType: CollisionType.PreventCollision});
    this.entitiesByObjectId = new Map();
    this.entitiesByConstructor = new Map();
    this.setupPath();
  }

  /**
   * マップの様々なデータを初期化します。
   * マップをシーンに追加した直後に必ず呼んでください。
   */
  public initialize(scene: MainScene, options: FieldInitializeOptions): void {
    this.addSelf(scene);
    this.loadFieldProperties(scene);
    this.hideCollisionLayers();
    this.setupLayerRenderingOrders();
    this.setupCameraBounds(scene);
    this.loadFieldEntities(scene, options);
    this.addFieldEntities(scene, options);
    this.observeResize(scene);
    scene.confirmMoveField();
  }

  public reuse(scene: MainScene, options: FieldInitializeOptions): void {
    this.loadFieldProperties(scene);
    this.hideCollisionLayers();
    this.setupLayerRenderingOrders();
    this.setupCameraBounds(scene);
    this.loadFieldEntities(scene, options);
    this.addFieldEntities(scene, options);
    this.observeResize(scene);
    scene.confirmMoveField();
  }

  /**
   * マップの様々なデータを破棄します。
   * マップをシーンから削除する直前に必ず呼んでください。
   */
  public finalize(scene: MainScene): void {
    this.killSelf(scene);
    this.unobserveResize();
  }

  private addSelf(scene: Scene): void {
    const layers = this.getTileMapLayers();
    for (const layer of layers) {
      this.rootActor.addChild(layer);
    }
    scene.add(this.rootActor);
    this.useSolidLayers();
  }

  private killSelf(scene: Scene): void {
    this.rootActor.kill();
  }

  private setupPath(): void {
    this.convertPath = (originPath, relativePath) => {
      const extname = pathUtil.extname(originPath);
      if (extname === ".tsx") {
        const convertedPath = pathUtil.join("assets/maps", pathUtil.dirname(originPath), relativePath);
        return convertedPath;
      } else {
        const convertedPath = pathUtil.join("", pathUtil.dirname(originPath), relativePath);
        return convertedPath;
      }
    };
  }

  private loadFieldProperties(scene: MainScene): void {
    const name = this.getFieldProperty<string>("name") ?? `#${this.id}`;
    const fieldGroupId = this.getFieldProperty<string>("group") ?? `$unique$${this.id}`;
    scene.fieldGroup.changeFieldGroup(fieldGroupId);

    this.name = name;
    this.fieldGroupId = fieldGroupId;
    writeLog("Field", "loadFieldProperties", {name, fieldGroupId});
  }

  private hideCollisionLayers(): void {
    const layerInfos = this.getLayerInfosWithProperty<boolean>("solid");
    for (const {layer} of layerInfos) {
      layer.get(GraphicsComponent)!.visible = false;
    }
  }

  private setupLayerRenderingOrders(): void {
    const layerInfos = this.getLayerInfosWithProperty<RenderingOrder>("renderingOrder");
    for (const {layer, value} of layerInfos) {
      switch (value) {
      case "above":
        layer.z = 20;
        break;
      case "below":
        break;
      default:
        break;
      }
    }
  }

  private setupCameraBounds(scene: Scene): void {
    const boundingBox = this.boundingBox;
    scene.camera.strategy.limitCameraBounds(boundingBox);
  }

  private loadFieldEntities(scene: MainScene, options: FieldInitializeOptions): void {
    const entityConstructorMap = new Map<string, EntityConstructor<Entity>>();
    for (const constructor of this.entityConstructors) {
      entityConstructorMap.set(constructor.name, constructor);
    }

    const fieldGroup = scene.fieldGroup;
    if (!fieldGroup.isLoaded(this.id)) {
      const objectLayers = this.data.getExcaliburObjects();
      for (const objectLayer of objectLayers) {
        for (const object of objectLayer.objects) {
          const name = object.class ?? object.type ?? "";
          // 指定された名前のエンティティクラスがなかった場合は Actor を使う
          // 移動先の指定などに空のオブジェクトを指定するというユースケースがあるためエラーにしない
          const EntityConstructor = entityConstructorMap.get(name) ?? Actor;
          const properties = getProperties(object);
          const configs = {
            objectId: object.id,
            x: object.x + object.width! / 2,
            y: object.y + object.height! / 2,
            width: object.width!,
            height: object.height!,
            properties,
            name: object.class ?? object.type ?? "",
            player: options.player,
            ghostGroup: options.ghostGroup,
            field: this
          };
          const entity = new EntityConstructor(configs);
          entity.addComponent(new FieldEntityComponent(configs));
          fieldGroup.registerEntity(this, entity, properties.preserve);
        }
      }
    }
  }

  private addFieldEntities(scene: MainScene, options: FieldInitializeOptions): void {
    const fieldGroup = scene.fieldGroup;
    const entities = fieldGroup.getEntities(this.id);
    for (const entity of entities) {
      const fieldEntity = entity.get(FieldEntityComponent);
      if (fieldEntity == null) {
        throw new Error("Entity does not have FieldEntityComponent");
      }
      if (fieldEntity.originalFieldId === this.id) {
        this.entitiesByObjectId.set(fieldEntity.objectId, entity);
      }
      pushToMap(this.entitiesByConstructor, entity.constructor, entity);
    }
    fieldGroup.addEntities(this);
  }

  private observeResize(scene: MainScene): void {
    const containerElement = document.getElementById("screen-container") as HTMLElement;
    const observer = new ResizeObserver(() => this.applyResolution(scene, containerElement));
    observer.observe(containerElement);
    this.applyResolution(scene, containerElement);
    this.observer = observer;
  }

  private unobserveResize(): void {
    this.observer?.disconnect();
    this.observer = undefined;
  }

  /**
   * 指定された名前のプロパティをもつレイヤーを、プロパティの値とともに返します。
   */
  private getLayerInfosWithProperty<T>(name: string): Array<{layer: TileMap, value: T, order: number}> {
    const orders = [];
    for (const rawLayer of this.data.layers ?? []) {
      const property = rawLayer.getProperty<T>(name);
      const value = property?.value;
      const layer = this.layers?.[rawLayer.order];
      if (value && layer) {
        orders.push({layer, value, order: rawLayer.order});
      }
    }
    return orders;
  }

  private getFieldProperty<T>(name: string): T | undefined {
    const rawProperties = (this.data.rawMap.properties as any)?.property;
    const properties = rawProperties == null ? [] : Array.isArray(rawProperties) ? rawProperties : [rawProperties];
    const value = properties.find((property) => property.name === name)?.value;
    return value;
  }

  private getResolution(containerElement: HTMLElement): {width: number, height: number} {
    const fieldWidth = this.data.width * this.data.tileWidth;
    const fieldHeight = this.data.height * this.data.tileHeight;

    const containerWidth = containerElement.clientWidth;
    const containerHeight = containerElement.clientHeight;

    const isSmartphone = window.matchMedia(smartphone().replace("@media ", "")).matches;
    const standardFactor = isSmartphone ? 20 * 32 / containerWidth : 30 * 32 / containerHeight; // スマートフォンでは横 20 タイル固定, パソコンでは縦 30 タイル固定
    const factor = Math.min(fieldWidth / containerWidth, fieldHeight / containerHeight, standardFactor);

    const width = containerElement.clientWidth * factor;
    const height = containerElement.clientHeight * factor;
    return {width, height};
  }

  private applyResolution(scene: Scene, containerElement: HTMLElement): void {
    const resolution = this.getResolution(containerElement);
    scene.engine.screen.resolution = resolution;
    scene.engine.screen.applyResolutionAndViewport();
  }

  public getRedirectFieldId(): string | undefined {
    const redirectFieldId = this.getFieldProperty<string>("redirectFieldId");
    return redirectFieldId;
  }

  public getEntities<E extends Entity>(clazz: EntityConstructor<E>): Array<E> {
    return (this.entitiesByConstructor.get(clazz) ?? []) as Array<E>;
  }

  public getFirstEntity<E extends Entity>(clazz: EntityConstructor<E>): E | undefined {
    return (this.entitiesByConstructor.get(clazz) ?? [])[0] as E;
  }

  public getEntityFromObjectId<E extends Entity>(objectId: number): E | undefined {
    return this.entitiesByObjectId.get(objectId) as E | undefined;
  }

  public get boundingBox(): BoundingBox {
    return new BoundingBox(0, 0, this.data.width * this.data.tileWidth, this.data.height * this.data.tileHeight);
  }
}

const getProperties = <P extends FieldEntityProperties = FieldEntityProperties>(object: TiledObject): P => {
  const properties = {} as any;
  for (const rawProperty of object.properties) {
    const paths = rawProperty.name.split(/\s*\.\s*/);
    let current = properties;
    for (let index = 0 ; index < paths.length - 1 ; index ++) {
      const path = paths[index];
      if (!(path in current)) {
        current[path] = {};
      }
      current = current[path];
    }
    current[paths[paths.length - 1]] = rawProperty.value;
  }
  return properties;
};

const pushToMap = <K, T>(map: Map<K, Array<T>>, key: K, item: T): void => {
  let items = map.get(key);
  if (items == null) {
    items = [];
    map.set(key, items);
  }
  items.push(item);
};
