import {css} from "@emotion/react";
import {FONT_FAMILY, alpha, color, fontWeight, smartphone} from "src/components/constants/constants";


export const globalStyle = css`
  html {
    font-family: ${FONT_FAMILY};
    font-size: min(50vw, 50vh);
    font-weight: ${fontWeight("normal")};
    font-feature-settings: "palt" 1, "pkna" 1, "lnum" 1, "kern" 1 !important;
    color: ${color("blackText")};
    background-color: ${color("background")};
    line-height: 1;
    overflow: hidden;
    ${smartphone()} {
      font-size: min(85vw, 85vh);
    }
  }
  body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    user-select: none;
    -webkit-user-select: none;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 0.003em;
    -webkit-text-size-adjust: 100%;
  }
  *:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
    all: unset;
    display: revert;
    min-block-size: 0rem;
    min-inline-size: 0rem;
    background-origin: border-box;
    scroll-behavior: smooth;
  }
  input,
  textarea {
    user-select: auto !important;
    -webkit-user-select : auto !important;
  }
  #root {
    width: 100%;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    z-index: 0;
  }
  #screen {
    outline: none;
  }
  .simplebar-scrollbar:before {
    background-color: ${alpha(color("primary", 5), 0.6)};
  }
`;