import {css} from "@emotion/react";
import {ReactNode} from "react";
import {SingleLineText} from "src/components/common/singleLineText";
import {color, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


interface DrawerHeadingProps extends CustomDataAttributes {
  className?: string;
  children?: ReactNode;
};

const styles = {
  root: css`
    margin-block-start: ${size(2)};
    font-size: ${size(4)};
    color: ${color("primary", 6)};
    opacity: 0.9;
    letter-spacing: 0.05em;
    transform: skew(-3deg, 0deg);
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DrawerDescription: React.FC<DrawerHeadingProps> = ({
  className,
  children,
  ...data
}) => {
  return (
    <SingleLineText css={styles.root} className={className} {...data}>
      {children}
    </SingleLineText>
  );
};
