import {css} from "@emotion/react";
import {faAnglesDown, faAnglesRight} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useContext} from "react";
import {CircleIconButton} from "src/components/common/circleIconButton";
import {size, smartphone} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";
import {useTranslation} from "src/modules/translation";
import {context} from "./context";


interface DrawerCloseButtonProps extends CustomDataAttributes {
  className?: string;
};

const styles = {
  button: css`
    inset-block-start: ${size(4)};
    inset-inline-start: ${size(-16)};
    opacity: 0;
    cursor: pointer;
    position: absolute;
    pointer-events: none;
    &[data-open="true"] {
      opacity: 1;
      pointer-events: all;
    }
    ${smartphone()} {
      inset-block-start: ${size(-16)};
      inset-inline-start: ${size(4)};
    }
  `,
  iconDesktop: css`
    ${smartphone()} {
      display: none;
    }
  `,
  iconSmartphone: css`
    display: none;
    ${smartphone()} {
      display: unset;
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DrawerCloseButton: React.FC<DrawerCloseButtonProps> = ({
  className,
  ...data
}) => {
  const {trans} = useTranslation("drawer");

  const {isOpen, onClose} = useContext(context);

  return (
    <CircleIconButton
      css={styles.button}
      className={className}
      variant="outline"
      onClick={onClose}
      data-open={isOpen}
      aria-label={trans("close")}
      iconNode={[
        <FontAwesomeIcon key="desktop" css={styles.iconDesktop} icon={faAnglesRight}/>,
        <FontAwesomeIcon key="smartphone" css={styles.iconSmartphone} icon={faAnglesDown}/>
      ]}
      {...data}
    />
  );
};
