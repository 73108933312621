import {socket} from "src/clients/socket.js";
import {global} from "src/globals";
import {FieldGroupId, PlayerInfo, PlayerStore, SessionId, UserId} from "src/types";
import {createApi, createAsyncApi} from "src/utils/api";


/**
 * ゲームを開始します。
 * ゲームマップに入ったときに必ず一度だけ呼んでください。
 * ただし、`@baton8/qroud-lib-resources` パッケージが提供する `Controller` クラスが自動的にこれを呼ぶようになっているので、ゲーム実装者がこれを直接利用する必要は基本的にありません。
 * @param sessionId セッション ID
 * @param fieldGroupId マップグループ
 * @returns 自身がプライマリの場合 `true`
 * @group API Wrappers
 * @category Game
 */
export const startGame = createAsyncApi((sessionId: SessionId, fieldGroupId: FieldGroupId): Promise<boolean> => {
  const user = global.user;
  return new Promise((resolve, reject) => {
    if (user != null) {
      socket.emit("startGame", sessionId, fieldGroupId, user.id, (isPrimary) => {
        resolve(isPrimary);
      });
    } else {
      throw new Error("Not signed in");
    }
  });
}, "startGame");

/**
 * 同じセッショングループの同じマップグループにいる他のプレイヤーにゲームステートを送信します。
 * ただし、ゲームステートの同期処理は `@baton8/qroud-lib-resources` パッケージが提供する `Controller` クラスが自動的に行うので、ゲーム実装者がこれを直接利用する必要は基本的にありません。
 * @param state 送信するゲームステート
 * @group API Wrappers
 * @category Game
 */
export const sendGameState = createApi(<S>(state: S): void => {
  const user = global.user;
  if (user != null) {
    socket.emit("sendGameMessage", state, user.id);
  } else {
    throw new Error("Not signed in");
  }
}, "sendGameState");

/**
 * 他のプレイヤーからゲームステートが送られてきたときに実行するリスナーを設定します。
 * ただし、ゲームステートの同期処理は `@baton8/qroud-lib-resources` パッケージが提供する `Controller` クラスが自動的に行うので、ゲーム実装者がこれを直接利用する必要は基本的にありません。
 * @param listener リスナー
 * @group API Wrappers
 * @category Game
 */
export const listenGameStateSent = <S>(listener: (state: S) => unknown): void => {
  socket.on("gameStateSent", listener);
};

/**
 * @group API Wrappers
 * @category Game
 */
export const unlistenGameStateSent = <S>(listener?: (state: S) => unknown): void => {
  socket.off("gameStateSent", listener);
};

/**
 * @group API Wrappers
 * @category Game
 */
export const listenPrimaryReplaced = (listener: (primarySocketId: string) => unknown): void => {
  socket.on("gameStateSent", listener);
};

/**
 * @group API Wrappers
 * @category Game
 */
export const unlistenPrimaryReplaced = (listener?: (primarySocketId: string) => unknown): void => {
  socket.off("gameStateSent", listener);
};

/**
 * @group Types
 * @category Game
 */
export interface SendGameMessageOptions {
  /**
   * 送信先のプレイヤー ID の配列。
   * 省略した場合は、同じセッショングループの同じマップグループにいる他のプレイヤー全員にメッセージが送信されます。
   * また、ここに指定したプレイヤーが自身と同じマップにいない場合は、単に無視されてメッセージは送信されません。
   */
  toIds?: Array<string>;
  /**
   * ゲームメッセージを自分自身にも送信するかどうか。
   * `toIds` を指定している場合、この設定は無視されます。
   * @defaultValue `false`
   */
  includeSelf?: boolean;
};

/**
 * 同じセッショングループの同じマップグループにいる他のプレイヤーにゲームメッセージを送信します。
 * プレイヤーが何らかのアクションを行ったことを他のプレイヤーに知らせたり、ゲームの司会がその他のプレイヤーにゲームイベントを変えるよう指示したりするのに利用できます。
 * @param message 送信するオブジェクト
 * @group API Wrappers
 * @category Game
 */
export const sendGameMessage = createApi(<M>(message: M, options?: SendGameMessageOptions): void => {
  const user = global.user;
  if (user != null) {
    socket.emit("sendGameMessage", message, user.id, options);
  } else {
    throw new Error("Not signed in");
  }
}, "sendGameMessage");

/**
 * 他のプレイヤーからゲームメッセージが送られてきたときに実行するリスナーを設定します。
 * @param listener リスナー
 * @group API Wrappers
 * @category Game
 */
export const listenGameMessageSent = (listener: <M>(message: M, from: PlayerInfo) => unknown): void => {
  socket.on("gameMessageSent", listener);
};

/**
 * @group API Wrappers
 * @category Game
 */
export const unlistenGameMessageSent = (listener?: <M>(message: M, from: PlayerInfo) => unknown): void => {
  socket.off("gameMessageSent", listener);
};

/**
 * プレイヤーストアを取得します。
 * 自分と同じマップグループにいないプレイヤーのプレイヤーストアは取得できず、代わりに `undefined` が返されます。
 * @param userId ユーザー ID (省略時は自分)
 * @returns プレイヤーストア (存在しなかった場合は `undefined`)
 * @group API Wrappers
 * @category Game
 */
export const getPlayerStore = createAsyncApi(<S extends PlayerStore>(userId?: UserId): Promise<S | undefined> => {
  const actualUserId = userId ?? global.user?.id;
  return new Promise((resolve, reject) => {
    if (actualUserId != null) {
      socket.emit("getPlayerStore", actualUserId, (playerStore) => {
        resolve(playerStore as S);
      });
    } else {
      throw new Error("Not signed in");
    }
  });
}, "getPlayerStore");

/**
 * 自分と同じマップグループにいるプレイヤー全員のプレイヤーストアを取得します。
 * @returns プレイヤーのユーザー ID とプレイヤーストアのペアの配列
 * @group API Wrappers
 * @category Game
 */
export const getPlayerStoresInSameFieldGroup = createAsyncApi(<S extends PlayerStore>(): Promise<Array<{id: UserId, store: S}>> => {
  const user = global.user;
  return new Promise((resolve, reject) => {
    if (user != null) {
      socket.emit("getPlayerStoresInSameField", user.id, (playerStoreSpecs) => {
        resolve(playerStoreSpecs as never);
      });
    } else {
      throw new Error("Not signed in");
    }
  });
}, "getPlayerStoresInSameFieldGroup");

/**
 * プレイヤーストアを設定します。
 * 引数に指定されたものにプレイヤーストアを上書きします。
 * 自分と同じフィールドグループにいないプレイヤーのプレイヤーストアは設定できません (単に無視されます)。
 * @param userId ユーザー ID
 * @param playerStore 設定するプレイヤーストア
 * @group API Wrappers
 * @category Game
 */
export const setPlayerStore = createAsyncApi(<S extends PlayerStore>(userId: UserId, playerStore: S): Promise<void> => {
  return new Promise((resolve, reject) => {
    socket.emit("setPlayerStore", userId, playerStore, () => {
      resolve();
    });
  });
}, "setPlayerStore");

/**
 * 自分のプレイヤーストアを設定します。
 * 引数に指定されたものにプレイヤーストアを上書きします。
 * @param playerStore 設定するプレイヤーストア
 * @group API Wrappers
 * @category Game
 */
export const setMyPlayerStore = createAsyncApi(<S extends PlayerStore>(playerStore: S): Promise<void> => {
  const user = global.user;
  return new Promise((resolve, reject) => {
    if (user != null) {
      socket.emit("setPlayerStore", user.id, playerStore, () => {
        resolve();
      });
    } else {
      reject(new Error("Not signed in"));
    }
  });
}, "setMyPlayerStore");

/**
 * プレイヤーストアを更新します。
 * 引数に指定されたキーの値のみプレイヤーストアを上書きします。
 * 自分と同じフィールドグループにいないプレイヤーのプレイヤーストアは上書きできません (単に無視されます)。
 * @param userId ユーザー ID
 * @param playerStore 設定するプレイヤーストア
 * @group API Wrappers
 * @category Game
 */
export const updatePlayerStore = createAsyncApi(<S extends PlayerStore>(userId: UserId, playerStore: Partial<S>): Promise<void> => {
  return new Promise((resolve, reject) => {
    socket.emit("updatePlayerStore", userId, playerStore, () => {
      resolve();
    });
  });
}, "updatePlayerStore");

/**
 * 自分のプレイヤーストアを更新します。
 * 引数に指定されたキーの値のみプレイヤーストアを上書きします。
 * @param playerStore 設定するプレイヤーストア
 * @group API Wrappers
 * @category Game
 */
export const updateMyPlayerStore = createAsyncApi(<S extends PlayerStore>(playerStore: Partial<S>): Promise<void> => {
  const user = global.user;
  return new Promise((resolve, reject) => {
    if (user != null) {
      socket.emit("updatePlayerStore", user.id, playerStore, () => {
        resolve();
      });
    } else {
      reject(new Error("Not signed in"));
    }
  });
}, "updatePlayerStore");

/**
 * プレイヤーストアを削除します。
 * 自分と同じフィールドグループにいないプレイヤーのプレイヤーストアは削除できません (単に無視されます)。
 * @param userId ユーザーのソケット ID
 * @group API Wrappers
 * @category Game
 */
export const deletePlayerStore = createAsyncApi((userId: UserId): Promise<void> => {
  const actualUserId = userId ?? global.user?.id;
  return new Promise((resolve, reject) => {
    if (actualUserId != null) {
      socket.emit("deletePlayerStore", actualUserId, () => {
        resolve();
      });
    } else {
      reject(new Error("Not signed in"));
    }
  });
}, "deletePlayerStore");

/**
 * 自分のプレイヤーストアを削除します。
 * @group API Wrappers
 * @category Game
 */
export const deleteMyPlayerStore = createAsyncApi((): Promise<void> => {
  const userId = global.user?.id;
  return new Promise((resolve, reject) => {
    if (userId != null) {
      socket.emit("deletePlayerStore", userId, () => {
        resolve();
      });
    } else {
      reject(new Error("Not signed in"));
    }
  });
}, "deleteMyPlayerStore");