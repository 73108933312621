import {PlayableDeck as Deck} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {ChangeEvent, Dispatch, MutableRefObject, SetStateAction, useCallback} from "react";
import {Button} from "src/components/common/button";
import {Heading} from "src/components/common/heading";
import {Input} from "src/components/common/input";
import {size, smartphone} from "src/components/constants/constants";
import {DeckListbox} from "src/components/modules/deckListbox";
import {DrawerBody, DrawerDescription, DrawerHeader, DrawerTitle} from "src/components/modules/drawer";
import {DrawerFooter} from "src/components/modules/drawer/drawerFooter";
import {useTranslation} from "src/modules/translation";


interface Props {
  deck: Deck | null;
  setDeck: Dispatch<SetStateAction<Deck | null>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  isNameChangedRef: MutableRefObject<boolean>;
  setMode: Dispatch<SetStateAction<string>>;
};

const styles = {
  drawer: css`
  `,
  body: css`
    column-gap: ${size(8)};
    row-gap: ${size(6)};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    flex-grow: 1;
    flex-shrink: 1;
    ${smartphone()} {
      grid-template-columns: 1fr;
      grid-template-rows: max-content max-content;
    }
  `,
  heading: css`
    margin-block-end: ${size(4)};
  `,
  link: css`
    margin-block-end: ${size(3)};
  `
};

export const HoldCompetitionDrawerMainPart: React.FC<Props> = ({
  deck,
  setDeck,
  name,
  setName,
  isLoading,
  isNameChangedRef,
  setMode
}) => {
  const {trans} = useTranslation("holdCompetitionDrawer");

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    isNameChangedRef.current = true;
  }, [setName, isNameChangedRef]);

  const handleDeckChange = useCallback((deck: Deck | null) => {
    setDeck(deck);
    if (!isNameChangedRef.current && deck != null) {
      setName(deck.name);
    }
  }, [setName, setDeck, isNameChangedRef]);

  const searchOnLibrary = useCallback(() => {
    setMode("library");
  }, [setMode]);

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{trans("header.title")}</DrawerTitle>
        <DrawerDescription>{trans("header.description")}</DrawerDescription>
      </DrawerHeader>
      <DrawerBody css={styles.body}>
        <section>
          <Heading css={styles.heading}>{trans("heading.deck")}</Heading>
          <DeckListbox deck={deck} onChange={handleDeckChange}/>
        </section>
        <section>
          <Heading css={styles.heading}>{trans("heading.basic")}</Heading>
          <Input
            label={trans("name")}
            value={name}
            onChange={handleInputChange}
          />
        </section>
      </DrawerBody>
      <DrawerFooter>
        <Button type="submit" size="lg" isLoading={isLoading}>{trans("submit")}</Button>
      </DrawerFooter>
    </>
  );
};
