import {css} from "@emotion/react";
import {ReactNode} from "react";
import {alpha, boxShadow, color, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


interface DrawerFooterProps extends CustomDataAttributes {
  className?: string;
  children?: ReactNode;
};

const styles = {
  root: css`
    margin-block-start: ${size(6)}; // 内部のシャドウを描画するために margin と padding に分ける
    padding-block-start: ${size(4)};
    margin-block-end: ${size(-10)};
    margin-inline: ${size(-12)};
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    z-index: 2;
  `,
  inner: css`
    width: 100%;
    height: 100%;
    padding-block: ${size(6)};
    padding-inline: ${size(12)};
    background-color: ${color("white")};
    box-shadow: ${boxShadow(alpha(color("primary", 5), 0.5), 1)};
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DrawerFooter: React.FC<DrawerFooterProps> = ({
  className,
  children,
  ...data
}) => {
  return (
    <div css={styles.root} {...data}>
      <div css={styles.inner} className={className}>
        {children}
      </div>
    </div>
  );
};
