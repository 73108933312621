import {css} from "@emotion/react";
import {ChangeEventHandler} from "react";
import {Checkbox} from "src/components/common/checkbox";
import {Slider} from "src/components/common/slider";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";
import {useTranslation} from "src/modules/translation";


interface VolumePaneProps {
  title: string;
  mute: boolean;
  value: number;
  onMuteChange?: ChangeEventHandler<HTMLInputElement>;
  onValueChange?: ChangeEventHandler<HTMLInputElement>;
};

const styles = {
  body: css`
    block-size: ${size(13)};
    padding-inline-end: ${size(3)};
    border-radius: ${size(2)};
    background-color: ${color("white")};
    border: solid ${borderWidth(1)} ${alpha(color("primary", 5), 0.3)};
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    overflow: hidden;
    font-size: ${size(4)};
    margin:  ${size(1)};
    `,
  title: css`
    text-align: left;
  `,
  menuBlock: css`
    float: left;
    margin: ${size(2)};
    width: 40%;
  `,
  sliderBlock: css`
    float: left;
    margin: ${size(2)};
  `,
  valueBlock: css`
    float: left;
    display: grid;
    place-items: center;
  `
};

export const VolumePane: React.FC<VolumePaneProps> = ({
  title,
  mute,
  value,
  onMuteChange,
  onValueChange
}) => {
  const {trans} = useTranslation("soundMenu");
  return (
    <div css={styles.body}>
      <div css={styles.menuBlock}>
        <p css={styles.title}>{title}</p>
        <Checkbox label={trans("mute")} checked={mute} onChange={onMuteChange}/>
      </div>
      <div css={styles.sliderBlock}>
        <Slider value={value} onChange={onValueChange}/>
      </div>
      <div css={styles.valueBlock}>
        {value}
      </div>
    </div>
  );
};