// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#ex-toast-container {
  position: absolute;
  height: 0;
  min-width: 50%;
  left: 50%;
  top: 0;
}

.ex-toast-message {
  left: -50%;
  position: relative;
  display: flex;
  justify-content: space-between;


  padding: 10px;
  margin-top: 5px;
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 6px;
  border: 3px solid #b7b779;
  background-color: rgb(253, 253, 192);
}


.ex-toast-message button {
  align-self: flex-start;
}`, "",{"version":3,"sources":["webpack://./Util/Toaster.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,SAAS;EACT,cAAc;EACd,SAAS;EACT,MAAM;AACR;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,aAAa;EACb,8BAA8B;;;EAG9B,aAAa;EACb,eAAe;EACf,eAAe;EACf,uBAAuB;EACvB,kBAAkB;EAClB,yBAAyB;EACzB,oCAAoC;AACtC;;;AAGA;EACE,sBAAsB;AACxB","sourcesContent":["\n#ex-toast-container {\n  position: absolute;\n  height: 0;\n  min-width: 50%;\n  left: 50%;\n  top: 0;\n}\n\n.ex-toast-message {\n  left: -50%;\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n\n\n  padding: 10px;\n  margin-top: 5px;\n  font-size: 18px;\n  font-family: sans-serif;\n  border-radius: 6px;\n  border: 3px solid #b7b779;\n  background-color: rgb(253, 253, 192);\n}\n\n\n.ex-toast-message button {\n  align-self: flex-start;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
