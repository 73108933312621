import {css} from "@emotion/react";
import {Fragment} from "react";
import {alpha, color} from "src/components/constants/constants";


interface Props {
  hotkeys: string | Array<string>;
};

const styles = {
  root: css`
    margin-inline-end: 0.25em;
    padding-block: 0.25em;
    padding-inline: 0.5em;
    border-radius:  1em;
    background-color: ${alpha(color("white"), 0.3)};
  `
};

export const HotkeyBadge: React.FC<Props> = ({
  hotkeys
}) => {
  const hotkey = Array.isArray(hotkeys) ? hotkeys[0] : hotkeys;

  return (
    <div css={styles.root}>
      {hotkey.split(" ").map((singleHotkey, index) => (
        <Fragment key={index}>
          {index > 0 && <span> </span>}
          <span key={index}>
            {singleHotkey.split("+").map((char, index) => (
              <Fragment key={index}>
                {index > 0 && <span>+</span>}
                <kbd key={index}>{char.charAt(0).toUpperCase() + char.slice(1)}</kbd>
              </Fragment>
            ))}
          </span>
        </Fragment>
      ))}
    </div>
  );
};