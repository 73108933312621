import {User} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {useState} from "react";
import {alpha, boxShadow, color, filterBlur, size, smartphone} from "src/components/constants/constants";
import {createOverlay} from "src/modules/create";
import {LoginForm} from "./loginForm";
import {RegisterForm} from "./registerForm";


export interface LoginFormCoverProps {
  isVisible: boolean;
  zIndex?: number;
  onSuccess?: (user: User) => void;
};

const styles = {
  root: css`
    inset: 0rem;
    line-height: 1;
    background-color: ${color("white")};
    background-image: url("https://source.unsplash.com/M5gwfVh5Mhs");
    background-size: cover;
    box-sizing: border-box;
    pointer-events: all;
    position: absolute;
  `,
  background: css`
    width: 100%;
    height: 100%;
    padding-inline: 5vw;
    column-gap: 5vw;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    ${smartphone()} {
      padding-inline: 0vw;
      padding-block: 5vh;
      row-gap: 5vh;
      flex-direction: column;
    }
  `,
  logo: css`
    display: flex;
    flex-basis: 0rem;
    flex-grow: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  logoImage: css`
    width: 100%;
    ${smartphone()} {
      width: initial;
      height: 100%;
    }
  `,
  main: css`
    padding-inline: ${size(12)};
    background-color: ${alpha(color("white"), 0.95)};
    backdrop-filter: ${filterBlur(1)};
    box-shadow: ${boxShadow(alpha(color("primary", 5), 0.5), 1)};
    display: flex;
    flex-basis: 0rem;
    flex-grow: 5;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${smartphone()} {
      flex-grow: 14;
    }
  `
};

/**
 * - **Inner Props**: {@link LoginFormCoverProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const LoginFormCover = createOverlay<LoginFormCoverProps>(({
  isVisible,
  zIndex = 400,
  onSuccess
}) => {
  const [mode, setMode] = useState<"login" | "register">("login");

  return isVisible ? (
    <div css={styles.root} style={{zIndex}}>
      <div css={styles.background}>
        <div css={styles.logo}>
          <img css={styles.logoImage} src="/assets/general/logo.svg" alt="Quizium"/>
        </div>
        <div css={styles.main}>
          {mode === "login" ? (
            <LoginForm onSuccess={onSuccess} onMoveToRegister={() => setMode("register")}/>
          ) : (
            <RegisterForm onSuccess={onSuccess} onMoveToLogin={() => setMode("login")}/>
          )}
        </div>
      </div>
    </div>
  ) : null;
}, {
  isVisible: false
});