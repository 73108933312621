import {css} from "@emotion/react";
import {useMedia} from "react-use";
import {size, smartphone} from "src/components/constants/constants";
import {GameCover} from "src/components/modules/gameCover";
import {createOverlay} from "src/modules/create";
import {CompetitionSlide} from "./competitionSlide";
import {DesktopChatSlide} from "./desktopChatSlide";
import {OpenMenuButton} from "./openMenuButton";
import {SessionPane} from "./sessionPane";
import {SmartphoneChatSlide} from "./smartphoneChatSlide";


export interface BottomOverlayProps {
  /**
   * このオーバーレイの Z index。
   * @defaultValue `100`
   */
  zIndex?: number;
  /**
   * ランキングを表示する際に使用するイベントの ID。
   * `null` を指定すると、イベント結果が非表示になります。
   */
  competitionId: string | null;
  /**
   * ランキングを表示する際に使用する集計タグ。
   * `null` を指定すると、イベント結果が非表示になります。
   */
  aggregationTags: [string, string?, string?] | null;
};

const styles = {
  cover: css`
    padding: ${size(4)};
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  `,
  left: css`
    row-gap: ${size(4)};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    z-index: 0;
  `,
  negativeLeft: css`
    margin-inline-start: ${size(-4)};
  `,
  right: css`
    row-gap: ${size(4)};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 0;
  `,
  center: css`
    inset-inline: ${size(4)};
    inset-block-start: ${size(4)};
    inset-block-end: ${size(0)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    z-index: 1;
  `,
  chatPane: css`
    inline-size: ${size(56)};
    block-size: ${size(48)};
  `
};

/**
 * - **Inner Props**: {@link BottomOverlayProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const BottomOverlay = createOverlay<BottomOverlayProps>(({
  zIndex = 100,
  competitionId,
  aggregationTags
}) => {
  const isSmartphone = useMedia(smartphone().replace("@media", ""));

  return (
    <GameCover css={styles.cover} zIndex={zIndex}>
      <div css={styles.center}>
        {isSmartphone && <SmartphoneChatSlide/>}
      </div>
      <div css={styles.left}>
        {!isSmartphone && (
          <div css={styles.negativeLeft}>
            <DesktopChatSlide/>
          </div>
        )}
        {!isSmartphone && <SessionPane/>}
        {isSmartphone && <OpenMenuButton/>}
      </div>
      <div css={styles.right}>
        <CompetitionSlide competitionId={competitionId} aggregationTags={aggregationTags}/>
      </div>
    </GameCover>
  );
}, {
  competitionId: null,
  aggregationTags: null
});
