import {css} from "@emotion/react";
import {ButtonHTMLAttributes} from "react";
import {color, size} from "src/components/constants/constants";


export interface SegmentedButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "css"> {
  className?: string;
}

const styles = {
  root: css`
    flex-basis: 0rem;
    flex-grow: 1;
    cursor: pointer;
    position: relative;
    &::after {
      inset: ${size(0)};
      transition: background-color 0.2s ease;
      position: absolute;
      z-index: 0;
      content: "";
    }
    &:first-of-type::after {
      border-start-start-radius: ${size(1)};
      border-end-start-radius: ${size(1)};
    }
    &:last-of-type::after {
      border-start-end-radius: ${size(1)};
      border-end-end-radius: ${size(1)};
    }
    &:hover:not(:disabled)::after {
      background-color: ${color("primary", 6)};
    }
  `,
  buttonInner: css`
    border-radius: ${size(1)};
    padding-block: 0.5em;
    padding-inline: 1em;
    text-align: center;
    z-index: 2;
    position: relative;
    pointer-events: none;
  `
};

/**
 * @deprecated
 * @group React Components
 * @category Common Component
 */
export const SegmentedButton: React.FC<SegmentedButtonProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <button className={className} css={styles.root} type="button" {...props}>
      <div css={styles.buttonInner}>
        {children}
      </div>
    </button>
  );
};
