import {socket} from "@baton8/qroud-lib-repositories";
import {useEffect} from "react";
import {createOverlay} from "src/modules/create";
import {useTranslation} from "src/modules/translation";
import {useToast} from "src/overlays/common/toast";


export interface AutoDisconnectorProps {
  onForceDisconnect: () => unknown;
};

const styles = {
};

/**
 * - **Inner Props**: {@link AutoDisconnectorProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const AutoDisconnector = createOverlay<AutoDisconnectorProps>(({
  onForceDisconnect
}) => {
  const {trans} = useTranslation("autoDisconnector");

  const showToast = useToast();

  useEffect(() => {
    socket.on("forceDisconnect", () => {
      showToast(trans("disconnect"));
      onForceDisconnect?.();
    });
    return () => {
      socket.off("forceDisconnect");
    };
  }, [showToast, onForceDisconnect, trans]);

  return null;
}, {
  onForceDisconnect: () => null
});