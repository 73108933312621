import {css} from "@emotion/react";
import {Button} from "src/components/common/button";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


export interface DirectionSlideButtonListProps extends CustomDataAttributes {
  buttonSpecs: Array<DirectionButtonSpec>;
  showButtons: boolean;
  className?: string;
};

export type DirectionButtonSpec = {
  /**
   * ボタンを識別するキー。
   * React コンポーネントの `key` としてそのまま渡されます。
   * 省略した場合、配列のインデックスが `key` として利用されます。
   */
  key?: string,
  /**
   * ボタンに表示するテキスト。
   */
  text: string,
  /**
   * ボタンを発火するショートカットキー。
   * Mousetrap のキー設定が利用できます。
   */
  hotkeys?: string | Array<string>,
  /**
   * ボタンがクリックされたときに呼ばれるコールバック関数。
   */
  onClick?: () => unknown
};

const styles = {
  root: css`
    margin-block-start: ${size(3)};
    padding-block-start: ${size(3)};
    row-gap: ${size(2)};
    font-size: ${size(3)};
    border-block-start: solid ${borderWidth(1)} ${alpha(color("primary", 5), 0.2)};
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  button: css`
    width: ${size(36)};
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DirectionSlideButtonList: React.FC<DirectionSlideButtonListProps> = ({
  buttonSpecs,
  showButtons,
  className,
  ...data
}) => {
  return (showButtons && buttonSpecs.length > 0) ? (
    <div css={styles.root} className={className} {...data}>
      {buttonSpecs.map((spec, index) => (
        <Button key={spec.key ?? index} css={styles.button} type="button" hotkeys={spec.hotkeys} onClick={spec.onClick}>
          {spec.text}
        </Button>
      ))}
    </div>
  ) : null;
};
