import {css} from "@emotion/react";
import {ReactElement, useCallback, useEffect, useState} from "react";
import SelectOriginal, {GroupBase, InputActionMeta, OptionsOrGroups, Props as ReactSelectProps} from "react-select";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";


export interface SelectProps<O, G extends GroupBase<O>> extends ReactSelectProps<O, false, G> {
  /** */
  suggestOption?: (value: string) => OptionsOrGroups<O, G> | undefined;
  /**
   * 初期段階もしくはテキスト無入力状態で全てのオプションを表示するか。
   */
  showAllSuggestsFirst?: boolean;
  /** */
  className?: string;
}

export type SelectComponent = <O, G extends GroupBase<O> = GroupBase<O>>(props: SelectProps<O, G>) => ReactElement;

const styles = {
  root: css`
    & .qr-select__control {
      padding-block: 0.5em;
      padding-inline: 0.5em;
      border: solid ${borderWidth(1)} ${color("primary", 5)};
      border-radius: ${size(1)};
      line-height: 1;
      background-color: ${color("primary", 8)};
      box-sizing: border-box;
      &--is-focused {
        border-color: ${color("primary", 4)};
        outline: solid ${borderWidth(1)} ${color("primary", 4)} !important;
        transition: none !important;
      }
    }
    & .qr-select__input {
      height: 1em;
    }
    & .qr-select__menu {
      margin-block-start: ${borderWidth(2)};
    }
    & .qr-select__menu-list {
      border: solid ${borderWidth(1)} ${color("primary", 5)};
      border-radius: ${size(1)};
      background-color: ${color("primary", 8)};
      overflow: hidden;
    }
    & .qr-select__option {
      padding-block: 0.5em;
      padding-inline: 0.5em;
      cursor: pointer;
      &--is-focused {
        background-color: ${color("primary", 6)};
      }
      &--is-selected {
        background-color: ${alpha(color("primary", 4), 0.3)};
      }
    }
    & .qr-select__menu-notice {
      padding-block: 0.5em;
      padding-inline: 0.5em;
    }
  `
};

/**
 * 汎用の選択ボックスです。
 * React Select が提供するコンポーネントに QROUD に合うようなスタイリングを当てて、上部にラベルを表示できるようにしたものです。
 * `options` などの props に渡す値の詳細は React Select のドキュメントを参照してください。
 * @group React Components
 * @category Common Component
 */
// TODO: 右の矢印部分のスタイリング (FontAwesome使うとか)
export const Select: SelectComponent = ({
  options,
  suggestOption,
  filterOption,
  showAllSuggestsFirst = false,
  className,
  ...props
}) => {
  const [actualOptions, setActualOptions] = useState(options);
  useEffect(() => {
    if (suggestOption != null) {
      setActualOptions(showAllSuggestsFirst ? options : []);
    } else {
      setActualOptions(options);
    }
  }, [suggestOption, showAllSuggestsFirst, options]);

  const actualOnInputChange = useCallback((value: string, meta: InputActionMeta) => {
    if (suggestOption != null) {
      if (meta.action === "input-change") {
        if (showAllSuggestsFirst && value === "") {
          setActualOptions(options);
        } else {
          setActualOptions(suggestOption(value));
        }
      } else if (meta.action === "menu-close") {
        setActualOptions(showAllSuggestsFirst ? options : []);
      }
    }
  }, [suggestOption, showAllSuggestsFirst, options]);

  const actualFilterOption = suggestOption != null ? () => true : filterOption;

  return (
    <SelectOriginal
      css={styles.root}
      className={className}
      classNamePrefix="qr-select"
      placeholder=""
      noOptionsMessage={() => null}
      {...props}
      options={actualOptions}
      filterOption={actualFilterOption}
      onInputChange={actualOnInputChange}
      isMulti={false}
      unstyled={true}
    />
  );
};