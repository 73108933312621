import {css} from "@emotion/react";
import {ReactNode, forwardRef} from "react";
import SimpleBarCore from "simplebar-core";
import SimpleBar from "simplebar-react";
import {size} from "src/components/constants/constants";


export interface ScrollProps {
  /**
   * スクロールエリアの周りに小さなスペースを確保するかどうか。
   * これを `true` にすると、小さなスペースを作ることでスクロールエリア内の UI の端が切れてしまうのを防げます。
   */
  insertMargin?: boolean;
  /** */
  className?: string;
  /** */
  children?: ReactNode;
}

const styles = {
  root: css`
    &[data-insert="true"] {
      margin: ${size(-1)};
    }
  `,
  bar: css`
    inline-size: 100%;
    block-size: 100%;
  `,
  inner: css`
    &[data-insert="true"] {
      inline-size: calc(100% - ${size(2)});
      block-size: calc(100% - ${size(2)});
      margin: ${size(1)};
    }
    &[data-insert="false"] {
      inline-size: 100%;
      block-size: 100%;
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const Scroll = forwardRef<SimpleBarCore, ScrollProps>(({
  insertMargin = false,
  className,
  children
}, ref) => {
  return (
    <div css={styles.root} className={className} data-insert={insertMargin}>
      <SimpleBar css={styles.bar} ref={ref}>
        <div css={styles.inner} data-insert={insertMargin}>
          {children}
        </div>
      </SimpleBar>
    </div>
  );
});