import {FieldId, LastPosition, Session, getMe, getSession, getSessionEntryRequirement, global, updateCookieAgreed, writeLog} from "@baton8/qroud-lib-repositories";
import {User, logout, socket} from "@baton8/qroud-lib-repositories";
import {AutoDisconnector, InputPasswordDialog, LoginFormCover, Menu} from "@baton8/qroud-lib-resources";
import {Engine, Scene, SceneActivationContext} from "excalibur";
import {deleteUrlParam, getUrlParam} from "src/utils/url";


export class LoginScene extends Scene {
  public constructor() {
    super();
  }

  public override onInitialize(engine: Engine): void {
    this.setupInitialOverlays(engine);
  }

  public override onActivate({engine}: SceneActivationContext): void {
    this.autoLogin(engine);
  }

  private setupInitialOverlays(engine: Engine): void {
    AutoDisconnector.propsSubject.next({
      onForceDisconnect: async () => {
        await logout();
        this.postprocessAfterLogout(engine);
      }
    });
    Menu.propsSubject.next({
      isOpen: false,
      onLogout: () => {
        this.postprocessAfterLogout(engine);
      }
    });
  }

  private async autoLogin(engine: Engine): Promise<void> {
    updateCookieAgreed(true); // TODO: ユーザーに尋ねる
    const me = await getMe().catch(() => null);
    if (me != null) {
      this.prepareStartMainScene(engine, me);
    } else {
      this.setupLoginCover(engine);
    }
  }

  private setupLoginCover(engine: Engine): void {
    LoginFormCover.propsSubject.next({
      isVisible: true,
      onSuccess: (user) => {
        this.prepareStartMainScene(engine, user);
      }
    });
  }

  private prepareStartMainScene(engine: Engine, me: User): void {
    global.userSubject.next(me);

    socket.connect();
    socket.emit("join", me, async (lastPosition: LastPosition | undefined) => {
      writeLog("LoginScene", "join", lastPosition);
      const session = await this.determineSession(lastPosition);
      const fieldId = await this.determineFieldId(lastPosition);

      this.startMainScene(engine, session, fieldId);
    });
  }

  private async startMainScene(engine: Engine, session: Session, fieldId: FieldId): Promise<void> {
    const requirement = await getSessionEntryRequirement(session.id);
    if (requirement.password) {
      InputPasswordDialog.propsSubject.next({
        isVisible: true,
        zIndex: 410,
        correctPassword: requirement.password,
        onConfirm: () => {
          engine.goToScene("main", {session, fieldId, isFirst: true});
        }
      });
    } else {
      engine.goToScene("main", {session, fieldId, isFirst: true});
    }
  }

  private postprocessAfterLogout(engine: Engine): void {
    // これ LoginScene の onActivate でやるべきことでは
    global.userSubject.next(null);
    global.sessionSubject.next(null);
    global.fieldSubject.next(null);
    deleteUrlParam("session");
    deleteUrlParam("map");
    socket.disconnect();
    engine.goToScene("login");
  }

  /**
   * 最初に参加するセッションを決定します。
   * URL で `session` が指定されていて、そのセッションが今でも存在している場合は、そのセッションを返します。
   * それ以外の場合は、オフィシャルセッションを返します。
   */
  private async determineSession(lastPosition: LastPosition | undefined): Promise<Session> {
    const urlSessionId = getUrlParam("session");
    const lastPositionSessionId = lastPosition?.sessionId;

    const sessionId = urlSessionId || lastPositionSessionId || "public";
    const session = await getSession(sessionId).catch(() => getSession("public"));
    writeLog("LoginScene", "determineSession", session);
    return session;
  }

  private async determineFieldId(lastPosition: LastPosition | undefined): Promise<FieldId> {
    const urlFieldId = getUrlParam("map");
    const lastPositionFieldId = lastPosition?.fieldId;

    const fieldId = urlFieldId || lastPositionFieldId || "areaEntrance";
    writeLog("LoginScene", "determineFieldId", fieldId);
    return fieldId;
  }
}
