import {css} from "@emotion/react";
import {faCaretRight} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {size} from "src/components";
import {Link} from "src/components/common/link";
import {useTranslation} from "src/modules/translation";


export interface Props {
};

const styles = {
  list: css`
    list-style-type: none;
  `,
  listItem: css`
    margin-inline-start: 1em;
    & +& {
      margin-block-start: ${size(2)};
    }
  `,
  listBullet: css`
    width: 1em;
    margin-inline-start: -1em;
    opacity: 0.6;
    display: inline-block;
  `,
  copyright: css`
    margin-block-start: ${size(1)};
    font-size: ${size(3)};
    opacity: 0.6;
  `
};

/**
 * - **Inner Props**: {@link Props}
 * @group React Components
 * @category Builtin Overlay
 */
export const MaterialList: React.FC<Props> = ({
}) => {
  const {trans} = useTranslation("menu");

  return (
    <ul css={styles.list}>
      <li css={styles.listItem}>
        <span css={styles.listBullet}><FontAwesomeIcon icon={faCaretRight}/></span>
        <Link href="http://refmap-l.blog.jp/archives/8655709.html" target="_blank">REFMAP マップチップ一式</Link>
        <div css={styles.copyright}>© REFMAP</div>
      </li>
      <li css={styles.listItem}>
        <span css={styles.listBullet}><FontAwesomeIcon icon={faCaretRight}/></span>
        <Link href="https://plaza.komodo.jp/products/time-fantasy-animals" target="_blank">TIME FANTASY: ANIMALS</Link>
        <div css={styles.copyright}>© finalbossblues</div>
      </li>
      <li css={styles.listItem}>
        <span css={styles.listBullet}><FontAwesomeIcon icon={faCaretRight}/></span>
        <Link href="https://plaza.komodo.jp/collections/dlcs/products/time-fantasy-add-on-animals-2" target="_blank">Time Fantasy Add on Animals 2</Link>
        <div css={styles.copyright}>© finalbossblues</div>
      </li>
    </ul>
  );
};