import {Vector, vec} from "excalibur";


export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

export function lerp(start: number, end: number, ratio: number): number;
export function lerp(start: Vector, end: Vector, ratio: number): Vector;
export function lerp(start: any, end: any, ratio: number): number | Vector {
  if (typeof start === "number") {
    return start * (1 - ratio) + end * ratio;
  } else {
    const x = lerp(start.x, end.x, ratio);
    const y = lerp(start.y, end.y, ratio);
    return vec(x, y);
  }
}