import {css} from "@emotion/react";
import {ReactNode} from "react";
import {size, smartphone} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


interface DrawerAddonProps extends CustomDataAttributes {
  className?: string;
  children?: ReactNode;
};

const styles = {
  root: css`
    inset-block-start: ${size(20)};
    inset-block-end: ${size(4)};
    inset-inline-start: ${size(-16)};
    inset-inline-end: auto;
    column-gap: ${size(2)};
    row-gap: ${size(2)};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    ${smartphone()} {
      inset-block-start: ${size(-16)};
      inset-block-end: auto;
      inset-inline-start: ${size(20)};
      inset-inline-end: ${size(4)};
      flex-direction: row;
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DrawerAddon: React.FC<DrawerAddonProps> = ({
  className,
  children,
  ...data
}) => {
  return (
    <div css={styles.root} className={className} {...data}>
      {children}
    </div>
  );
};
