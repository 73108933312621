import {FieldGroupId, FieldId} from "@baton8/qroud-lib-repositories";
import {FieldEntityComponent} from "@baton8/qroud-lib-resources";
import {Actor, CollisionType, Entity, vec} from "excalibur";
import {Field} from "./field";


export class FieldGroup extends Actor {
  public fieldGroupId: FieldGroupId;
  public cachedEntities: Map<FieldId, Array<Entity>>;
  public preservedEntities: Array<Entity>;

  public constructor() {
    super({
      pos: vec(0, 0),
      collisionType: CollisionType.PreventCollision,
      name: "FieldGroup"
    });
    this.fieldGroupId = "";
    this.cachedEntities = new Map();
    this.preservedEntities = [];
  }

  public registerEntity(field: Field, entity: Entity, preserve: boolean): void {
    if (!this.cachedEntities.has(field.id)) {
      this.cachedEntities.set(field.id, []);
    }
    this.cachedEntities.get(field.id)!.push(entity);
    if (preserve) {
      this.preservedEntities.push(entity);
    }
  }

  public addEntities(field: Field): void {
    const entities = this.getEntities(field.id);
    for (const entity of entities) {
      const fieldEntity = entity.get(FieldEntityComponent);
      if (fieldEntity != null) {
        fieldEntity.field = field;
        this.addChild(entity);
      } else {
        throw new Error("Entity does not have FieldEntityComponent");
      }
    }
  }

  public getEntities(fieldId: FieldId): Array<Entity> {
    const entities = new Set([
      ...(this.cachedEntities.get(fieldId) || []),
      ...this.preservedEntities
    ]);
    return [...entities];
  }

  public changeFieldGroup(fieldGroupId: FieldGroupId): void {
    if (this.fieldGroupId !== fieldGroupId) {
      this.killAllChildren();
      this.fieldGroupId = fieldGroupId;
      this.cachedEntities = new Map();
      this.preservedEntities = [];
    } else {
      this.removeAllChildren();
    }
  }

  public reset(): void {
    this.removeAllChildren();
    this.fieldGroupId = "";
    this.cachedEntities = new Map();
    this.preservedEntities = [];
  }

  public override removeAllChildren(): Entity {
    super.removeAllChildren();
    return this;
  }

  public killAllChildren(): Entity {
    const children = [...this.children];
    for (const child of children) {
      child.kill();
    }
    return this;
  }

  public isLoaded(fieldId: FieldId): boolean {
    return this.cachedEntities.has(fieldId);
  }
}
