import {global} from "@baton8/qroud-lib-repositories";
import dayjs, {Dayjs} from "dayjs";
import {ReactNode, useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {useBehaviorSubject} from "src/modules/subject";
import {Primitive} from "ts-essentials";


/**
 * @group React Hooks
 */
export const useLocale = (): string => {
  const locale = useBehaviorSubject(global.localeSubject);
  return locale;
};

/**
 * @group React Hooks
 */
export const useSetLocale = (): (locale: string) => void => {
  const setLocale = useCallback((locale: string) => {
    global.localeSubject.next(locale);
  }, []);
  return setLocale;
};

/**
 * @group React Hooks
 */
export const useTranslation = (scope?: string): TransCallbacks => {
  const intl = useIntl();

  const trans = useCallback((key: string, values?: any): any => {
    const fullKey = key.includes(":") ? key.replace(":", ".") : (scope != null ? `${scope}.` : "") + key;
    const defaultMessage = values?.defaultMessage ?? `<${fullKey}>`;
    const message = intl.formatMessage({id: fullKey, defaultMessage}, values);
    return message;
  }, [intl, scope]);

  const transDate = useCallback((date: string | number | Date | Dayjs | null | undefined): string => {
    if (date !== null && date !== undefined) {
      const locale = intl.locale;
      if (locale === "ja") {
        return dayjs(date).locale("ja").format("YYYY/MM/DD");
      } else {
        return dayjs(date).locale("en").format("DD/MMM/YYYY");
      }
    } else {
      return "";
    }
  }, [intl]);

  const transTime = useCallback((date: string | number | Date | Dayjs | null | undefined): string => {
    if (date !== null && date !== undefined) {
      const locale = intl.locale;
      if (locale === "ja") {
        return dayjs(date).locale("ja").format("YYYY/MM/DD HH:mm");
      } else {
        return dayjs(date).locale("en").format("DD/MMM/YYYY HH:mm");
      }
    } else {
      return "";
    }
  }, [intl]);

  const transNumber = useCallback((number: number | null | undefined, options?: {digit?: number, withSign?: boolean}): string => {
    const intlOptions = {minimumFractionDigits: options?.digit, maximumFractionDigits: options?.digit};
    if (number !== null && number !== undefined) {
      if (number > 0) {
        return (options?.withSign ? "+" : "") + intl.formatNumber(number, intlOptions);
      } else if (number === 0) {
        return (options?.withSign ? "±" : "") + intl.formatNumber(number, intlOptions);
      } else {
        return "−" + intl.formatNumber(-number, intlOptions);
      }
    } else {
      return "";
    }
  }, [intl]);

  return useMemo(() => ({
    trans,
    transNode: trans,
    transDate,
    transTime,
    transNumber
  }), [
    trans,
    transDate,
    transTime,
    transNumber
  ]);
};

type TransCallback = {
  (key: string, values?: Record<string, Primitive | ((parts: Array<string>) => string)>): string,
  (key: string, values?: Record<string, Primitive | ReactNode | ((parts: Array<ReactNode>) => ReactNode)>): ReactNode
};
type TransCallbacks = {
  trans: TransCallback,
  transNode: (key: string, values?: Record<string, ReactNode | ((parts: Array<ReactNode>) => ReactNode)>) => ReactNode,
  transDate: (date: string | number | Date | Dayjs | null | undefined) => string,
  transTime: (date: string | number | Date | Dayjs | null | undefined) => string,
  transNumber: (number: number | null | undefined, options?: {digit?: number, withSign?: boolean}) => string
};