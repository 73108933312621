import {css} from "@emotion/react";
import {InputHTMLAttributes, ReactNode, forwardRef} from "react";
import {HelperText} from "src/components/common/helperText";
import {Label} from "src/components/common/label";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";


export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "css"> {
  /**
   * テキスト欄の上に表示するラベル。
   */
  label?: ReactNode;
  /**
   * テキスト欄の下に表示する説明文。
   */
  helperText?: ReactNode;
  /**
   * テキスト欄内部の左側に表示する要素。
   */
  leftAddon?: ReactNode;
  /**
   * テキスト欄内部の右側に表示する要素。
   */
  rightAddon?: ReactNode;
  /** */
  className?: string;
}

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    &[data-disabled="true"] {
      opacity: 0.5;
    }
  `,
  inputContainer: css`
    column-gap: 0.4em;
    display: flex;
    align-items: baseline;
  `,
  addon: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
  input: css`
    height: 1em;
    padding-block: 0.5em;
    padding-inline: 0.5em;
    border: solid ${borderWidth(1)} ${alpha(color("black"), 0.3)};
    background-color: ${color("white")};
    border-radius: ${size(1)};
    line-height: 1;
    box-sizing: content-box;
    flex-grow: 1;
    flex-shrink: 1;
    &:focus {
      border-color: ${color("primary", 5)};
      outline: solid ${borderWidth(1)} ${color("primary", 5)};
    }
    &:disabled {
      cursor: inherit;
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(({
  label,
  helperText,
  leftAddon,
  rightAddon,
  className,
  ...inputProps
}, ref) => {
  return (
    <label className={className} css={styles.root} data-disabled={inputProps.disabled}>
      {!!label && (
        <Label>{label}</Label>
      )}
      <div css={styles.inputContainer}>
        {leftAddon && (
          <div css={styles.addon}>
            {leftAddon}
          </div>
        )}
        <input css={styles.input} ref={ref} {...inputProps}/>
        {rightAddon && (
          <div css={styles.addon}>
            {rightAddon}
          </div>
        )}
      </div>
      {!!helperText && (
        <HelperText>{helperText}</HelperText>
      )}
    </label>
  );
});