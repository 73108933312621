import {Group, PublicUser, Session, createSession, global} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import dayjs from "dayjs";
import {nanoid} from "nanoid";
import {FormEvent, useCallback, useEffect, useState} from "react";
import {Button} from "src/components/common/button";
import {Checkbox} from "src/components/common/checkbox";
import {Heading} from "src/components/common/heading";
import {Input} from "src/components/common/input";
import {Scroll} from "src/components/common/scroll";
import {alpha, borderWidth, color, size} from "src/components/constants/constants";
import {Drawer, DrawerBody, DrawerCloseButton, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle} from "src/components/modules/drawer";
import {GroupMultiListbox} from "src/components/modules/groupMultiListbox";
import {UserMultiListbox} from "src/components/modules/userMultiListbox";
import {createOverlay} from "src/modules/create";
import {useBehaviorSubject} from "src/modules/subject";
import {useTranslation} from "src/modules/translation";


export interface CreateSessionDrawerProps {
  /**
   * このオーバーレイの Z index。
   * @defaultValue `300`
   */
  zIndex?: number;

  isOpen: boolean;

  onSessionChange: (session: Session) => unknown;
};

const styles = {
  drawer: css`
  `,
  body: css`
    row-gap: ${size(6)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  formScroll: css`
    font-size: ${size(4)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
  formContainer: css`
    row-gap: ${size(6)};
    display: flex;
    flex-direction: column;
  `,
  buttonContainer: css`
    display: flex;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  section: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
  heading: css`
    margin-block-end: ${size(4)};
  `,
  inputList: css`
    row-gap: ${size(4)};
    display: flex;
    flex-direction: column;
  `,
  inputExpireHoursLine: css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    & >*:nth-child(n+2) {
      margin-block-end: calc(0.5em + ${borderWidth(1)});
    }
  `,
  expireHoursAddon: css`
    white-space: pre;
  `,
  expireHoursInput: css`
    width: 8em;
  `,
  group: css`
    margin-block-start: ${size(-1)};
    padding-block-start: ${size(5)};
    padding-inline-start: ${size(4)};
    border-inline-start: dashed ${borderWidth(1)} ${alpha(color("black"), 0.3)};
  `,
  formList: css`
    row-gap: ${size(4)};
    display: flex;
    flex-direction: column;
  `
};

/**
 * - **Inner Props**: {@link CreateSessionDrawerProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const CreateSessionDrawer = createOverlay<CreateSessionDrawerProps>(({
  zIndex = 300,
  isOpen,
  onSessionChange
}) => {
  const {trans, transNode, transTime} = useTranslation("createSessionDrawer");

  const user = useBehaviorSubject(global.userSubject);

  const [name, setName] = useState("");
  const [expireHours, setExpireHours] = useState(24);
  const [allowShowList, setAllowShowList] = useState(true);

  const [specifyPassword, setSpecifyPassword] = useState(false);
  const [password, setPassword] = useState("");

  const [restrictEntryGroup, setRestrictEntryGroup] = useState(false);
  const [entryGroups, setEntryGroups] = useState<Array<Group>>([]);

  const [restrictDirectorUser, setRestrictDirectorUser] = useState(true);
  const [directorUsers, setDirectorUsers] = useState<Array<PublicUser>>(user ? [user] : []);

  const [isLoading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    CreateSessionDrawer.propsSubject.update({isOpen: false});
  }, []);

  const handleSubmit = useCallback(async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault();
    setLoading(true);
    try {
      const entryRequirement = {
        allowShowList,
        allowAllGroup: !restrictEntryGroup,
        groupIds: entryGroups.map((group) => group.id),
        password: specifyPassword ? password : ""
      };
      const directorRequirement = {
        allowAllUser: !restrictDirectorUser,
        userIds: directorUsers.map((user) => user.id)
      };
      const session = await createSession(name, {entryRequirement, directorRequirement, expireHours});
      onSessionChange?.(session);
      CreateSessionDrawer.propsSubject.update({isOpen: false});
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [name, expireHours, allowShowList, specifyPassword, password, restrictEntryGroup, entryGroups, restrictDirectorUser, directorUsers, onSessionChange]);

  useEffect(() => {
    setName(user?.nickname ?? nanoid());
    setDirectorUsers(user ? [user] : []);
  }, [user]);

  return (
    <Drawer css={styles.drawer} zIndex={zIndex} isOpen={isOpen} onClose={handleClose} onSubmit={handleSubmit}>
      <DrawerCloseButton/>
      <DrawerHeader>
        <DrawerTitle>{trans("header.title")}</DrawerTitle>
        <DrawerDescription>{trans("header.description")}</DrawerDescription>
      </DrawerHeader>
      <DrawerBody css={styles.body}>
        <Scroll css={styles.formScroll} insertMargin={true}>
          <div css={styles.formContainer}>
            <section css={styles.section}>
              <Heading css={styles.heading}>{trans("heading.basic")}</Heading>
              <div css={styles.inputList}>
                <Input
                  label={trans("name")}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                <div css={styles.inputExpireHoursLine}>
                  {transNode("expireHoursForm", {
                    expireHours,
                    form: (parts) => (
                      <Input
                        css={styles.expireHoursInput}
                        label={trans("expireHours")}
                        type="number"
                        value={expireHours}
                        onChange={(event) => setExpireHours(+event.target.value)}
                        min={1} max={8760}
                      />
                    ),
                    addon: (parts) => <span css={styles.expireHoursAddon}>{parts}</span>
                  })}
                  <span css={styles.expireHoursAddon}>{trans("expireTime", {expireTime: transTime(dayjs().add(expireHours, "hour"))})}</span>
                </div>
                <Checkbox
                  label={trans("allowShowList")}
                  checked={allowShowList}
                  onChange={(event) => setAllowShowList(event.target.checked)}
                />
              </div>
            </section>
            <section css={styles.section}>
              <Heading css={styles.heading}>{trans("heading.entryRequirement")}</Heading>
              <div css={styles.formList}>
                <div>
                  <Checkbox
                    label={trans("restrictEntryGroup")}
                    checked={restrictEntryGroup}
                    onChange={(event) => setRestrictEntryGroup(event.target.checked)}
                  />
                  {restrictEntryGroup && (
                    <div css={styles.group}>
                      <GroupMultiListbox
                        label={trans("entryGroups")}
                        groups={entryGroups}
                        onChange={setEntryGroups}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <Checkbox
                    label={trans("specifyPassword")}
                    checked={specifyPassword}
                    onChange={(event) => setSpecifyPassword(event.target.checked)}
                  />
                  {specifyPassword && (
                    <div css={styles.group}>
                      <Input
                        label={trans("password")}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </section>
            <section css={styles.section}>
              <Heading css={styles.heading}>{trans("heading.directorRequirement")}</Heading>
              <div css={styles.formList}>
                <div>
                  <Checkbox
                    label={trans("restrictDirectorUser")}
                    checked={restrictDirectorUser}
                    onChange={(event) => setRestrictDirectorUser(event.target.checked)}
                  />
                  {restrictDirectorUser && (
                    <div css={styles.group}>
                      <UserMultiListbox
                        label={trans("directorUsers")}
                        users={directorUsers}
                        onChange={setDirectorUsers}
                      />
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </Scroll>
      </DrawerBody>
      <DrawerFooter>
        <Button type="submit" size="lg" isLoading={isLoading}>{trans("submit")}</Button>
      </DrawerFooter>
    </Drawer>
  );
}, {
  isOpen: false,
  onSessionChange: () => null
});
