import {useEffect, useState} from "react";
import {BehaviorSubject, Subject} from "rxjs";


/**
 * 格納されているデータの一部分だけを更新することができる `BehaviorSubject` です。
 */
export class UpdatableSubject<T extends {}> extends BehaviorSubject<T> {
  /**
   * 引数の `dispatch` に渡されたものに応じて、以下のような動作をします。
   * - オブジェクト — 現在の値の各プロパティを `dispatch` のプロパティの値で上書きしたものを次の値とする
   * - 関数 — 現在の値を `dispatch` に渡して実行した結果を次の値とする
   * @param dispatch 上書きするデータ
   */
  public update(dispatch: Partial<T> | ((prevValue: T) => T)): void {
    if (typeof dispatch === "function") {
      this.next(dispatch(this.value));
    } else {
      this.next({...this.value, ...dispatch});
    }
  }
}

/**
 * @group React Hooks
 */
export const useSubject = <T>(subject: Subject<T>): T | undefined => {
  const [value, setValue] = useState<T | undefined>(undefined);
  useEffect(() => {
    const subscription = subject.subscribe({
      next: (value) => setValue(value)
    });
    return () => subscription.unsubscribe();
  }, [subject]);

  return value;
};

/**
 * @group React Hooks
 */
export const useBehaviorSubject = <T>(subject: BehaviorSubject<T>): T => {
  const [value, setValue] = useState<T>(subject.value);
  useEffect(() => {
    const subscription = subject.subscribe({
      next: (value) => setValue(value)
    });
    return () => subscription.unsubscribe();
  }, [subject]);

  return value;
};