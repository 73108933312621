import {EntityConstructor, global, queryClient} from "@baton8/qroud-lib-repositories";
import {OverlayComponent, globalStyle, useBehaviorSubject, useLocale} from "@baton8/qroud-lib-resources";
import {Global, css} from "@emotion/react";
import React, {useEffect, useRef} from "react";
import {RawIntlProvider, createIntl, createIntlCache} from "react-intl";
import {QueryClientProvider} from "react-query";
import {RecoilRoot} from "recoil";
import {CustomEngine} from "src/game/core/engine";


interface Props {
  overlayComponents: Array<OverlayComponent>;
  entityConstructors: Array<EntityConstructor>;
  messages: Record<string, Record<string, string>>;
};

const styles = {
  screen: css`
    width: 100%;
    height: 100%;
    inset-block-end: 0rem;
    inset-inline-end: 0rem;
    position: absolute;
  `,
  overlay: css`
    position: absolute;
    inset: 0rem;
    pointer-events: none;
  `
};

export const Root: React.FC<Props> = ({
  overlayComponents,
  entityConstructors,
  messages
}) => {
  return (
    <RecoilRoot>
      <InnerRoot overlayComponents={overlayComponents} entityConstructors={entityConstructors} messages={messages}/>
    </RecoilRoot>
  );
};

export const InnerRoot: React.FC<Props> = ({
  overlayComponents,
  entityConstructors,
  messages
}) => {
  const locale = useLocale();
  const intl = useBehaviorSubject(global.intlSubject);

  const screenRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    screenRef.current?.addEventListener("click", (event) => {
      screenRef.current?.focus();
    });
  }, []);

  useEffect(() => {
    const intl = createIntl({locale, messages: messages[locale]}, createIntlCache());
    global.intlSubject.next(intl);
  }, [locale, messages]);

  useEffect(() => {
    const engine = new CustomEngine(entityConstructors);
    engine.run();
    console.clear();
  }, [entityConstructors]);

  return (
    <QueryClientProvider client={queryClient}>
      <RawIntlProvider value={intl}>
        <Global styles={globalStyle}/>
        <div id="screen-container" css={styles.screen}>
          <canvas id="screen" tabIndex={-1} ref={screenRef}/>
        </div>
        <div id="overlay-container" css={styles.overlay}>
          {overlayComponents.map((Component, index) => <Component key={index}/>)}
        </div>
      </RawIntlProvider>
    </QueryClientProvider>
  );
};

const getRootDimension = (): [width: number, height: number] => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  return [width, height];
};

const applyRootDimension = (): void => {
  const [width, height] = getRootDimension();
  document.documentElement.style.width = `${width}px`;
  document.documentElement.style.height = `${height}px`;
};

window.addEventListener("resize", applyRootDimension);
window.addEventListener("orientationchange", applyRootDimension);
applyRootDimension();