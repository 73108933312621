import {Field} from "@baton8/qroud-lib-repositories";
import {
  BodyComponent,
  ColliderComponent,
  CollisionEndEvent,
  CollisionStartEvent,
  Component,
  Entity,
  GameEvent,
  Input,
  Ray,
  Scene,
  System,
  SystemType,
  Vector
} from "excalibur";
import {FieldEntityComponent} from "src/ecs/fieldEntity/component";
import {Direction} from "src/entities";


const COMPONENT_TYPE = "qroud.playerTrigger" as const;
const SYSTEM_TYPES = ["qroud.playerTrigger"] as const;

/**
 * トリガーの種類を表す型
 * - `none` — 判定結果が真になったら発動
 * - `press` — 判定結果が真＋トリガーボタンが押されたら発動
 */
export type TriggerType = "none" | "press";
/**
 * トリガーを判定する方法を表す型
 * - `touch` — プレイヤーがエンティティに触れているかを判定
 * - `face` — プレイヤーがエンティティーの方を見ているかを判定
 */
export type TriggerMethod = "touch" | "face";

/**
 * プレイヤーがエンティティに触れたりエンティティの方を向いたりしたことを検知するコンポーネントです。
 * プレイヤーがエンティティに「話しかけた」ときに特定の処理をすることができます。
 *
 * 以下のイベントを発火します。
 * - `playerTrigger.touchstart` — プレイヤーがエンティティに接触し始めたとき
 * - `playerTrigger.touchend` — プレイヤーがエンティティとの接触を終えたとき
 * - `playerTrigger.touchpressed` — プレイヤーがエンティティと接触している状態でボタンが押されたとき
 * - `playerTrigger.facestart` — プレイヤーがエンティティの方を向き始めたとき
 * - `playerTrigger.faceend` — プレイヤーがエンティティの方を向いてから向かなくなったとき
 * - `playerTrigger.facepressed` — レイヤーがエンティティの方を向いている状態でボタンが押されたとき
 *
 * 具体的な使い方は `ShowMessageTrigger` の実装を見ると良いです。
 * @category Excalibur ECS
 */
export class PlayerTriggerComponent extends Component<typeof COMPONENT_TYPE> {
  public readonly type = COMPONENT_TYPE;

  public isColliding: boolean;
  public isRayCastHit: boolean;

  public constructor() {
    super();
    this.isColliding = false;
    this.isRayCastHit = false;
  }

  public override onAdd(owner: Entity): void {
    owner.on("collisionstart", (event) => this.onCollisionStart(owner, event));
    owner.on("collisionend", (event) => this.onCollisionEnd(owner, event));
  }

  private onCollisionStart(entity: Entity, event: CollisionStartEvent): void {
    const fieldEntity = entity.get(FieldEntityComponent);
    const playerTrigger = entity.get(PlayerTriggerComponent);
    if (fieldEntity == null || playerTrigger == null) {
      throw new Error("Entity does not have FieldEntityComponent or PlayerTriggerComponent");
    }
    if (event.other === fieldEntity.player) {
      playerTrigger.isColliding = true;
      entity.emit("playerTrigger.touchstart", new PlayerTriggerEvent(entity));
    }
  }

  private onCollisionEnd(entity: Entity, event: CollisionEndEvent): void {
    const fieldEntity = entity.get(FieldEntityComponent);
    const playerTrigger = entity.get(PlayerTriggerComponent);
    if (fieldEntity == null || playerTrigger == null) {
      throw new Error("Entity does not have FieldEntityComponent or PlayerTriggerComponent");
    }
    if (event.other === fieldEntity.player) {
      playerTrigger.isColliding = false;
      entity.emit("playerTrigger.touchend", new PlayerTriggerEvent(entity));
    }
  }
}

/**
 * @category Excalibur ECS
 */
export class PlayerTriggerSystem extends System<PlayerTriggerComponent> {
  public readonly types = SYSTEM_TYPES;
  public readonly systemType = SystemType.Update;

  public override initialize(scene: Scene): void {
    scene.engine.input.keyboard.on("press", (event) => this.onKeyPressed(scene, event));
    scene.engine.input.pointers.primary.on("down", (event) => this.onPointerDown(scene, event));
  }

  public override update(entities: Array<Entity>, delta: number): void {
    for (const entity of entities) {
      this.checkRayCast(entity, delta);
    }
  }

  private checkRayCast(entity: Entity, delta: number): void {
    const body = entity.get(BodyComponent);
    const collider = entity.get(ColliderComponent);
    const fieldEntity = entity.get(FieldEntityComponent);
    const playerTrigger = entity.get(PlayerTriggerComponent);
    if (body == null || collider == null || fieldEntity == null || playerTrigger == null) {
      throw new Error("Entity does not have any of BodyComponent, ColliderComponent, FieldEntityComponent, PlayerTriggerComponent");
    }
    const ray = new Ray(fieldEntity.player.center, getVector(fieldEntity.player.direction));
    const hitVec = collider.get().rayCast(ray, getRayCastDistance(fieldEntity.field, fieldEntity.player.direction));
    if (hitVec != null && !playerTrigger.isRayCastHit) {
      playerTrigger.isRayCastHit = true;
      entity.emit("playerTrigger.facestart", new PlayerTriggerEvent(entity));
    } else if (hitVec == null && playerTrigger.isRayCastHit) {
      playerTrigger.isRayCastHit = false;
      entity.emit("playerTrigger.faceend", new PlayerTriggerEvent(entity));
    }
  }

  private onKeyPressed(scene: Scene, event: Input.KeyEvent): void {
    const entities = scene.world.queryManager.getQuery(this.types).getEntities(this.sort);
    for (const entity of entities) {
      const playerTrigger = entity.get(PlayerTriggerComponent);
      if (playerTrigger == null) {
        throw new Error("Entity does not have PlayerTriggerComponent");
      }
      if (event.key === "Space") {
        if (playerTrigger.isRayCastHit) {
          entity.emit("playerTrigger.facepressed", new PlayerTriggerEvent(entity));
        }
        if (playerTrigger.isColliding) {
          entity.emit("playerTrigger.touchpressed", new PlayerTriggerEvent(entity));
        }
      }
    }
  }

  private onPointerDown(scene: Scene, event: Input.PointerEvent): void {
    const entities = scene.world.queryManager.getQuery(this.types).getEntities(this.sort);
    for (const entity of entities) {
      const playerTrigger = entity.get(PlayerTriggerComponent);
      if (playerTrigger == null) {
        throw new Error("Entity does not have PlayerTriggerComponent");
      }
      if (playerTrigger.isRayCastHit) {
        entity.emit("playerTrigger.facepressed", new PlayerTriggerEvent(entity));
      }
      if (playerTrigger.isColliding) {
        entity.emit("playerTrigger.touchpressed", new PlayerTriggerEvent(entity));
      }
    }
  }
}

export class PlayerTriggerEvent extends GameEvent<Entity> {
  public constructor(target: Entity) {
    super();
    this.target = target;
  }
}

const getVector = (direction: Direction): Vector => {
  switch (direction) {
  case "up":
    return Vector.Up;
  case "down":
    return Vector.Down;
  case "left":
    return Vector.Left;
  case "right":
    return Vector.Right;
  default:
    return Vector.Zero;
  }
};

const getRayCastDistance = (field: Field, direction: Direction): number => {
  switch (direction) {
  case "up":
  case "down":
    return field.data.tileHeight;
  case "left":
  case "right":
    return field.data.tileWidth;
  default:
    return 0;
  }
};