(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("excalibur"));
	else if(typeof define === 'function' && define.amd)
		define(["excalibur"], factory);
	else if(typeof exports === 'object')
		exports["ex"] = factory(require("excalibur"));
	else
		root["ex"] = root["ex"] || {}, root["ex"]["DevTools"] = factory(root["ex"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_excalibur__) => {
return 