import {AVATAR_NAMES} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faCog, faUser} from "@fortawesome/pro-regular-svg-icons";
import {useCallback, useState} from "react";
import {Button, Drawer, DrawerAddon, DrawerAddonButton, DrawerBody, DrawerCloseButton, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, Heading, ScrollList, ScrollListMain, alpha, borderWidth, color, size} from "src/components";
import {createOverlay} from "src/modules/create";
import {useTranslation} from "src/modules/translation";
import {AvatarImage} from "src/overlays/core/avatarDrawer/avatarImage";
import {Menu} from "src/overlays/core/menu";


export interface AvatarDrawerProps {
  /**
   * このオーバーレイの Z index。
   * @defaultValue `300`
   */
  zIndex?: number;
  /** */
  isOpen: boolean;
  /**
   * アバターの名前。
   */
  avatarName: string | null;
  /**
   * アバターの変更が行われたときに呼ばれるコールバック関数。
   */
  onAvatarNameChanged: (avatarName: string) => unknown;
};

// TODO: スタイル未調整!
const styles = {
  drawer: css`
  `,
  body: css`
    row-gap: ${size(6)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  formContainer: css`
    row-gap: ${size(6)};
    display: flex;
    flex-direction: column;
  `,
  topSection: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
  selectSection: css`
    display:flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  heading: css`
    margin-block-end: ${size(4)};
    flex-grow: 0;
    flex-shrink: 0;
  `,
  listWrapper: css`
  `,
  list: css`
    gap: ${size(2)};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 0;
    justify-content: start;
  `,
  avatarImageCover: css`
    border: solid ${borderWidth(2)} ${alpha(color("background"), 0.5)};
    cursor: pointer;
    background-color: ${alpha(color("primary", 3), 0.1)};
    &:hover {
      background-color: ${color("primary", 3)};
    }
    &[data-selected="true"] {
      border: solid ${borderWidth(2)} ${color("primary", 5)};
    }
  `
};

/**
 * - **Inner Props**: {@link AvatarDrawerProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const AvatarDrawer = createOverlay<AvatarDrawerProps>(({
  zIndex = 300,
  isOpen,
  avatarName,
  onAvatarNameChanged
}) => {
  const {trans} = useTranslation("avatarDrawer");

  const handleClose = useCallback(() => {
    AvatarDrawer.propsSubject.update({isOpen: false});
  }, []);

  const showMenuDrawer = useCallback(() => {
    AvatarDrawer.propsSubject.update({isOpen: false});
    setTimeout(() => {
      Menu.propsSubject.update({isOpen: true});
    }, 300);
  }, []);

  const [selectedAvatar, setSelectedAvatar] = useState(avatarName);

  const canChangeAvatar = selectedAvatar !== null && selectedAvatar !== avatarName;

  const handleChangeAvatar = useCallback(() => {
    if (selectedAvatar != null) {
      onAvatarNameChanged(selectedAvatar);
      AvatarDrawer.propsSubject.update({avatarName: selectedAvatar, isOpen: false});
    }
  }, [onAvatarNameChanged, selectedAvatar]);

  return (
    <Drawer css={styles.drawer} zIndex={zIndex} isOpen={isOpen} onClose={handleClose}>
      <DrawerCloseButton/>
      <DrawerAddon>
        <DrawerAddonButton icon={faUser} highlight={true}/>
        <DrawerAddonButton icon={faCog} onClick={showMenuDrawer}/>
      </DrawerAddon>
      <DrawerHeader>
        <DrawerTitle>{trans("header.title")}</DrawerTitle>
        <DrawerDescription>{trans("header.description")}</DrawerDescription>
      </DrawerHeader>
      <DrawerBody css={styles.body}>
        <div css={styles.formContainer}>
          <section css={styles.topSection}>
            <Heading css={styles.heading}>{trans("heading.currentAvatar")}</Heading>
            <AvatarImage avatarName={avatarName}/>
          </section>
          <section css={styles.selectSection}>
            <Heading css={styles.heading}>{trans("heading.changeAvatar")}</Heading>
            <ScrollList css={styles.listWrapper} items={AVATAR_NAMES} insertMargin={true}>
              <ScrollListMain css={styles.list}>
                {(avatar, index) => {
                  return (
                    <AvatarImage key={avatar} avatarName={avatar} onClick={() => setSelectedAvatar(avatar)} css={styles.avatarImageCover} data-selected={avatar === selectedAvatar}/>
                  );
                }}
              </ScrollListMain>
            </ScrollList>
          </section>
        </div>
      </DrawerBody>
      <DrawerFooter>
        <Button size="lg" onClick={handleChangeAvatar} disabled={!canChangeAvatar}>
          {trans("submit")}
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}, {
  isOpen: false,
  avatarName: null,
  onAvatarNameChanged: () => null
});