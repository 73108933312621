import {Actor} from "excalibur";
import {StoriesComponent} from "./component";


type ActorForStories = Pick<Actor, "get" | "addComponent">;

/**
 * `StoriesComponent` を追加する mix-in です。
 * `stories` ゲッターが追加され、これを経由して手続き的に実行する処理を指定できるようになります。
 * @category Excalibur ECS
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withStories<B extends new(...args: Array<any>) => ActorForStories>(base: B) {
  return class extends base {
    public constructor(...args: Array<any>) {
      super(...args);
      this.addComponent(new StoriesComponent());
    }

    /**
     * `this.get(StoriesComponent)` のショートハンドです。
     * これを利用して手続き的に実行する処理を指定できます。
     */
    public get stories(): StoriesComponent {
      return this.get(StoriesComponent)!;
    }
  };
};