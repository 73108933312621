import {css} from "@emotion/react";
import {ReactNode} from "react";
import {size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


export interface DirectionSlideContentProps extends CustomDataAttributes {
  className?: string;
  children?: ReactNode;
};

const styles = {
  root: css`
    font-size: ${size(4)};
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const DirectionSlideContent: React.FC<DirectionSlideContentProps> = ({
  className,
  children,
  ...data
}) => {
  return (
    <div css={styles.root} className={className} {...data}>
      {children}
    </div>
  );
};
