import {User} from "@baton8/quizium-lib-repositories/dist/library";
import {IntlShape, createIntl} from "react-intl";
import {BehaviorSubject} from "rxjs";
import {Field, Session} from "src/types";


export class Global {
  /**
   * プレイヤーが参加しているセッションが流される `BehaviorSubject` です。
   * QROUD ゲーム側はこれを購読するためだけに利用し、新たなデータは流さないでください。
   */
  public readonly sessionSubject: BehaviorSubject<Session | null>;

  /**
   * ログイン中のユーザーが流される `BehaviorSubject` です。
   * ゲーム開始時のログイン画面でログインされた後 (もしくはログイン済みの場合は自動ログインの後) に、QROUD 本体からユーザーのデータが流されます。
   * QROUD ゲーム側はこれを購読するためだけに利用し、新たなデータは流さないでください。
   */
  public readonly userSubject: BehaviorSubject<User | null>;

  /**
   * プレイヤーが存在しているマップが流される `BehaviorSubject` です。
   * QROUD ゲーム側はこれを購読するためだけに利用し、新たなデータは流さないでください。
   */
  public readonly fieldSubject: BehaviorSubject<Field | null>;

  /**
   * プレイヤーが選択しているロケールが流される `BehaviorSubject` です。
   * QROUD ゲーム側はこれを購読するためだけに利用し、新たなデータは流さないでください。
   */
  public readonly localeSubject: BehaviorSubject<string>;

  public readonly intlSubject: BehaviorSubject<IntlShape>;

  public constructor() {
    this.sessionSubject = new BehaviorSubject<Session | null>(null);
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.fieldSubject = new BehaviorSubject<Field | null>(null);
    this.localeSubject = new BehaviorSubject<string>("ja");
    this.intlSubject = new BehaviorSubject<IntlShape>(createIntl({locale: "ja"}));
  }

  /**
   * 現在プレイヤーが参加しているセッションです。
   * `sessionSubject.value` のショートハンドです。
   */
  public get session(): Session | null {
    return this.sessionSubject.value;
  }

  /**
   * 現在ログイン中のユーザーです。
   * `userSubject.value` のショートハンドです。
   */
  public get user(): User | null {
    return this.userSubject.value;
  }

  /**
   * 現在プレイヤーが存在しているマップです。
   * `fieldSubject.value` のショートハンドです。
   */
  public get field(): Field | null {
    return this.fieldSubject.value;
  }

  /**
   * プレイヤーが選択しているロケールです。
   * `localeSubject.value` のショートハンドです。
   */
  public get locale(): string {
    return this.localeSubject.value;
  }

  public get intl(): IntlShape {
    return this.intlSubject.value;
  }
}

export const global = new Global();