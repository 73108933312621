import {FieldId, UserId, global, socket, writeLog, writeLogCollapsed} from "@baton8/qroud-lib-repositories";
import {GhostGroup as GhostGroupInterface} from "@baton8/qroud-lib-resources";
import {Actor, CollisionType, Engine, Scene, vec} from "excalibur";
import {Field} from "src/game/entities/field";
import {Ghost} from "src/game/entities/ghost";
import {MainScene} from "src/game/scenes/main";


export class GhostGroup extends Actor implements GhostGroupInterface {
  public field!: Field;

  public readonly ghosts: Map<UserId, Ghost> = new Map();

  public constructor() {
    super({
      pos: vec(0, 0),
      collisionType: CollisionType.PreventCollision,
      name: "GhostGroup"
    });
  }

  public override onInitialize(engine: Engine): void {
    this.setupSocket(engine);
  }

  public override onPreKill(scene: Scene): void {
    this.cleanupSocket();
  }

  public resetGhosts(fieldId: FieldId): void {
    this.ghosts.clear();
    this.removeAllChildren();
    const user = global.user;
    if (user != null) {
      socket.emit("getPlayers", user.id, fieldId, (playerInfos) => {
        writeLogCollapsed("GhostGroup", "getPlayers", playerInfos);
        for (const playerInfo of playerInfos) {
          if (playerInfo.user.id !== global.user?.id && !this.ghosts.has(playerInfo.user.id)) {
            const ghost = new Ghost(playerInfo);
            ghost.field = this.field;
            this.addChild(ghost);
            this.ghosts.set(playerInfo.user.id, ghost);
          }
        }
      });
    }
  }

  private setupSocket(engine: Engine): void {
    const scene = engine.currentScene;
    if (scene instanceof MainScene) {
      socket.on("playerEntered", (playerInfo) => {
        writeLogCollapsed("GhostGroup", "playerEntered", playerInfo);
        if (!this.ghosts.has(playerInfo.user.id)) {
          const ghost = new Ghost(playerInfo);
          ghost.field = this.field;
          this.addChild(ghost);
          this.ghosts.set(playerInfo.user.id, ghost);
        }
      });
      socket.on("playerLeft", (userId) => {
        writeLogCollapsed("GhostGroup", "playerLeft", userId);
        const ghost = this.ghosts.get(userId);
        if (ghost != null) {
          this.removeChild(ghost);
          this.ghosts.delete(userId);
        }
      });
      socket.on("playerMoved", (playerInfo) => {
        const ghost = this.ghosts.get(playerInfo.user.id);
        if (ghost != null) {
          ghost.setByInfo(playerInfo);
        } else {
          // 本来 playerMoved の前に playerEntered が呼ばれてそこで Ghost が追加されているはず。
          // しかし、タイミングによっては playerEntered が呼ばれない場合があるので、ここでも追加処理をする。
          // また、これもタイミングによっては別のマップのプレイヤーの playerMoved が呼ばれる場合がある (要調査) ので、マップ ID のチェックをする。
          if (playerInfo.fieldId === scene.field.id) {
            const ghost = new Ghost(playerInfo);
            ghost.field = this.field;
            this.addChild(ghost);
            this.ghosts.set(playerInfo.user.id, ghost);
          }
        }
      });
      socket.on("graphicSettingChanged", (userId, graphicSetting) => {
        writeLog("GhostGroup", "graphicSettingChanged", userId, graphicSetting);
        const ghost = this.ghosts.get(userId);
        if (ghost != null) {
          ghost.setByGraphicSetting(graphicSetting);
        }
      });
    }
  }

  private cleanupSocket(): void {
    socket.off("playerEntered");
    socket.off("playerLeft");
    socket.off("playerMoved");
  }
}