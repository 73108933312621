import {render} from "@baton8/qroud-client";
import approx from "@baton8/qroud-game-approx";
import dropout from "@baton8/qroud-game-dropout";
import life from "@baton8/qroud-game-life";
import sharing from "@baton8/qroud-game-sharing";
import speed from "@baton8/qroud-game-speed";


const isEnabled = {
  approx: true, // 4.1.0 for QROUD 9.3.0
  dropout: true, // 3.0.1 for QROUD 9.0.0
  life: false, // 1.0.0 for QROUD 7.0.0
  sharing: true, // 4.0.1 for QROUD 9.0.0
  speed: true // 2.1.0 for QROUD 9.2.2
};

render([
  isEnabled.approx ? [approx] : [],
  isEnabled.dropout ? [dropout] : [],
  isEnabled.life ? [life] : [],
  isEnabled.sharing ? [sharing] : [],
  isEnabled.speed ? [speed] : []
].flat());