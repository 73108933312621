import {
  Component,
  Entity,
  MotionComponent,
  System,
  SystemType,
  TransformComponent,
  Vector,
  vec
} from "excalibur";
import {clamp, lerp} from "src/utils/misc";


const COMPONENT_TYPE = "qroud.stories" as const;
const SYSTEM_TYPES = ["qroud.stories"] as const;

export type StoryGenerator = Generator<unknown, void, number>;
type StoryGeneratorSpec = {name: string | null, generator: StoryGenerator, resolve: () => void};

/**
 * @category Excalibur ECS
 */
export class StoriesComponent extends Component<typeof COMPONENT_TYPE> {
  public readonly type = COMPONENT_TYPE;

  public generatorSpecs: Array<StoryGeneratorSpec> = [];

  public constructor() {
    super();
  }

  public addStory(story: () => StoryGenerator): Promise<void>;
  public addStory(name: string, story: () => StoryGenerator): Promise<void>;
  public addStory(...args: [() => StoryGenerator] | [string, () => StoryGenerator]): Promise<void> {
    const [name, story] = args.length === 1 ? [null, args[0]] : args;
    const generator = story();
    generator.next();
    const promise = new Promise<void>((resolve, reject) => {
      this.generatorSpecs.push({name, generator, resolve});
    });
    return promise;
  }

  public removeStory(name: string): void {
    this.generatorSpecs = this.generatorSpecs.filter((spec) => spec.name !== name);
  }

  public clearAllStories(): void {
    this.generatorSpecs = [];
  }

  public *storyWait(duration: number): StoryGenerator {
    let timer = 0;
    while (true) {
      timer += yield;
      if (timer >= duration) {
        break;
      }
    }
  }

  public *storyRepeat(go: () => unknown, interval: number): StoryGenerator {
    let timer = 0;
    while (true) {
      timer += yield;
      if (timer >= interval) {
        go();
        timer -= interval;
      }
    }
  }

  public *storyWithRatio(update: (ratio: number) => unknown, duration: number): StoryGenerator {
    let timer = 0;
    while (true) {
      timer += yield;
      const ratio = clamp(timer / duration, 0, 1);
      update(ratio);
      if (timer >= duration) {
        break;
      }
    }
  }

  public *storyMoveTo(destPos: Vector, duration: number): StoryGenerator {
    const transform = this.owner?.get(TransformComponent);
    if (transform == null) {
      throw new Error("Entity does not have TransformComponent");
    }
    const initialPos = transform.pos.clone();
    yield* this.storyWithRatio((ratio) => {
      const pos = lerp(initialPos, destPos, ratio);
      transform.pos = pos;
    }, duration);
  }

  public *storyMoveBy(offset: Vector, speed: number): StoryGenerator {
    const transform = this.owner?.get(TransformComponent) ?? null;
    const motion = this.owner?.get(MotionComponent) ?? null;
    if (transform == null || motion == null) {
      throw new Error("Entity does not have TransformComponent or MotionComponent");
    }
    const targetPosition = new Vector(transform.pos.x + offset.x, transform.pos.y + offset.y);
    motion.vel = targetPosition.sub(transform.pos).normalize().scale(speed);

    while (motion.vel.x !== 0 || motion.vel.y !== 0) {
      const delta = yield;
      if (targetPosition.distance(transform.pos) <= speed * delta / 1000) {
        motion.vel = vec(0, 0);
        transform.pos = targetPosition.clone();
        break;
      }
    }
  }

  public *storyMoveToBySpeed(destPos: Vector, speed: number): StoryGenerator {
    const transform = this.owner?.get(TransformComponent) ?? null;
    const motion = this.owner?.get(MotionComponent) ?? null;
    if (transform == null || motion == null) {
      throw new Error("Entity does not have TransformComponent or MotionComponent");
    }
    while (true) {
      const delta = yield;
      motion.vel = destPos.sub(transform.pos).normalize().scale(speed);
      if (destPos.distance(transform.pos) <= speed * delta / 1000) {
        motion.vel = vec(0, 0);
        transform.pos = destPos.clone();
        break;
      }
    }
  }
}

/**
 * @category Excalibur ECS
 */
export class StoriesSystem extends System<StoriesComponent> {
  public readonly types = SYSTEM_TYPES;
  public readonly systemType = SystemType.Update;

  public override update(entities: Array<Entity>, delta: number): void {
    for (const entity of entities) {
      this.runStories(entity, delta);
    }
  }

  private runStories(entity: Entity, delta: number): void {
    const stories = entity.get(StoriesComponent)!;
    const deletedIndices = [] as Array<number>;
    for (let i = 0 ; i < stories.generatorSpecs.length ; i ++) {
      const {generator, resolve} = stories.generatorSpecs[i];
      const result = generator.next(delta);
      if (result.done) {
        resolve();
        deletedIndices.push(i);
      }
    }
    if (deletedIndices.length > 0) {
      stories.generatorSpecs = stories.generatorSpecs.filter((dummy, index) => !deletedIndices.includes(index));
    }
  }
}