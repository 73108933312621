import {css} from "@emotion/react";
import {ReactNode} from "react";
import {alpha, boxShadow, color, filterBlur, size} from "src/components/constants/constants";
import {CustomDataAttributes} from "src/modules/data";


export interface WhitePaneProps extends CustomDataAttributes {
  /**
   * ホバー時に表示を薄くするかどうか。
   * @defaultValue `false`
   */
  hideOnHover?: boolean;
  /**
   * ルート要素の CSS クラス名。
   */
  className?: string;
  /** */
  children?: ReactNode;
};

const styles = {
  root: css`
    padding-block: ${size(3)};
    padding-inline: ${size(4)};
    border-radius: ${size(2)};
    color: ${color("blackText")};
    background-color: ${alpha(color("white"), 0.95)};
    backdrop-filter: ${filterBlur(1)};
    box-shadow: ${boxShadow(alpha(color("primary", 5), 0.5), 1)};
    box-sizing: border-box;
    position: relative;
    pointer-events: all;
    transition: opacity 0.2s ease;
    &[data-hide="true"]:hover {
      opacity: 0.2;
    }
  `
};

/**
 * @group React Components
 * @category Common Component
 */
export const WhitePane: React.FC<WhitePaneProps> = ({
  className,
  hideOnHover = false,
  children,
  ...data
}) => {
  return (
    <div css={styles.root} className={className} data-hide={hideOnHover} {...data}>
      {children}
    </div>
  );
};
