import {getEnv, getVersion, isDeploy, useApi} from "@baton8/quizium-lib-repositories";
import {css} from "@emotion/react";
import {useMemo} from "react";
import {size} from "src/components";
import {useTranslation} from "src/modules/translation";


export interface Props {
};

const styles = {
  version: css`
    margin-block-start: ${size(2)};
    font-size: ${size(3)};
    opacity: 0.6;
  `,
  environment: css`
    margin-block-start: ${size(1)};
    font-size: ${size(3)};
    opacity: 0.6;
  `
};

/**
 * - **Inner Props**: {@link Props}
 * @group React Components
 * @category Builtin Overlay
 */
export const GameInfoView: React.FC<Props> = ({
}) => {
  const {trans} = useTranslation("menu");

  const frontVersion = useMemo(() => getFrontVersion(), []);
  const [backVersion] = useApi(getVersion, [], {cacheTime: Infinity, staleTime: Infinity});

  const year = useMemo(() => new Date().getFullYear(), []);

  const envText = useMemo(() => {
    const env = getEnv();
    return env.charAt(0).toUpperCase() + env.slice(1).toLowerCase();
  }, []);
  const deployText = useMemo(() => {
    return isDeploy() ? "Deployed" : "Local";
  }, []);

  return (
    <div>
      <div>
        © {year} baton, inc.
      </div>
      <div css={styles.version}>
        F: {frontVersion || "unknown"} · B: {backVersion || "unknown"}
      </div>
      <div css={styles.environment}>
        {envText} ({deployText})
      </div>
    </div>
  );
};

const getFrontVersion = (): string => {
  const buildId = process.env.COMMIT_HASH;
  if (buildId) {
    if (buildId === "development") {
      return "dev";
    } else {
      return buildId.slice(0, 7);
    }
  } else {
    return "";
  }
};