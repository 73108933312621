import {Field} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {faUser} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SingleLineText} from "src/components/common/singleLineText";
import {alpha, borderWidth, color, fontWeight, size} from "src/components/constants/constants";
import {DummyUserAvatar} from "src/components/modules/userView/dummyUserAvatar";


export interface Props {
  field: Field | null;
};

const styles = {
  root: css`
    block-size: ${size(20)};
    padding-inline-end: ${size(3)};
    border-radius: ${size(2)};
    background-color: ${color("white")};
    border: solid ${borderWidth(1)} ${alpha(color("primary", 5), 0.3)};
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    overflow: hidden;
  `,
  background: css`
    inline-size: 20%;
    background-image: url("https://picsum.photos/800");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  main: css`
    margin-inline-start: -8%;
    padding-block: ${size(3)};
    padding-inline-start: ${size(3)};
    column-gap: ${size(3)};
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  name: css`
    padding-block: 0.2em;
    padding-inline: 0.4em;
    margin-block: -0.2em;
    margin-inline: -0.4em;
    border-radius: ${size(1)};
    font-size: ${size(5)};
    font-weight: ${fontWeight("bold")};
    background-color: ${alpha(color("white"), 0.9)};
    flex-grow: 1;
    flex-shrink: 1;
  `,
  right: css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 0;
    flex-shrink: 0;
  `,
  playerCountContainer: css`
    display: flex;
    align-items: center;
    opacity: 0.6;
  `,
  playerCountIcon: css`
    margin-inline-end: 0.4em;
    font-size: ${size(3)};
  `,
  playerCount: css`
    font-size: ${size(4)};
  `,
  user: css`
    margin-block-start: ${size(1)};
    display: flex;
    & >* +* {
      margin-inline-start: -0.5em;
    }
  `
};

/**
 * - **Inner Props**: {@link Props}
 * @group React Components
 * @category Builtin Overlay
 */
export const MenuFieldPane: React.FC<Props> = ({
  field
}) => {
  return field != null && (
    <div css={styles.root}>
      <div css={styles.background}/>
      <div css={styles.main}>
        <SingleLineText css={styles.name}>
          {field.name ?? "?"}
        </SingleLineText>
        <div css={styles.right}>
          <div css={styles.playerCountContainer}>
            <div css={styles.playerCountIcon}>
              <FontAwesomeIcon icon={faUser}/>
            </div>
            <div css={styles.playerCount}>
              ?
            </div>
          </div>
          <div css={styles.user}>
            {Array.from({length: 5}).map((dummy, index) => (
              <DummyUserAvatar key={index}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};