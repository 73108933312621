import {faBars} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCallback} from "react";
import {CircleIconButton} from "src/components/common/circleIconButton";
import {useTranslation} from "src/modules/translation";
import {Menu} from "src/overlays/core/menu";


interface Props {
};

const styles = {
};

export const OpenMenuButton: React.FC<Props> = ({
}) => {
  const {trans} = useTranslation("bottomOverlay");

  const openMenu = useCallback(() => {
    Menu.propsSubject.update({isOpen: true});
  }, []);

  return (
    <CircleIconButton iconNode={<FontAwesomeIcon icon={faBars}/>} onClick={openMenu} aria-label={trans("openMenu")}/>
  );
};
