import {ComponentType, FunctionComponent} from "react";
import {Observable, pairwise} from "rxjs";
import {UpdatableSubject, useBehaviorSubject} from "src/modules/subject";


export type OverlayComponent = ComponentType<{}>;
export type OverlayRoot<P extends {}> = FunctionComponent<{}> & {propsSubject: UpdatableSubject<P>, propsObservable: Observable<[P, P]>};

/**
 * `Component` に渡されたコンポーネントをラップしたオーバーレイを作ります。
 * このラップコンポーネントは `propsSubject` プロパティを追加でもっており、これを操作することでオリジナルコンポーネントに渡される props を変更して表示を更新することができます。
 * これにより、マップオブジェクト側からオーバーレイを更新することができます。
 *
 * ```typescript
 * // オーバーレイを作成
 * type Props = {foo: string};
 * const ExampleOverlay = createOverlay<Props>(…);
 * // オーバーレイに渡す props を変更して UI を更新
 * ExampleOverlay.propsSubject.update({foo: "Hello"});
 * ```
 * @param Component ラップされるコンポーネント
 * @param initialProps ラップされるコンポーネントに渡す props の初期値
 * @returns ラップしたコンポーネント
 */
export const createOverlay = <P extends {}>(
  Component: ComponentType<P>,
  initialProps: P
): OverlayRoot<P> => {
  const propsSubject = new UpdatableSubject(initialProps);
  const propsObservable = propsSubject.pipe(pairwise());

  const WrappedComponent = Object.assign(() => {
    const props = useBehaviorSubject(propsSubject);
    if (props != null) {
      return <Component {...props}/>;
    } else {
      return null;
    }
  }, {
    propsSubject,
    propsObservable
  });

  return WrappedComponent;
};