import {Field, FieldId} from "@baton8/qroud-lib-repositories";
import {Actor, Engine} from "excalibur";
import {GhostGroup, MainScene, Player} from "src/entities";
import {FieldEntityComponent} from "./component";


type ActorForFieldEntity = Pick<Actor, "get" | "on" | "scene">;

/**
 * `FieldEntityComponent` を利用しやすくする mix-in です。
 * 他の mix-in 追加ユーテリティティ関数と異なり、この関数は `FieldEntityComponent` へのアクセスをしやすくするメソッドを追加するのみで、`FieldEntityComponent` の追加は行いません。
 * `FieldEntityComponent` は、マップデータを読み込む途中で QROUD 本体によって追加されます。
 * @category Excalibur ECS
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withFieldEntity<B extends new(...args: Array<any>) => ActorForFieldEntity>(base: B) {
  return class extends base {
    public engine!: Engine;
    public override readonly scene!: MainScene;

    public constructor(...args: Array<any>) {
      super(...args);
      this.on("initialize", ({engine}) => {
        this.engine = engine;
      });
    }

    /**
     * このエンティティが存在しているフィールドです。
     * エンティティはマップ移動しても維持されることがあるので、ゲーム中にこの値は変わる可能性があります。
     */
    public get field(): Field {
      return this.get(FieldEntityComponent)!.field;
    }

    /**
     * プレイヤーエンティティへの参照です。
     */
    public get player(): Player {
      return this.get(FieldEntityComponent)!.player;
    }

    /**
     * ゴーストを管理するエンティティへの参照です。
     */
    public get ghostGroup(): GhostGroup {
      return this.get(FieldEntityComponent)!.ghostGroup;
    }

    /**
     * Tiled 上のオブジェクト ID です。
     */
    public get objectId(): number {
      return this.get(FieldEntityComponent)!.objectId;
    }

    /**
     * このエンティティが最初に生成されたフィールドの ID です。
     * マップ間で維持されるエンティティの場合、この値は `this.field.id` と異なる場合があります。
     */
    public get originalFieldId(): FieldId {
      return this.get(FieldEntityComponent)!.originalFieldId;
    }
  };
};