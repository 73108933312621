import {Actor, Random, Vector, vec} from "excalibur";
import {withFieldEntity} from "src/ecs";
import {FieldEntityConfigs} from "src/ecs/fieldEntity/component";


const random = new Random();

/**
 * マップのスポーンポイントを設定します。
 * プレイヤーがマップに入ったときに、このエンティティ内のランダムな位置にスポーンします。
 * マップ内に必ず 1 個だけ設置してください。
 * @category Builtin Entity
 */
export class SpawnPoint extends withFieldEntity(Actor) {

  public constructor(configs: FieldEntityConfigs<{}>) {
    super(configs);
  }

  public getRandomPos(): Vector {
    const x = random.floating(this.pos.x - this.width / 2, this.pos.x + this.width / 2);
    const y = random.floating(this.pos.y - this.height / 2, this.pos.y + this.height / 2);
    return vec(x, y);
  }
}