import {Group, getImage, getUserByHandle, orNull, useApi} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {size} from "src/components/constants/constants";


interface Props {
  group: Group;
};

const styles = {
  root: css`
    width: 1.5em;
    height: 1.5em;
    border-radius: ${size(1)};
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    &[data-personal="true"] {
      border-radius: 100%;
    }
  `
};

export const GroupAvatar: React.FC<Props> = ({
  group
}) => {
  const [user] = useApi(getUserByHandle, group.isPersonal && [group.handle]);
  const icon = user?.icon ?? group.icon;
  const [image] = useApi(orNull(getImage), [icon], {staleTime: Infinity});

  const src = image?.url;
  const background = image != null ? "white" : `hsl(${parseInt(group.id.slice(0, 8), 16) % 360}, 30%, 50%)`;

  return src != null ? group.isPersonal ? (
    <img css={styles.root} data-personal={true} src={src}/>
  ) : (
    <img css={styles.root} src={src}/>
  ) : (
    <div css={styles.root} style={{background}}/>
  );
};
