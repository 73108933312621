import {css} from "@emotion/react";
import {useCallback} from "react";
import {atom, useRecoilValue, useSetRecoilState} from "recoil";
import {lineHeight, size} from "src/components/constants/constants";
import {BlackPane} from "src/components/modules/blackPane";
import {GameCover} from "src/components/modules/gameCover";


export interface ToastProps {
};

const styles = {
  spacer: css`
    padding-block: ${size(0)};
    padding-inline: ${size(6)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `,
  paneWrapper: css`
    transition: transform 0.3s ease;
    &[data-visible="false"] {
      transform: translate(0%, -100%);
    }
  `,
  pane: css`
    border-start-start-radius: ${size(0)};
    border-start-end-radius: ${size(0)};
    display: flex;
  `,
  text: css`
    font-size: ${size(4)};
    ${lineHeight(1.4)}
  `
};

export interface ToastOptions {
  /**
   * トーストを表示する時間 (ミリ秒単位)。
   * @defaultValue `3000`
   */
  duration?: number;
}

interface ToastSpec {
  text: string;
  isVisible: boolean;
};

const popupSpecAtom = atom<ToastSpec>({
  key: "popupSpec",
  default: {
    text: "",
    isVisible: false
  }
});
let popupTimeout = null as NodeJS.Timeout | null;

/**
 * トーストを表示するためのフックです。
 * @returns トーストを表示する関数
 * @group React Hooks
 * @category Builtin Hook
 */
export const useToast = (): ((text: string, options?: ToastOptions) => void) => {
  const setPopupSpec = useSetRecoilState(popupSpecAtom);
  const showPopup = useCallback((text: string, options?: ToastOptions): void => {
    const duration = options?.duration ?? 3000;
    setPopupSpec({
      text,
      isVisible: true
    });
    if (popupTimeout) {
      clearTimeout(popupTimeout);
    }
    popupTimeout = setTimeout(() => {
      setPopupSpec((spec) => ({...spec, isVisible: false}));
    }, duration);
  }, [setPopupSpec]);
  return showPopup;
};

/**
 * トーストを表示するオーバーレイです。
 * これを直接操作することは基本的になく、トーストを表示したい場合は `useToast` フックを利用してください。
 * - **Inner Props**: {@link ToastProps}
 * @group React Components
 * @category Builtin Overlay
 */
export const Toast: React.FC<ToastProps> = ({
}) => {
  const popupSpec = useRecoilValue(popupSpecAtom);

  return (
    <GameCover css={styles.spacer} zIndex={500}>
      <div css={styles.paneWrapper} data-visible={popupSpec.isVisible}>
        <BlackPane css={styles.pane}>
          <p css={styles.text}>
            {popupSpec.text}
          </p>
        </BlackPane>
      </div>
    </GameCover>
  );
};